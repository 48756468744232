import { createAction } from 'redux-actions';

export const GET_PLAN = createAction('GET_PLAN');
export const GET_PLAN_START = createAction('GET_PLAN_START');
export const GET_PLAN_SUCCESS = createAction('GET_PLAN_SUCCESS');
export const GET_PLAN_ERROR = createAction('GET_PLAN_ERROR');
export const GET_PLAN_FINALLY = createAction('GET_PLAN_FINALLY');

export const GET_PLANS = createAction('GET_PLANS');
export const GET_PLANS_START = createAction('GET_PLANS_START');
export const GET_PLANS_SUCCESS = createAction('GET_PLANS_SUCCESS');
export const GET_PLANS_ERROR = createAction('GET_PLANS_ERROR');
export const GET_PLANS_FINALLY = createAction('GET_PLANS_FINALLY');

export const GET_ONBOARDING_PLANS = createAction('GET_ONBOARDING_PLANS');
export const GET_ONBOARDING_PLANS_START = createAction('GET_ONBOARDING_PLANS_START');
export const GET_ONBOARDING_PLANS_SUCCESS = createAction('GET_ONBOARDING_PLANS_SUCCESS');
export const GET_ONBOARDING_PLANS_ERROR = createAction('GET_ONBOARDING_PLANS_ERROR');
export const GET_ONBOARDING_PLANS_FINALLY = createAction('GET_ONBOARDING_PLANS_FINALLY');