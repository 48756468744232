import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

import ProfileSelectionContainer from '../../containers/profile/ProfileSelectionContainer'
import Loading from '../Loading';

export default memo(function ProfileSelectionComponent() {
  const { isAuthenticated, isLoading, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth);
  const { data: profiles } = useSelector(state => state.profiles);

  const navigate = useNavigate()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak]);

  return (
    (isEmpty(profiles) || isLoading)
      ? <Loading />
      : <ProfileSelectionContainer
        navigate={navigate}
        useImageCDN={useImageCDN}
      />
  )
})