import React, { useState } from 'react'
import Fade from '@mui/material/Fade'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Icons } from './Icons'
import LockIcon from '../assets/icons/candado.svg'

export const CardInfo = (props) => {
  const { vod, isHome, pay, isUnlocked, showSeason, useImageCDN } = props

  const [isVideoShown, setIsVideoShown] = useState(false)

  let timeoutId = null

  const handleMouseHover = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => { setIsVideoShown(true) }, 1000)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutId)
    setIsVideoShown(false)
  }

  return (
    <Link to={!isUnlocked && pay ? '/planes' : `/player/vod/${vod._id}`}>
      <article
        className={classNames({
          'articleItemBox whitVisibleTitle': true,
          'cwCard': isHome
        })}
        onMouseEnter={() => handleMouseHover()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <figure className='ratioImgBox ratioThumb'>
          {!isUnlocked && pay && <Icons path={LockIcon} className='lockIcon' />}
          <img
            src={!isEmpty(vod.imagesSite.thumbnail)
              ? useImageCDN
                ? vod.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                : vod.imagesSite.thumbnail
              : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
            }
            alt=''
          />
          {isVideoShown && (
            <Fade in={isVideoShown} timeout={600} style={{ transitionDelay: '500ms' }}>
              <video id={`video_cardInfo_${vod.idMedia}`} autoPlay preload='auto' loop='loop' muted>
                <source src={`https://mdstrm.com/video/${vod.idMedia}/preview.mp4`} type='video/mp4' />
              </video>
            </Fade>
          )}
        </figure>
        <header>
          <h3>{
            isHome
              ? `${showSeason ? `T${vod.season.order}:` : ''}${(vod.serie?.seasons[0]?.vods?.length ?? 0) > 0 ? `E${Number(vod.order) + 1} ` : ''}`
              : `${showSeason ? `T${vod.season.order}:` : ''}E${Number(vod.order) + 1}`
          }</h3>
          <p>
            {isHome
              ? vod.serie !== null
                ? vod.serie.title
                : vod.event.title
              : vod.title}
          </p>
          <div className='continueBar' style={{ 'width': '100%', 'display': vod.position ? 'block' : 'none' }}>
            <span style={{
              'width': `${(isNaN((vod.position / vod.duration)) ? 0 : (vod.position / vod.duration) * 100)}%`
            }} />
          </div>
        </header>
      </article>
    </Link>
  )
}