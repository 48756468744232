import React, { memo, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  CHANGE_SUBSCRIPTION,
  GET_PLANS,
  SET_SUBSCRIPTIONS
} from '../../redux/actions'

import Loading from '../Loading'
import PlansContainer from '../../containers/plans/PlansContainer'

export default memo(function PlansComponent() {
  const { isAuthenticated, keycloak } = useSelector(state => state.auth)
  const { data: subscription, isLoading, subscriptionsPlanIds, subscriber } = useSelector(state => state.subscription)
  const { plans, isLoading: isLoadingPlans } = useSelector(state => state.plans)

  const [isSameUser, setIsSameUser] = useState(true)
  const [email, setEmail] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id: userId } = useParams()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    if (isAuthenticated && !isEmpty(userId)) {
      if (keycloak.subject !== userId) {
        setEmail(keycloak.userInfo?.email || '')
        setIsSameUser(false)
      } else {
        setEmail('')
        setIsSameUser(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userId])

  useEffect(() => {
    isAuthenticated
      && isEmpty(plans)
      && dispatch(GET_PLANS({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    !isEmpty(subscriber) && !isEmpty(subscriber.idp_id)
      && navigate('/inicio')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriber])

  const handleOnPayPlan = ({ planId, isSubscription }) => {
    dispatch(SET_SUBSCRIPTIONS({ keycloak, planId, isSubscription }))
  }

  const handleOnChangePlan = ({ planId }) => {
    dispatch(CHANGE_SUBSCRIPTION({ keycloak, planId, subscriptionId: subscription.id }))
  }

  return (
    isLoading || isLoadingPlans
      ? <Loading />
      : <PlansContainer
        email={email}
        handleOnPayPlan={handleOnPayPlan}
        handleOnChangePlan={handleOnChangePlan}
        navigate={navigate}
        isSameUser={isSameUser}
        keycloak={keycloak}
        plans={plans}
        setIsSameUser={setIsSameUser}
        subscription={subscription}
        subscriptionsPlanIds={subscriptionsPlanIds}
        userId={userId}
      />
  )
})