import React from 'react'
import { Select, MenuItem, FormControl } from '@mui/material'

import Footer from '../../components/Footer'
import { Icons } from '../../components/Icons'
import iconArrowDown from '../../assets/icons/arrowDown.svg'
import { CardPortrait } from '../../components/CardPortrait'

export default function CategoriesContainer(props) {
  const {
    categories,
    categoryIdSelected,
    isEventSelected,
    handleOnChangeCategory,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    preferencesKeyedById,
    seriesFiltered,
    useImageCDN
  } = props;

  return (
    <main className='appWrap'>
      <div className='mainWidth noHeroWrap'>
        <section className='rowXxl'>
          <header className='rowS flexHorizontal'>
            <h2>Categorías</h2>
            <FormControl>
              <Select
                labelId='season-select'
                id='season-select'
                value={categoryIdSelected}
                onChange={handleOnChangeCategory}
                defaultValue={categoryIdSelected}
                className='selectBase'
                IconComponent={() => (<Icons path={iconArrowDown} />)}
              >
                {categories.map(category => (
                  <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </header>
          <ul className='grid grid-6-cols'>
            {isEventSelected ?
              seriesFiltered.map(serie => (
                <li key={serie._id} className='articleListItem'>
                  <CardPortrait
                    serie={serie}
                    handleOnChangePreferences={handleOnChangePreferences}
                    handleOnClickWatchNow={handleOnClickWatchNow}
                    link={`/evento/${serie._id}`}
                    preference={preferencesKeyedById[serie._id]}
                    useImageCDN={useImageCDN}
                  />
                </li>
              ))
              :
              seriesFiltered.map(serie => (
                <li key={serie._id} className='articleListItem'>
                  <CardPortrait
                    serie={serie}
                    handleOnChangePreferences={handleOnChangePreferences}
                    handleOnClickWatchNow={handleOnClickWatchNow}
                    link={`/serie/${serie._id}`}
                    preference={preferencesKeyedById[serie._id]}
                    useImageCDN={useImageCDN}
                  />
                </li>
              ))
            }

          </ul>
        </section>
      </div>
      <Footer />
    </main>
  )
}
