import React from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import moment from 'moment'
import 'moment/locale/es-mx'

import { Icons } from './Icons'
import LockIcon from '../assets/icons/candado.svg'

moment.locale('es-mx')

export const CardInfoEvent = (props) => {
  const { vod, isUnlocked, pay, type, useImageCDN } = props

  // Definir clase para tag de eventos
  const eventTagClass = !isUnlocked && pay ? 'eventTag free' : 'eventTag'

  // Minutos de un día
  const day = 1440
  // Fecha de inicio y término evento
  // let dateStartEvent = moment("2023-03-18T17:00:00.000Z")
  let dateStartEvent = moment(vod.dateStart)
  // let dateEndEvent = moment("2023-03-18T17:31:00.000Z")
  let dateEndEvent = moment(vod.dateEnd)
  let eventDuration = dateStartEvent.diff(dateEndEvent, 'minutes')
  // Llamar fecha actual desde la API
  let dateNow = moment("2023-03-18T16:30:00.000Z")
  let dateFromNow = dateStartEvent.diff(dateNow, 'minutes')

  // Defino la hora actual para moment
  moment.now = function () {
    return +new Date(dateNow) // 24 November, 2018
  }

  const currTagName = (eventDiffNow) => {
    if (eventDiffNow >= day * 7) {
      //fecha mayor a 7 días
      return moment(dateStartEvent).format("D [de] MMMM")
    } else if (eventDiffNow >= day && eventDiffNow < day * 7) {
      //faltan "X" días
      return moment(dateStartEvent).fromNow()
    } else if (eventDiffNow < day & eventDiffNow > 15) {
      //hoy, horario
      return moment(dateStartEvent).format("HH[:]mm [Hrs]")
    } else if (eventDiffNow <= 15 && eventDiffNow > 0) {
      //en "X" minutos
      return moment(dateStartEvent).fromNow()
    } else if (eventDiffNow > eventDuration) {
      return "En vivo"
    } else if (eventDiffNow <= eventDuration) {
      return "Finalizado"
    } else {
      // Retorno fecha del evento si fallan todas
      return moment(dateStartEvent).format("D [de] MMMM, HH[:]mm [Hrs]")
    }
  }

  //función para retornar link de evento live o vod
  const linkUrl = () => {
    if (!isUnlocked && pay) {
      return "/planes"
    } else if (type === "Live" && (dateFromNow > 0 || dateFromNow <= eventDuration)) {
      return null
    } else if (type === "Live") {
      return `/player/live/${vod._id}`
    } else {
      return `/player/vod/${vod._id}`
    }
  }

  return (
    <Link to={linkUrl}>
      <article className='articleItemBox whitVisibleTitle'>
        <figure className='ratioImgBox ratioThumb'>
          {type === 'Live' &&
            <span
              className={eventTagClass}
              data-tagname={currTagName(dateFromNow)}
            >
              {currTagName(dateFromNow)}
            </span>
          }
          {!isUnlocked && pay && <Icons path={LockIcon} className='lockIcon' />}
          <img
            src={!isEmpty(vod.imagesSite.thumbnail)
              ? useImageCDN
                ? vod.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                : vod.imagesSite.thumbnail
              : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
            }
            alt=''
          />
        </figure>
        <div className='continueBar' style={{ 'width': '100%', 'display': vod.position ? 'block' : 'none' }}>
          <span style={{
            'width': `${(isNaN((vod.position / vod.duration)) ? 0 : (vod.position / vod.duration) * 100)}%`
          }} />
        </div>
        <header>
          <p>{vod.resume}</p>
          <h3>{vod.title}</h3>
        </header>
      </article>
    </Link>
  )
}