import { all, call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { compact, isEmpty, keyBy, merge, omit, uniqBy } from 'lodash'
import { getDocs, getFirestore, collection, orderBy, query } from 'firebase/firestore'

import {
  GET_CONTINUE_WATCHING,
  GET_CONTINUE_WATCHING_START,
  GET_CONTINUE_WATCHING_SUCCESS,
  GET_CONTINUE_WATCHING_ERROR,
  GET_CONTINUE_WATCHING_FINALLY,
  GET_NOT_VIEWED_CONTINUE_WATCHING,
  GET_NOT_VIEWED_CONTINUE_WATCHING_START,
  GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS,
  GET_NOT_VIEWED_CONTINUE_WATCHING_ERROR,
  GET_NOT_VIEWED_CONTINUE_WATCHING_FINALLY,
} from '../../../redux/actions'

function* GetContinueWatchingAction({ payload: { keycloak, idCurrentProfile } }) {
  yield put(GET_CONTINUE_WATCHING_START())
  let token = keycloak.token
  const sub = keycloak.subject

  const db = getFirestore()

  try {
    var vods = []
    const q = query(
      collection(db, 'users', sub, 'profiles', idCurrentProfile, 'continue_watching'),
      orderBy('viewedLastAt', 'desc')
    )
    const querySnapshot = yield call(getDocs, q)
    querySnapshot.forEach(doc => vods.push(doc.data()))

    if (vods.length > 0) {
      const isTokenUpdated = yield call(keycloak.updateToken, 5)
      token = isTokenUpdated ? keycloak.token : token

      const headers = {
        headers: { 'authorization': `Bearer ${token}` },
        timeout: 10000
      }

      const queryIds = vods.map(vod => `ids=${vod.id}`).join('&')
      const { data: contents } = yield call(axios.get,
        `${process.env.REACT_APP_API_URL}screen/vods?${queryIds}`,
        headers
      )

      const vodsById = keyBy(vods, 'id')
      const mergedVods = contents.map(content => merge(content, omit(vodsById[content._id], 'id')))

      yield put(GET_CONTINUE_WATCHING_SUCCESS(mergedVods))
    } else {
      yield put(GET_CONTINUE_WATCHING_SUCCESS([]))
    }
  } catch (error) {
    yield put(GET_CONTINUE_WATCHING_ERROR(error.message))
  } finally {
    yield put(GET_CONTINUE_WATCHING_FINALLY())
  }
}

function* GetNotViewedContinueWatchingAction({ payload: { keycloak, idCurrentProfile } }) {
  yield put(GET_NOT_VIEWED_CONTINUE_WATCHING_START())
  let token = keycloak.token
  const sub = keycloak.subject

  const db = getFirestore()

  try {
    var vods = []
    const q = query(
      collection(db, 'users', sub, 'profiles', idCurrentProfile, 'continue_watching'),
      orderBy('viewedLastAt', 'desc')
    )
    const querySnapshot = yield call(getDocs, q)
    querySnapshot.forEach(doc => vods.push(doc.data()))

    const vodsUniqBySerie = uniqBy(vods, (vod) => vod.serieId)

    if (vodsUniqBySerie.length > 0) {
      const isTokenUpdated = yield call(keycloak.updateToken, 5)
      token = isTokenUpdated ? keycloak.token : token

      const headers = {
        headers: { 'authorization': `Bearer ${token}` },
        timeout: 10000
      }

      const continueWatching = yield all(vodsUniqBySerie.map(vod => {
        if (vod.isViewed === true) {
          return call(GetNextVod, { continueWatchingVod: vod, headers })
        } else {
          return vod
        }
      }))

      const queryIds = compact(continueWatching).map(vod => `ids=${vod.id}`).join('&')
      const { data: contents } = yield call(axios.get,
        `${process.env.REACT_APP_API_URL}screen/vods?${queryIds}`,
        headers
      )

      const vodsById = keyBy(vods, 'id')
      const mergedVods = contents.map(content => merge(content, omit(vodsById[content._id], 'id')))

      yield put(GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS(mergedVods))
    } else {
      yield put(GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS([]))
    }
  } catch (error) {
    yield put(GET_NOT_VIEWED_CONTINUE_WATCHING_ERROR(error.message))
  } finally {
    yield put(GET_NOT_VIEWED_CONTINUE_WATCHING_FINALLY())
  }
}

function* GetNextVod({ continueWatchingVod, headers }) {
  const { data } = yield call(axios.get,
    `${process.env.REACT_APP_API_URL}screen/vods?ids=${continueWatchingVod.id}`,
    headers
  )

  if (!isEmpty(data[0])) {
    const allVodsAux = []
    data[0].serie.seasons.forEach(season => season.vods?.forEach(vod => allVodsAux.push(vod)))
    const mediaIndex = allVodsAux.findIndex(vod => vod._id === continueWatchingVod.id)

    return allVodsAux[mediaIndex]._id === allVodsAux[allVodsAux.length - 1]._id
      ? null
      : {
        id: allVodsAux[mediaIndex + 1]._id,
        serieId: continueWatchingVod.serieId,
        viewedLastAt: continueWatchingVod.viewedLastAt,
      }
  } else {
    return null
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_CONTINUE_WATCHING, GetContinueWatchingAction)
  yield takeLatest(GET_NOT_VIEWED_CONTINUE_WATCHING, GetNotViewedContinueWatchingAction)
}