import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { SET_COMPENSATION } from '../subscription/subscription.actions'

import Loading from '../Loading'
import CompensationModalContainer from '../../containers/compensation/CompensationModalContainer'

export default memo(function CompensationModalComponent({ openModal, setOpenModal }) {
  const { isAuthenticated, keycloak, email } = useSelector(state => state.auth)
  const {
    isLoading: isLoadingSubscription,
    data:
    subscription,
    subscriber,
    compensationError,
    isLoadingCompensation
  } = useSelector(state => state.subscription)
  const [openDialogcoupon, setOpenDialogcoupon] = useState(false)
  const [clientRequest, setClientRequest] = useState('')
  const [openDialogdevolution, setOpenDialogdevolution] = useState(false)
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false)
  const [openDialogAccept, setOpenDialogAccept] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  const handleOpen = () => { setOpenModal(true) }
  const handleClose = () => { setOpenModal(false) }

  const handleOpenDialogCupon = () => {
    setOpenDialogcoupon(true)
    setOpenModal(false)
    setClientRequest('COUPON_COMPENSATION')
  }

  const handleOpenDialogDevolution = () => {
    setOpenDialogdevolution(true)
    setOpenModal(false)
    setClientRequest('REFOUND_COMPENSATION')
  }

  useEffect(() => {
    if (openDialogcoupon) {
      handleOpenDialogConfirm(clientRequest)
      setOpenDialogConfirm(true)
    } if (openDialogdevolution) {
      handleOpenDialogConfirm(clientRequest)
      setOpenDialogConfirm(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialogcoupon, openDialogdevolution])

  const handleCloseDialogConfirm = () => {
    setOpenDialogdevolution(false)
    setOpenDialogcoupon(false)
    setOpenDialogConfirm(false)
    setOpenModal(true)
  }

  const handleCloseDialogConfirmX = () => {
    setOpenDialogConfirm(false)
    setOpenDialogdevolution(false)
    setOpenDialogcoupon(false)
    setOpenModal(false)
  }

  const handleOpenDialogConfirm = () => {
    setOpenDialogConfirm(true)
  }

  const handleCloseDialogAcept = () => {
    setOpenDialogAccept(false)
    window.location.reload();
  }

  const handleOpenDialogAccept = () => {
    handleOnSubscriptionDevolutions(clientRequest)
    setOpenDialogAccept(true)
    setOpenDialogcoupon(false)
    setOpenDialogdevolution(false)
    setOpenModal(false)
    setOpenDialogConfirm(false)
  }

  const handleOnSubscriptionDevolutions = (clientRequest) => {
    dispatch(SET_COMPENSATION({
      keycloak,
      clientRequest,
      subscriber_id: subscriber.id,
      transaction_id: subscription.Transaction.transaction_id
    }))
  }

  return (
    (isLoadingSubscription || isEmpty(subscription))
      ?
      <Loading />
      :
      <CompensationModalContainer
        clientRequest={clientRequest}
        compensationError={compensationError}
        subscription={subscription}
        email={email}
        handleOnSubscriptionDevolutions={handleOnSubscriptionDevolutions}
        openModal={openModal}
        openDialogConfirm={openDialogConfirm}
        openDialogAccept={openDialogAccept}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleOpenDialogCupon={handleOpenDialogCupon}
        handleOpenDialogDevolution={handleOpenDialogDevolution}
        handleCloseDialogAcept={handleCloseDialogAcept}
        handleOpenDialogAccept={handleOpenDialogAccept}
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        handleCloseDialogConfirmX={handleCloseDialogConfirmX}
        isLoadingCompensation={isLoadingCompensation}
        openDialogcoupon={openDialogcoupon}
      />
  )
})