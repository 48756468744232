import React, { memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Loading from '../Loading'
import RegisterContainer from '../../containers/login/RegisterContainer'

export default memo(function RegisterComponent() {
  const { isAuthenticated, keycloak } = useSelector(state => state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    !isAuthenticated && keycloak.register()
  }, [isAuthenticated, keycloak])

  return (
    !isAuthenticated
      ? <Loading />
      : <RegisterContainer navigate={navigate} keycloak={keycloak} />
  )
})