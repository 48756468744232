import React from 'react'
import Slider from 'react-slick'
import { intersection, isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { Icons } from '../../components/Icons'
import meganoticias from '../../assets/icons/meganoticias.svg'
import { CardInfo } from '../../components/CardInfo'
import { CardThumb } from '../../components/CardThumb'
import { CardNews } from '../../components/CardNews'
import { CardPortrait } from '../../components/CardPortrait'
import HomeHero from './HomeHeroContainer'

export default function Home(props) {
  const {
    handleOnChangePreferences,
    handleOnClickWatchNow,
    lists,
    news,
    preferencesKeyedById,
    sliderNewsSettings,
    sliderSettings,
    subscriptionsPlanIds,
    navigate,
    useImageCDN,
    sponsored
  } = props

  return (
    <main
      className={
        `appWrap fixedHero${lists?.filter(
          list => list.type === 'Highlighted' || list.isHighlighted)[0]?.content?.length > 1
          ? ' fixed-hero-container'
          : ''
        }`
      }
    >
      {lists.map(row => (
        (row.type === 'Highlighted' || row.isHighlighted) && row.content.length !== 0 ? (
          <HomeHero
            row={row}
            key={row._id}
            handleOnClickWatchNow={handleOnClickWatchNow}
            navigate={navigate}
            useImageCDN={useImageCDN}
            sponsored={sponsored}
          />
        ) : row.type === 'News' ? news.length > 0 && (
          <section key={row._id} className='rowXxl horizontalRow'>
            <header className='rowS mainWidth'>
              <h2>{row.title}<Icons path={meganoticias} /></h2>
            </header>
            <Slider {...sliderNewsSettings} className='newsSlider'>
              {(news).map(item => (
                <li key={item.url} className='articleListItem'>
                  <CardNews title={item.titulo} link={item.url} imgUrl={item.imagen} />
                </li>
              ))}
            </Slider>
          </section>
        ) : (row.title === 'Claro Sports en Mega GO' || row.title === 'Juegos Olímpicos París 2024') ?
          <section key={row._id} className='rowXxl horizontalRow featuredVideoList'>
            <header className='rowS mainWidth'>
              <h2>{row.title}</h2>
            </header>
            <div className='mainWidth'>
              <ul className='grid grid-4-cols'>
                {(row.content).map(content => (
                  <li key={content._id} className='articleListItem'>
                    <CardThumb
                      link={`/player/live/${content._id}`}
                      image={!isEmpty(content.imagesSite.thumbnail)
                        ? useImageCDN
                          ? content.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                          : content.imagesSite.thumbnail
                        : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
                      }
                      isUnlocked={!isEmpty(intersection(subscriptionsPlanIds, content.planIds || []))}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
          : row.content.length > 0 && (
            <section key={row._id} className='rowXxl horizontalRow'>
              <header className='rowS mainWidth'>
                <h2>{row.title}</h2>
              </header>
              <Slider {...sliderSettings}>
                {(row.content).map(content => (
                  <div key={content._id} className='articleListItem'>
                    {row.type === 'Vod' ?
                      <CardInfo
                        vod={content}
                        isHome
                        isUnlocked={!isEmpty(intersection(subscriptionsPlanIds, content.serie?.planIds || []))}
                        pay={content.pay}
                        showSeason={content.serie?.seasons?.length > 1 || false}
                        useImageCDN={useImageCDN}
                      />
                      : row.type === 'Live' ?
                        <CardThumb
                          link={`/player/live/${content._id}`}
                          image={!isEmpty(content.imagesSite.thumbnail)
                            ? useImageCDN
                              ? content.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                              : content.imagesSite.thumbnail
                            : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
                          }
                          isUnlocked={!isEmpty(intersection(subscriptionsPlanIds, content.planIds || []))}
                        />
                        : row.type === 'Serie' ?
                          <CardPortrait
                            serie={content}
                            handleOnChangePreferences={handleOnChangePreferences}
                            handleOnClickWatchNow={handleOnClickWatchNow}
                            link={`/serie/${content._id}`}
                            preference={preferencesKeyedById[content._id]}
                            useImageCDN={useImageCDN}
                          />
                          : row.type === 'Event' &&
                          <CardPortrait
                            serie={content}
                            handleOnChangePreferences={handleOnChangePreferences}
                            handleOnClickWatchNow={handleOnClickWatchNow}
                            link={`/evento/${content._id}`}
                            preference={preferencesKeyedById[content._id]}
                            useImageCDN={useImageCDN}
                          />
                    }
                  </div>
                ))}
                <div key='last-item-placeholder' className='articleListItem'></div>
              </Slider>
            </section>
          )
      ))}
      <Footer />
    </main>
  )
}