import React from 'react';
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { v4 as uuid } from 'uuid';

import ProfilesList from '../../components/ProfilesList';
import { Icons } from '../../components/Icons';
import iconArrowBack from '../../assets/icons/arrowBack.svg';

export default function ProfileSelectionContainer(props) {
  const { navigate, useImageCDN } = props;
  const { data: profiles } = useSelector(state => state.profiles);

  return (
    <main className='appWrap'>
      <Button variant='contained' className='medium goBack' onClick={() => navigate('/cuenta')} startIcon={<Icons path={iconArrowBack} />}>Volver</Button>
      <div className='stagedProfilesWrap'>
        <div className='stagedProfiles'>
          <header className='mediumHeader centered'>
            <h3>Selecciona un perfil para editar</h3>
          </header>
          <ProfilesList
            useImageCDN={useImageCDN}
          />
          {profiles.length < 6 &&
            <Link to={`/cuenta/editar/${uuid()}`}>
              <Button variant='contained'>Crear nuevo</Button>
            </Link>
          }
        </div>
      </div>
    </main>
  );
}