import React from "react";
import Button from "@mui/material/Button";

import { Icons } from "../../components/Icons";
import megaGo from "../../assets/icons/megaGo.svg";
import iconPlay from "../../assets/icons/play.svg";

export default function PreHomeContainer(props) {
  const { counter, navigate, titleEvent, urlEvent, preHomeTextButton } = props;

  return (
    <main className="preHomeContainer">
      <div className="preHomeBox">
        <div className="preHomeHeader">
          <Icons path={megaGo} />
          <p>Evento en vivo</p>
        </div>
        <h2>{titleEvent}</h2>
        <div className="buttonsWrap">
          <Button
            variant="contained"
            className="dark"
            onClick={() => navigate("/inicio")}
          >
            Ir a Mega GO
          </Button>
          <Button
            variant="contained"
            startIcon={<Icons path={iconPlay} />}
            onClick={() => navigate(urlEvent)}
          >
            {preHomeTextButton}
          </Button>
        </div>
        <p className="countDown">
          Serás redireccionado en {counter} segundos
        </p>
      </div>
    </main>
  );
}
