import React from 'react'
import { isEmpty } from 'lodash'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import Loading from '../../components/Loading'

export default function CompensationModalContainer(props) {
  const {
    clientRequest,
    compensationError,
    subscription: {
      Refound,
      next_pay_date,
      Plan: plan
    },
    email,
    openModal,
    openDialogConfirm,
    openDialogAccept,
    handleOpen,
    handleClose,
    handleOpenDialogCupon,
    handleOpenDialogDevolution,
    handleCloseDialogAcept,
    handleOpenDialogAccept,
    handleCloseDialogConfirm,
    handleCloseDialogConfirmX,
    isLoadingCompensation,
    openDialogcoupon
  } = props

  const date = new Date(next_pay_date)
  const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }

  return (
    !isEmpty(Refound) && (
      <>
        <Grid variant="extended"
          onClick={handleOpen}
          className='divModal'>
          <ErrorOutlineIcon className='iconPending' />
          Tienes una devolución pendiente, por favor revisa aquí.
          <IconButton style={{ border: 'none' }} />
        </Grid>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='styleBox'>
            <IconButton
              className='IconButtonX'
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <h5 className='textTitleTypography' id="modal-modal-title">
              Aviso de devolución
            </h5>
            <p className='textDescription' id="modal-modal-description">
              Tenemos una devolución pendiente que asciende a <strong style={{ color: 'black' }}>${(Refound.amount * Refound.price).toLocaleString('de') || '--'}</strong>, debido a problemas en el cobro de tu suscripción. Dada esta situación, te ofrecemos las siguientes opciones para remediar lo acontecido:
            </p>
            <Grid className='gridinfomodal' style={{ marginTop: '30px' }}>
              <p>
                <strong>
                  {Refound.offer} {plan.Periodicity.unity === 'MONTH'
                    ? (Refound.amount > 1 ? 'meses' : 'mes')
                    : (plan.Periodicity.unity === 'YEAR' ? 'año' : '')} de suscripción gratuita</strong> que equivalen a <strong>${(Refound.offer * Refound.price).toLocaleString('de') || '--'}</strong>
              </p>
              <Button variant='contained' className='btncupon' onClick={handleOpenDialogCupon} ><p>¡Solicitar descuento!</p></Button>
            </Grid>
            <Grid className='gridinfomodal gridinfomodalDevo'>
              <p style={{ textAlign: 'center' }}>
                Devolución de <strong>${(Refound.amount * Refound.price).toLocaleString('de') || '--'}</strong>, correspondiente a <strong>{Refound.amount} {plan.Periodicity.unity === 'MONTH' ? (Refound.amount > 1 ? 'meses' : 'mes') : (plan.Periodicity.unity === 'YEAR' ? 'año' : '')}</strong> de suscripción.
              </p>
              <Button variant='contained' className='btncompensation' onClick={handleOpenDialogDevolution}><p>Solicitar devolución</p></Button>
            </Grid>
          </Box>
        </Modal>
        <Dialog
          open={openDialogConfirm}
          onClose={handleCloseDialogConfirmX}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='contentDialogSmall'
        >
          <Grid className='gridContentdialog'>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialogConfirmX}
              className='IconButtonX'
            >
              <CloseIcon />
            </IconButton>
            <p id="alert-dialog-title" className='textTitleTypographyconfirm'>
              Por favor confirma tu solicitud
            </p>
            <DialogContent>
              <p className='textDescriptiondevolution'>
                {openDialogcoupon
                  ?
                  <>
                    {Refound.offer} {plan.Periodicity.unity === 'MONTH'
                      ? (Refound.amount > 1 ? 'meses' : 'mes')
                      : (plan.Periodicity.unity === 'YEAR' ? 'año' : '')} de suscripción gratuita.
                  </>
                  :
                  <>
                    <>
                      Devolución de {Refound.amount} {plan.Periodicity.unity === 'MONTH'
                        ? (Refound.amount > 1 ? 'meses' : 'mes')
                        : (plan.Periodicity.unity === 'YEAR' ? 'año' : '')} correspondiente a ${(Refound.amount * plan.amount).toLocaleString('de') || '--'}*
                    </>
                    <span style={{ fontSize: '12px', textAlign: 'center', lineHeight: '1.5', display: 'block', padding: '16px 20px 0', color: '#968e8e' }}>
                      *Nos contactaremos al correo: {email} para solicitar tus datos y proceder con la devolución.
                    </span>
                  </>
                }
              </p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button variant='contained' className='btnCancel' onClick={handleCloseDialogConfirm} ><p>Volver</p></Button>
              <Button variant='contained' className='btnAccept' onClick={handleOpenDialogAccept} ><p>Confirmar</p></Button>
            </DialogActions>
          </Grid>
        </Dialog>
        <Dialog
          className='dialogAccept'
          open={openDialogAccept}
          onClose={handleCloseDialogAcept}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid className='gridContentdialogfinalizado'>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialogAcept}
              className='IconButtonX'
            >
              <CloseIcon />
            </IconButton>
            {
              isLoadingCompensation ? (
                <Loading styles={{ position: 'relative', padding: '80px 0px' }} />
              ) : compensationError ? (
                <>
                  <h5 id="alert-dialog-title" className='textTitleTypographyconfirm'>
                    Error
                  </h5>
                  <p className='textDescriptionacept'>
                    Ocurrió un error al procesar tu solicitud, por favor intenta más tarde.
                  </p>
                </>
              ) : clientRequest === 'COUPON_COMPENSATION' ? (
                <>
                  <h5 id="alert-dialog-title" className='textTitleTypographyconfirm'>
                    Cupón asignado
                  </h5>
                  <p className='textDescriptionacept'>
                    El cupón fue asignado exitosamente, muchas gracias por ser parte de Mega GO.
                  </p>
                  <DialogActions className='dialogActions'>
                    <Button variant='contained' className='btnAccept' onClick={handleCloseDialogAcept}>
                      <p>Seguir en Mega GO</p>
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <h5 id="alert-dialog-title" className='textTitleTypographyconfirm'>
                    Solicitud aceptada
                  </h5>
                  <p className='textDescriptionacept'>
                    El proceso será realizado a la brevedad, muchas gracias por ser parte de Mega GO.
                  </p>
                  <DialogActions className='dialogActions'>
                    <Button variant='contained' className='btnAccept' onClick={handleCloseDialogAcept}>
                      <p>Seguir en Mega GO</p>
                    </Button>
                  </DialogActions>
                </>
              )
            }
          </Grid>
        </Dialog>
      </>
    )
  )
}