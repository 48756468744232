import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Fade,
  Tooltip
} from '@mui/material'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material/'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { CardInfo } from '../../components/CardInfo'
import { Icons } from '../../components/Icons'
import iconStar from '../../assets/icons/star.svg'
import iconStarOutline from '../../assets/icons/starOutline.svg'
import iconThumbUp from '../../assets/icons/thumbUp.svg'
import iconThumbUpOutline from '../../assets/icons/thumbUpOutline.svg'
import iconThumbDown from '../../assets/icons/thumbDown.svg'
import iconThumbDownOutline from '../../assets/icons/thumbDownOutline.svg'
import iconArrowDown from '../../assets/icons/arrowDown.svg'
import iconPlay from '../../assets/icons/play.svg'
import LockIcon from '../../assets/icons/candado.svg'
import SerieHeroContainer from './SerieHeroContainer'

export default function SerieContainer(props) {
  const {
    serie: {
      imagesSite,
      resume,
      seasons,
      vodTrailer,
      _id
    },
    watchNow,
    seriePreferences,
    currentSeason,
    setCurrentSeason,
    handleOnChangePreferences,
    isUnlocked,
    useImageCDN,
    sponsored
  } = props

  const array = seasons?.[currentSeason]?.vods || []
  const paginateNumber = 12
  // Tamaño máximo del array
  const maxArrayLength = array.length
  const [minArray, setMinArray] = useState(0)
  const [maxArray, setMaxArray] = useState(paginateNumber)
  const [hasMoreItems, setHasMoreItems] = useState(true)
  // Función para dividir el array
  const sliceArray = (first, last, season) => {
    return seasons?.[season]?.vods?.slice(first, last) || []
  }

  const [items, setItems] = useState(sliceArray(minArray, maxArray, currentSeason))
  const [isVideoShown, setIsVideoShown] = useState(false)
  const buttonRef = useRef(null)

  let timeoutId = null

  const handleMouseHover = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => { setIsVideoShown(true) }, 1000)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutId)
    setIsVideoShown(false)
  }

  // Al cambiarse el item se actualiza al siguiente array
  useEffect(() => {
    setMinArray(prevMinArray => prevMinArray + paginateNumber)
    setMaxArray(prevMaxArray => prevMaxArray + paginateNumber)
  }, [items])

  const fetchMoreData = () => {
    let nextArray = sliceArray(minArray, maxArray, currentSeason)

    if (items.length >= maxArrayLength) {
      setHasMoreItems(false)
      return
    }
    setItems(items.concat(nextArray))
  }

  const handleSeasonLazy = (event) => {
    setCurrentSeason(event.target.value)
    setMinArray(0)
    setMaxArray(paginateNumber)
    setItems(sliceArray(0, paginateNumber, event.target.value))
    setHasMoreItems(true)
  }

  useEffect(() => {
    setMinArray(0)
    setMaxArray(paginateNumber)
    setHasMoreItems(true)
    setItems(sliceArray(0, paginateNumber, currentSeason))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasons])

  return (
    <main className='appWrap fixedHero'>
      <SerieHeroContainer
        vodTrailer={vodTrailer}
        imagesSite={imagesSite}
        useImageCDN={useImageCDN}
        sponsored={sponsored}
        serieId={_id}
      />
      <div className='mainWidth homeRowsWrap'>
        <section className='rowXxl showInfoHeader'>
          <div className='serieData'>
            <section className='serieReaction'>
              <div className='containerReaction'>
                <img
                  src={!isEmpty(imagesSite?.logo)
                    ? useImageCDN
                      ? imagesSite.logo.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : imagesSite.logo
                    : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-logo.png'
                  }
                  alt=''
                  className='serieLogo'
                />
                <div className='buttonGroup'>
                  <Button
                    variant='outlined'
                    startIcon={<Icons path={seriePreferences?.favorite ? iconStar : iconStarOutline} />}
                    onClick={() => handleOnChangePreferences({ favorite: !seriePreferences?.favorite || false })}
                  >
                    Favorito
                  </Button>
                  <IconButton
                    color='primary'
                    aria-label='me gusta'
                    onClick={() => handleOnChangePreferences({ thumb: (seriePreferences?.thumb || '') === 'UP' ? '' : 'UP' })}
                  >
                    <Icons path={(seriePreferences?.thumb || '') === 'UP' ? iconThumbUp : iconThumbUpOutline} />
                  </IconButton>
                  <IconButton
                    color='primary'
                    aria-label='no me gusta'
                    onClick={() => handleOnChangePreferences({ thumb: (seriePreferences?.thumb || '') === 'DOWN' ? '' : 'DOWN' })}
                  >
                    <Icons path={(seriePreferences?.thumb || '') === 'DOWN' ? iconThumbDown : iconThumbDownOutline} />
                  </IconButton>
                </div>
              </div>
              {_id === sponsored._id &&
                <a href={sponsored.url} target="_blank" className='sponsoredLink' rel="noreferrer">
                  <span>Patrocinado por:</span>
                  <img
                    src={sponsored.imageUrl}
                    alt='placeholder'
                  />
                </a>
              }
            </section>
            <div className='serieDescription'>
              <p>{resume}</p>
            </div>
            {/* <div className='serieCast'>
              <p>Elenco: <span>Paola Volpato, Francisco Melo, Fernando Godoy, Francisca Imboden, Íngrid Cruz</span></p>
            </div> */}
          </div>
          {!isEmpty(watchNow) &&
            <div className='cardLandscape'>
              <Link to={!isUnlocked && watchNow.pay ? '/planes' : `/player/vod/${watchNow._id}`}>
                <article
                  className='articleItemBox whitVisibleTitle'
                  onMouseEnter={() => handleMouseHover()}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <figure className='ratioImgBox ratioThumb'>
                    {!isUnlocked && watchNow.pay && <Icons path={LockIcon} className='lockIcon' />}
                    <img
                      src={!isEmpty(watchNow.imagesSite.thumbnail)
                        ? useImageCDN
                          ? watchNow.imagesSite.thumbnail.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                          : watchNow.imagesSite.thumbnail
                        : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-thumbnail.jpg'
                      }
                      alt=''
                    />
                    {isVideoShown && (
                      <Fade in={isVideoShown} timeout={600} style={{ transitionDelay: '500ms' }}>
                        <video id={`video_serieContainer_${watchNow.idMedia}`} autoPlay preload='auto' loop='loop' muted>
                          <source src={`https://mdstrm.com/video/${watchNow.idMedia}/preview.mp4`} type='video/mp4' />
                        </video>
                      </Fade>
                    )}
                  </figure>
                  <header>
                    <h3>{`${watchNow.serie.seasons.length > 1 ? `T${watchNow.season.order}:` : ''}E${Number(watchNow.order) + 1}`}</h3>
                    <p>{watchNow.title}</p>
                    <div className='continueBar' style={{ 'width': '100%', 'display': watchNow.position ? 'block' : 'none' }}>
                      <span style={{
                        'width': `${(isNaN((watchNow.position / watchNow.duration)) ? 0 : (watchNow.position / watchNow.duration) * 100)}%`,
                        'display': watchNow.position ? 'block' : 'none'
                      }} />
                    </div>
                    <Button
                      variant='contained'
                      startIcon={<Icons path={iconPlay} />}
                    >
                      {
                        watchNow.position === undefined
                          ? 'Ver ahora'
                          : watchNow.isViewed !== true
                            ? 'Seguir viendo'
                            : 'Volver a ver'
                      }
                    </Button>
                  </header>
                </article>
              </Link>
            </div>
          }
        </section>
        <section className='rowXxl'>
          <div className={`rowL btnWrap ${seasons?.length > 1 && 'withSeasons'}`}>
            <header className='rowS'>
              <h2>Episodios</h2>
            </header>
            {seasons?.length > 1 &&
              <FormControl>
                <Select
                  labelId='season-select'
                  id='season-select'
                  value={currentSeason}
                  onChange={handleSeasonLazy}
                  defaultValue={0}
                  className='selectBase'
                  IconComponent={() => (<Icons path={iconArrowDown} />)}
                >
                  {seasons.map((season, index) => (
                    <MenuItem value={index} key={index}>{season.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            <Tooltip title='Ordenar episodios' placement='top'>
              <Button
                ref={buttonRef}
                autoFocus={false}
                variant='outlined'
                className='idleColor iconBtn'
                size='large'
                onClick={() => { handleOnChangePreferences({ isOrderInverted: !seriePreferences?.isOrderInverted || false }) }}
                onKeyDown={(event) => {
                  event.preventDefault()
                  buttonRef.current.blur()
                }}
              >
                <p>Ordenar</p>
                {seriePreferences?.isOrderInverted ? <ArrowDownward /> : <ArrowUpward />}
              </Button>
            </Tooltip>
          </div>
          <InfiniteScroll
            style={{ overflow: 'visible' }}
            dataLength={items.length < array.length ? items.length : 10}
            className='grid grid-3-cols cardLandscape'
            next={fetchMoreData}
            hasMore={hasMoreItems}
            scrollThreshold={0.96}
          >
            {items.map(vod => (
              <li key={vod._id}>
                <CardInfo
                  vod={vod}
                  isUnlocked={isUnlocked}
                  pay={vod.pay}
                  showSeason={vod.serie?.seasons?.length > 1 || false}
                  useImageCDN={useImageCDN}
                />
              </li>
            ))}
          </InfiniteScroll>
        </section>
      </div>
      <Footer />
    </main>
  )
}