import { handleActions } from 'redux-actions';

import {
    GET_LISTS_START,
    GET_LISTS_SUCCESS,
    GET_LISTS_ERROR,
    GET_LISTS_FINALLY,
} from '../../../redux/actions';

const initialState = {
    accessToken: '',
    data: [],
    error: null,
    isLoading: true,
};

export default handleActions({
    [GET_LISTS_START]: state => ({
        ...state,
        isLoading: true,
        error: false
    }),
    [GET_LISTS_SUCCESS]: (state, action) => ({
        ...state,
        data: action.payload,
    }),
    [GET_LISTS_ERROR]: (state, action) => ({
        ...state,
        error: action.payload,
    }),
    [GET_LISTS_FINALLY]: state => ({
        ...state,
        isLoading: false
    }),
}, initialState);