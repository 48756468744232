import { handleActions } from 'redux-actions'

import {
  GET_PROFILES_START,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_ERROR,
  GET_PROFILES_FINALLY,
  SET_PROFILE_START,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_ERROR,
  SET_PROFILE_FINALLY,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_FINALLY,
  DELETE_PROFILE_START,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_ERROR,
  DELETE_PROFILE_FINALLY,
  SET_CURRENT_PROFILE,
  GET_PROFILE_PREFERENCES_START,
  GET_PROFILE_PREFERENCES_SUCCESS,
  GET_PROFILE_PREFERENCES_ERROR,
  GET_PROFILE_PREFERENCES_FINALLY,
  SET_PROFILE_PREFERENCES_START,
  SET_PROFILE_PREFERENCES_SUCCESS,
  SET_PROFILE_PREFERENCES_ERROR,
  SET_PROFILE_PREFERENCES_FINALLY,
} from '../../../redux/actions'

const initialState = {
  data: [],
  avatars: [],
  currentProfile: {},
  preferences: [],
  error: null,
  isLoading: true,
  isLoadingPreferences: false,
}

export default handleActions({
  [GET_PROFILES_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [GET_PROFILES_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.profilesWithAvatar,
    avatars: action.payload.avatars
  }),
  [GET_PROFILES_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_PROFILES_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [SET_PROFILE_START]: state => ({
    ...state,
    error: false,
    isLoading: true
  }),
  [SET_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    data: [
      ...state.data,
      {
        ...action.payload,
        avatar: state.avatars.find(avatar => avatar._id === action.payload.idAvatar)
      }
    ],
  }),
  [SET_PROFILE_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [SET_PROFILE_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [UPDATE_PROFILE_START]: state => ({
    ...state,
    error: false,
    isLoading: true
  }),
  [UPDATE_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    data: state.data.map(profile => {
      return profile.id === action.payload.id
        ? {
          ...profile,
          ...action.payload,
          avatar: state.avatars.find(avatar => avatar._id === action.payload.idAvatar)
        }
        : profile
    }),
  }),
  [UPDATE_PROFILE_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [UPDATE_PROFILE_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [DELETE_PROFILE_START]: state => ({
    ...state,
    error: false,
    isLoading: true
  }),
  [DELETE_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    data: state.data.filter(profile => profile.id !== action.payload),
  }),
  [DELETE_PROFILE_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [DELETE_PROFILE_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [SET_CURRENT_PROFILE]: (state, action) => ({
    ...state,
    currentProfile: action.payload
  }),
  [GET_PROFILE_PREFERENCES_START]: state => ({
    ...state,
    isLoadingPreferences: true,
    error: false
  }),
  [GET_PROFILE_PREFERENCES_SUCCESS]: (state, action) => ({
    ...state,
    preferences: action.payload,
  }),
  [GET_PROFILE_PREFERENCES_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_PROFILE_PREFERENCES_FINALLY]: state => ({
    ...state,
    isLoadingPreferences: false
  }),
  [SET_PROFILE_PREFERENCES_START]: state => ({
    ...state,
    error: false
  }),
  [SET_PROFILE_PREFERENCES_SUCCESS]: (state, action) => ({
    ...state,
    preferences: action.payload,
  }),
  [SET_PROFILE_PREFERENCES_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [SET_PROFILE_PREFERENCES_FINALLY]: state => ({
    ...state,
  }),
}, initialState)