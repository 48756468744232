import { call, put, takeLatest } from 'redux-saga/effects'
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from '@firebase/firestore'
import axios from 'axios'
import { omit } from 'lodash'

import {
  GET_MEDIA,
  GET_MEDIA_START,
  GET_MEDIA_SUCCESS,
  GET_MEDIA_ERROR,
  GET_MEDIA_FINALLY,
  GET_MDSTRM_ACCESS_TOKEN,
  GET_MDSTRM_ACCESS_TOKEN_START,
  GET_MDSTRM_ACCESS_TOKEN_SUCCESS,
  GET_MDSTRM_ACCESS_TOKEN_ERROR,
  GET_MDSTRM_ACCESS_TOKEN_FINALLY,
  SET_CONTINUE_WATCHING,
  SET_CONTINUE_WATCHING_START,
  SET_CONTINUE_WATCHING_SUCCESS,
  SET_CONTINUE_WATCHING_ERROR,
  SET_CONTINUE_WATCHING_FINALLY,
  UPDATE_CONTINUE_WATCHING
} from '../../../redux/actions'

function* GetMediaAction({ payload: { keycloak, idCurrentProfile, type, id } }) {
  try {
    yield put(GET_MEDIA_START())
    let token = keycloak.token
    const sub = keycloak.subject

    const db = getFirestore()
    const continueWatching = doc(db, 'users', sub, 'profiles', idCurrentProfile, 'continue_watching', id)

    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { status, data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/medias/${id}/${type}`,
      headers
    )

    if (status === 200) {
      const continueData = yield call(getDoc, continueWatching)

      const media = continueData.exists()
        ? {
          ...data,
          ...omit(continueData.data(), 'id')
        }
        : data

      yield put(GET_MEDIA_SUCCESS(media))
    } else {
      yield put(GET_MEDIA_ERROR({ title: 'Ha ocurrido un error. Por favor intentar nuevamente.', code: 2008 }))
    }
  } catch (error) {
    yield put(GET_MEDIA_ERROR(error.message))
  } finally {
    yield put(GET_MEDIA_FINALLY())
  }
}

function* GetMdstrmAccessTokenAction({ payload: { keycloak, type, id } }) {
  yield put(GET_MDSTRM_ACCESS_TOKEN_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_MDSTRM_ACCESS_TOKEN_URL}/token/${type}/${id}`,
      headers
    )

    yield put(GET_MDSTRM_ACCESS_TOKEN_SUCCESS(data))
  } catch (error) {
    yield put(GET_MDSTRM_ACCESS_TOKEN_ERROR(error.message))
  } finally {
    yield put(GET_MDSTRM_ACCESS_TOKEN_FINALLY())
  }
}

function* SetContinueWatchingAction({ payload: { keycloak, idCurrentProfile, continueWatching } }) {
  yield put(SET_CONTINUE_WATCHING_START())
  const sub = keycloak.subject

  const db = getFirestore()
  const profilePreferences = doc(db, 'users', sub, 'profiles', idCurrentProfile, 'continue_watching', continueWatching.id)

  try {
    yield call(setDoc, profilePreferences, continueWatching, { merge: true })

    yield put(SET_CONTINUE_WATCHING_SUCCESS(continueWatching))
    yield put(UPDATE_CONTINUE_WATCHING(continueWatching))
  } catch (error) {
    yield put(SET_CONTINUE_WATCHING_ERROR(error.message))
  } finally {
    yield put(SET_CONTINUE_WATCHING_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_MEDIA, GetMediaAction)
  yield takeLatest(GET_MDSTRM_ACCESS_TOKEN, GetMdstrmAccessTokenAction)
  yield takeLatest(SET_CONTINUE_WATCHING, SetContinueWatchingAction)
}