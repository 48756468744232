import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, keyBy } from 'lodash'
import { useNavigate } from 'react-router-dom'

import CategoriesContainer from '../../containers/categories/CategoriesContainer'
import Loading from '../Loading'
import { watchNow } from '../../utils/watchNow'
import { CreateAnchor, DestroyAds } from '../Ads'

import {
  GET_SERIES,
  GET_EVENTS,
  GET_CATEGORIES,
  SET_PROFILE_PREFERENCES,
  GET_CONTINUE_WATCHING
} from '../../redux/actions'

export default memo(function CategoriesComponent() {
  const { isAuthenticated, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const { data: series, isLoading: isLoadingSeries } = useSelector(state => state.series)
  const { data: events, isLoading: isLoadingEvents } = useSelector(state => state.events)
  const { continueWatching, isLoading } = useSelector(state => state.continueWatching)
  const { currentProfile, preferences } = useSelector(state => state.profiles)
  const { data: categories, isLoading: isLoadingCategories } = useSelector(state => state.categories)
  const { isFreePlan } = useSelector(state => state.subscription)

  const [categoryIdSelected, setCategoryIdSelected] = useState('')
  const [seriesFiltered, setSeriesFiltered] = useState(series)
  const [preferencesKeyedById, setPreferencesKeyedById] = useState([])

  const [mergedShows, setMergedShows] = useState([])
  const [isEventSelected, setIsEventSelected] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const limit = 12
  const page = 1

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    isEmpty(series) && dispatch(GET_SERIES({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isEmpty(events) && dispatch(GET_EVENTS({ keycloak, limit, page }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !isLoadingEvents && !isLoadingSeries && setMergedShows([...events, ...series])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEvents, isLoadingSeries])

  useEffect(() => {
    isEmpty(categories) && dispatch(GET_CATEGORIES({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !isEmpty(categories) && setCategoryIdSelected(categories.find(category => category.isPreselected)._id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  useEffect(() => {
    dispatch(GET_CONTINUE_WATCHING({
      keycloak, idCurrentProfile: currentProfile.id
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile.id])

  useEffect(() => {
    if (!isEmpty(events) && events[0].categories[0] === categoryIdSelected) {
      setIsEventSelected(true)
    } else {
      setIsEventSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIdSelected])

  useEffect(() => {
    !isEmpty(series) &&
      !isEmpty(mergedShows) &&
      !isEmpty(categories) &&
      !isEmpty(categoryIdSelected) && setSeriesFiltered(mergedShows.filter(serie => serie.categories.includes(categoryIdSelected)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series, categories, categoryIdSelected, mergedShows])

  useEffect(() => {
    setPreferencesKeyedById(keyBy(preferences, 'id'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences])

  const handleOnChangeCategory = (event) => {
    setCategoryIdSelected(event.target.value)
  }

  const handleOnChangePreferences = (newPreference) => {
    const newPreferences = isEmpty(preferencesKeyedById[newPreference.id])
      ? {
        id: newPreference.id,
        favorite: false,
        thumb: "",
        ...newPreference
      } : {
        ...preferencesKeyedById[newPreference.id],
        ...newPreference
      }

    dispatch(SET_PROFILE_PREFERENCES({
      keycloak, idSerie: newPreference.id, idCurrentProfile: currentProfile.id, newPreferences
    }))
  }

  const handleOnClickWatchNow = (serie) => {
    if (!isEmpty(serie)) {
      if (!isEmpty(continueWatching)) {
        const continueWatchingFilteredBySerie = continueWatching.filter(vod => vod.idSerie === serie._id)

        if (!isEmpty(continueWatchingFilteredBySerie)) {
          watchNow(navigate, continueWatchingFilteredBySerie, serie)
        } else {
          !isEmpty(serie.seasons[0]?.vods[0])
            ? navigate(`/player/vod/${serie.seasons[0]?.vods[0]._id}`)
            : navigate(`/serie/${serie._id}`)
        }
      } else {
        !isEmpty(serie.seasons[0]?.vods[0])
          ? navigate(`/player/vod/${serie.seasons[0]?.vods[0]._id}`)
          : navigate(`/serie/${serie._id}`)
      }
    }
  }

  useEffect(() => {
    document.title = 'Mega GO | Categorías'
    return () => {
      document.title = 'Mega GO'
    }
  }, [])

  // Crea y destruye publicidades
  useEffect(() => {
    if (isFreePlan) {
      CreateAnchor('/ott/categorias/home/', {
        'seccion': 'categorias',
        'nivel': 'home',
        'nota': '',
        'id_nota': '',
        'tipo': 'otro',
        'keywords': ''
      })
      return () => {
        DestroyAds()
      }
    }
  }, [isFreePlan])

  return (
    isLoading || isLoadingCategories || isLoadingSeries || isLoadingEvents
      ?
      <Loading />
      :
      <CategoriesContainer
        categories={categories}
        categoryIdSelected={categoryIdSelected}
        isEventSelected={isEventSelected}
        currentProfile={currentProfile}
        handleOnChangeCategory={handleOnChangeCategory}
        handleOnChangePreferences={handleOnChangePreferences}
        handleOnClickWatchNow={handleOnClickWatchNow}
        preferencesKeyedById={preferencesKeyedById}
        seriesFiltered={seriesFiltered}
        useImageCDN={useImageCDN}
      />
  )
})