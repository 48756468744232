import { handleActions } from 'redux-actions';

import {
  GET_MEDIA_START,
  GET_MEDIA_SUCCESS,
  GET_MEDIA_ERROR,
  GET_MEDIA_FINALLY,
  GET_MDSTRM_ACCESS_TOKEN_START,
  GET_MDSTRM_ACCESS_TOKEN_SUCCESS,
  GET_MDSTRM_ACCESS_TOKEN_ERROR,
  GET_MDSTRM_ACCESS_TOKEN_FINALLY,
  SET_CONTINUE_WATCHING_START,
  SET_CONTINUE_WATCHING_SUCCESS,
  SET_CONTINUE_WATCHING_ERROR,
  SET_CONTINUE_WATCHING_FINALLY,
} from '../../../redux/actions';

const initialState = {
  accessToken: '',
  data: {},
  error: null,
  isLoading: true,
  isLoadingToken: true,
};

export default handleActions({
  [GET_MEDIA_START]: state => ({
    ...state,
    error: false,
    isLoading: true
  }),
  [GET_MEDIA_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
  [GET_MEDIA_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_MEDIA_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [GET_MDSTRM_ACCESS_TOKEN_START]: state => ({
    ...state,
    error: false,
    isLoadingToken: true
  }),
  [GET_MDSTRM_ACCESS_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    accessToken: action.payload.access_token,
  }),
  [GET_MDSTRM_ACCESS_TOKEN_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_MDSTRM_ACCESS_TOKEN_FINALLY]: state => ({
    ...state,
    isLoadingToken: false
  }),
  [SET_CONTINUE_WATCHING_START]: state => ({
    ...state,
    error: false,
    // isLoading: true
  }),
  [SET_CONTINUE_WATCHING_SUCCESS]: (state, action) => ({
    ...state,
    // data: { ...state.data, ...action.payload },
  }),
  [SET_CONTINUE_WATCHING_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [SET_CONTINUE_WATCHING_FINALLY]: state => ({
    ...state,
    // isLoading: false
  }),
}, initialState);