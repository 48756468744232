import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  AUTH,
  AUTH_KEYCLOAK_START,
  AUTH_KEYCLOAK_SUCCESS,
  AUTH_KEYCLOAK_ERROR,
  AUTH_FINALLY,
  GET_FIREBASE_TOKEN,
  GET_FIREBASE_TOKEN_START,
  GET_FIREBASE_TOKEN_SUCCESS,
  GET_FIREBASE_TOKEN_ERROR,
  GET_FIREBASE_TOKEN_FINALLY,
} from '../../redux/actions';

function* AuthAction({ payload: { keycloak, isAuthenticated } }) {
  yield put(AUTH_KEYCLOAK_START());

  if (keycloak) {
    if (isAuthenticated) {
      const { sub: idUser, email } = yield call(keycloak.loadUserInfo);

      yield put(AUTH_KEYCLOAK_SUCCESS({ keycloak, isAuthenticated, idUser, email }));
    } else {
      yield put(AUTH_KEYCLOAK_SUCCESS({ keycloak, isAuthenticated }));
    }
  } else {
    yield put(AUTH_KEYCLOAK_ERROR({ error: 'Problemas con Keycloak' }));
  }

  yield put(AUTH_FINALLY());
}

function* GetFirebaseTokenAction({ payload: { keycloak } }) {
  yield put(GET_FIREBASE_TOKEN_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.post,
      `${process.env.REACT_APP_MFB_API_URL}token`,
      {},
      headers
    )

    yield put(GET_FIREBASE_TOKEN_SUCCESS(data))
  } catch (error) {
    yield put(GET_FIREBASE_TOKEN_ERROR(error?.message ? error.message : ""))
  } finally {
    yield put(GET_FIREBASE_TOKEN_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(AUTH, AuthAction);
  yield takeLatest(GET_FIREBASE_TOKEN, GetFirebaseTokenAction);
};