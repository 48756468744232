import React from 'react'
import { Link } from 'react-router-dom'

import { Icons } from './Icons'
import LockIcon from '../assets/icons/candado.svg'

export const CardThumb = (props) => {
  const {
    link,
    image,
    isUnlocked
  } = props

  return (
    <Link to={!isUnlocked ? '/planes' : link}>
      <article className='articleItemBox'>
        <figure className='ratioImgBox ratioThumb'>
          {!isUnlocked && <Icons path={LockIcon} className='lockIcon' />}
          <img src={image} alt='' />
        </figure>
      </article>
    </Link>
  )
}