import { handleActions } from 'redux-actions'

import {
  GET_SERIE_START,
  GET_SERIE_SUCCESS,
  GET_SERIE_ERROR,
  GET_SERIE_FINALLY,
  GET_SERIES_START,
  GET_SERIES_SUCCESS,
  GET_SERIES_ERROR,
  GET_SERIES_FINALLY,
} from '../../../redux/actions'

const initialState = {
  data: [],
  serie: {},
  error: null,
  isLoading: false,
}

export default handleActions({
  [GET_SERIE_START]: state => ({
    ...state,
    isLoading: true,
    error: null
  }),
  [GET_SERIE_SUCCESS]: (state, action) => ({
    ...state,
    serie: action.payload,
  }),
  [GET_SERIE_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_SERIE_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [GET_SERIES_START]: state => ({
    ...state,
    isLoading: true,
    error: null
  }),
  [GET_SERIES_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
  [GET_SERIES_ERROR]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [GET_SERIES_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
}, initialState)