import { handleActions } from 'redux-actions';

import {
  GET_CONTINUE_WATCHING_START,
  GET_CONTINUE_WATCHING_SUCCESS,
  GET_CONTINUE_WATCHING_ERROR,
  GET_CONTINUE_WATCHING_FINALLY,
  GET_NOT_VIEWED_CONTINUE_WATCHING_START,
  GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS,
  GET_NOT_VIEWED_CONTINUE_WATCHING_ERROR,
  GET_NOT_VIEWED_CONTINUE_WATCHING_FINALLY,
  MERGE_CONTINUE_WATCHING_SERIE_START,
  MERGE_CONTINUE_WATCHING_SERIE_FINALLY,
  MERGE_CONTINUE_WATCHING_EVENT_START,
  MERGE_CONTINUE_WATCHING_EVENT_FINALLY,
  UPDATE_CONTINUE_WATCHING,
  GET_WATCH_NOW_SUCCESS,
} from '../../../redux/actions';

const initialState = {
  continueWatching: [],
  notViewedContinueWatching: [],
  watchNow: {},
  error: null,
  isLoading: true,
  isMerging: false,
};

export default handleActions({
  [GET_CONTINUE_WATCHING_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [GET_CONTINUE_WATCHING_SUCCESS]: (state, action) => ({
    ...state,
    continueWatching: action.payload
  }),
  [GET_CONTINUE_WATCHING_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [GET_CONTINUE_WATCHING_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [GET_NOT_VIEWED_CONTINUE_WATCHING_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS]: (state, action) => ({
    ...state,
    notViewedContinueWatching: action.payload
  }),
  [GET_NOT_VIEWED_CONTINUE_WATCHING_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [GET_NOT_VIEWED_CONTINUE_WATCHING_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [MERGE_CONTINUE_WATCHING_SERIE_START]: state => ({
    ...state,
    isMerging: true
  }),
  [MERGE_CONTINUE_WATCHING_SERIE_FINALLY]: state => ({
    ...state,
    isMerging: false
  }),
  [MERGE_CONTINUE_WATCHING_EVENT_START]: state => ({
    ...state,
    isMerging: true
  }),
  [MERGE_CONTINUE_WATCHING_EVENT_FINALLY]: state => ({
    ...state,
    isMerging: false
  }),
  [UPDATE_CONTINUE_WATCHING]: (state, action) => ({
    ...state,
    notViewedContinueWatching: state.notViewedContinueWatching.map(
      x => x._id === action.payload.id
        ? {
          ...x,
          duration: action.payload.duration,
          position: action.payload.position,
          isViewed: action.payload.isViewed,
        }
        : x
    ),
    continueWatching: state.continueWatching.map(
      x => x._id === action.payload.id
        ? {
          ...x,
          duration: action.payload.duration,
          position: action.payload.position,
          isViewed: action.payload.isViewed,
        }
        : x
    )
  }),
  [GET_WATCH_NOW_SUCCESS]: (state, action) => ({
    ...state,
    watchNow: action.payload,
  }),
}, initialState);