import React from 'react'
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from "firebase/remote-config"

import generateStore from './redux/store'
import Auth from './components/auth/Auth'
import { firebaseConfig } from './utils/firebaseConfig'

const app = initializeApp(firebaseConfig)
const remoteConfig = getRemoteConfig(app)

remoteConfig.defaultConfig = {
  "PLANS_SHOW_FIRST_VISIT_ON_WEB": false,
  "USE_IMAGE_CDN_ON_WEB": false
}

const store = generateStore()
const WithStore = () => <Provider store={store}><Auth remoteConfig={remoteConfig} /></Provider>

const root = createRoot(document.getElementById('root'))
root.render(<React.StrictMode>
  <WithStore />
</React.StrictMode>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()