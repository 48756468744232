import { isEmpty } from 'lodash'
import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Loading from '../Loading'

export default function DirectingComponent({isActivePrehome}) {
  const { isAuthenticated, keycloak, firebase } = useSelector(state => state.auth)

  let navigate = useNavigate();

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    if(!isEmpty(firebase) && isAuthenticated ){
      if(isActivePrehome === true && isActivePrehome !== undefined){
        navigate('/prehome')
      } else if (isActivePrehome !== undefined){
        navigate('/inicio')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivePrehome, firebase])
  
  return (
    <Loading />
  )
}
