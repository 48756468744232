import React from 'react'
import { Button, Grid, IconButton, Slider } from '@mui/material'
import { isEmpty } from 'lodash'

import { Icons } from '../Icons'
import iconPlay from '../../assets/icons/playerPlay.svg'
import iconPause from '../../assets/icons/playerPause.svg'
import iconFastForward from '../../assets/icons/playerFastForward.svg'
import iconFastBackward from '../../assets/icons/playerFastBackward.svg'
import iconNextVideo from '../../assets/icons/playerNext.svg'
import iconFullScreen from '../../assets/icons/playerFullScreen.svg'
import AudioIcon from '../../assets/icons/playerAudio.svg'
import iconArrowBack from '../../assets/icons/arrowBack.svg'
import AudioMutedIcon from '../../assets/icons/playerAudioMuted.svg'
import megaGo from '../../assets/icons/megaGo.svg'
import SliderWithPreview from './SliderWithPreview'

export default function PlayerControls(props) {
  const {
    controlsVisibility,
    elapsedTime,
    handleOnClose,
    navigate,
    muted,
    onForward,
    onMute,
    onPlayPause,
    onRewind,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    onToggleFullScreen,
    onVolumeChange,
    onVolumeSeekUp,
    played,
    playing,
    totalDuration,
    media: {
      order,
      season,
      serie,
      event,
      title,
    },
    type,
    volume,
    nextVod,
    vttContent,
    baseUrlImage,
    duration
  } = props

  return (
    <>
      <div className={controlsVisibility ? 'controlsWrapper' : 'controlsWrapper hide'}>
        {/* Top controls */}
        <Grid container direction='row' justifyContent='space-between' alignItems='center' className='topControls'>
          <Button
            variant='outlined'
            className='large'
            onClick={handleOnClose}
            startIcon={<Icons path={iconArrowBack} />}
          >
            Volver
          </Button>
          <Grid item>
            {<h3 className='title'>
              {type !== 'live' ? (serie ? serie.title : event.title) : title}
              <span text={1}>
                {type !== 'live' && serie !== null
                  ? ` | ${serie.seasons.length > 1 ? `T${season.order}:` : ''}${(serie.seasons[0]?.vods?.length ?? 0) > 0
                    ? `E${Number(order) + 1} `
                    : ''
                  }${title}`
                  : ' | En vivo'}
              </span>
            </h3>}
          </Grid>
          {type !== 'live' && !isEmpty(nextVod)
            ? <Button
              variant='outlined'
              className='large'
              endIcon={<Icons path={iconNextVideo} />}
              onClick={() => navigate(`/player/vod/${nextVod._id}`, { replace: true })}
            >
              Siguiente
            </Button>
            : <div className='MuiButtonBase-root MuiButton-root MuiButton-outlined large' style={{ visibility: 'hidden' }}>Siguiente</div>
          }
        </Grid>
        {/* Middle controls */}
        <Grid container direction='row' alignItems='center' justifyContent='center'>
          <Grid item>
            <IconButton onClick={onPlayPause} className='controlIcons bigPlay'>
              {playing ? <Icons path={iconPause} /> : <Icons path={iconPlay} />}
            </IconButton>
          </Grid>
        </Grid>
        {/* Bottom controls */}
        <Grid container direction='row' alignItems='center' justifyContent='space-between' className='bottomControls'>
          {type !== 'live' &&
            <Grid item xs={12}>
              <SliderWithPreview
                vttContent={vttContent}
                played={played}
                onSeek={onSeek}
                onSeekMouseDown={onSeekMouseDown}
                onSeekMouseUp={onSeekMouseUp}
                baseUrlImage={baseUrlImage}
                duration={duration}
              />
            </Grid>
          }
          <Grid item>
            <Grid container alignItems='center' direction='row' style={{ gap: '7px' }}>
              {/* play */}
              <IconButton onClick={onPlayPause} className='bottomIcons playIcon'>
                {playing ? <Icons path={iconPause} /> : <Icons path={iconPlay} />}
              </IconButton>
              {type !== 'live' &&
                <>
                  <IconButton onClick={onRewind} className='bottomIcons'>
                    <Icons path={iconFastBackward} />
                  </IconButton>
                  <IconButton onClick={onForward} className='bottomIcons'>
                    <Icons path={iconFastForward} />
                  </IconButton>
                </>
              }
              {/* mute */}
              <IconButton onClick={onMute} className='bottomIcons'>
                {muted ? <Icons path={AudioMutedIcon} /> : <Icons path={AudioIcon} />}
              </IconButton>
              {/* volume */}
              <Slider
                className='volumeSlider'
                min={0}
                max={100}
                value={muted ? 0 : volume}
                defaultValue={50}
                onChange={onVolumeChange}
                onChangeCommitted={onVolumeSeekUp}
              />
              {/* duration */}
              {type !== 'live' && <p style={{ color: 'white' }}>{elapsedTime}/{totalDuration}</p>}
            </Grid>
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            {/* fullscreen */}
            <IconButton onClick={onToggleFullScreen} className='bottomIcons'>
              <Icons path={iconFullScreen} />
            </IconButton>

            {/* logo mega go */}
            <Icons path={megaGo} className='playerIcon' />
          </Grid>
        </Grid>
      </div>
      <div className={controlsVisibility ? 'playerWatherMark hide' : 'playerWatherMark '}>
        <div className='markWrap'>
          <Icons path={megaGo} className='markIcon' />
        </div>
      </div>
    </>
  )
}
