import React from 'react'
import { Button, Grid } from '@material-ui/core'

import { Icons } from '../../components/Icons'
import iconArrowBack from '../../assets/icons/arrowBack.svg'

export default function PlayerAccessAlertContainer({ handleOnClose, error: { title, body, code }, showRetryButton }) {
  return (
    <div className='controlsWrapper'>
      <Grid container direction='row' justifyContent='space-between' alignItems='center' className='topControls'>
        <Button
          variant='outlined'
          className='large'
          onClick={handleOnClose}
          startIcon={<Icons path={iconArrowBack} />}
        >
          Volver
        </Button>
      </Grid>
      <Grid container direction='column' alignItems='center' justifyContent='center' style={{ marginTop: '-70px' }}>
        <Grid item>
          <h2 style={{ fontSize: '2rem' }}>{title || 'Ha ocurrido un error. Por favor intentar nuevamente.'}</h2>
        </Grid>
        <br />
        {body &&
          <Grid item>
            <h4 style={{ fontSize: '1.8rem' }}>{body}</h4>
          </Grid>
        }
        {code &&
          <Grid item>
            <h4 style={{ fontSize: '1.5rem', color: '#999' }}>Error: {code}</h4>
          </Grid>
        }
        {showRetryButton &&
          <Grid item style={{ marginTop: 40 }}>
            <Button
              variant='outlined'
              className='large'
              onClick={() => window.location.reload()}
            >
              Reintentar
            </Button>
          </Grid>
        }
      </Grid>
      <Grid container direction='row' alignItems='center' justifyContent='space-between' className='bottomControls' />
    </div>
  )
}