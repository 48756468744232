import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  GET_NEWS,
  GET_NEWS_START,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  GET_NEWS_FINALLY,
} from '../../../redux/actions';

function* GetNewsAction() {
  yield put(GET_NEWS_START());

  try {
    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_NEWS}`
    );

    yield put(GET_NEWS_SUCCESS(data));
  } catch (error) {
    yield put(GET_NEWS_ERROR(error.message));
  } finally {
    yield put(GET_NEWS_FINALLY());
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_NEWS, GetNewsAction);
}