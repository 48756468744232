import React from 'react'

export const CardNews = ({ title, link, imgUrl }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <article className='articleItemBox newsArticle'>
        <div className='newsImgCol'>
          <figure className='ratioImgBox ratioPortrait'>
            {imgUrl
              ?
              <img src={imgUrl} alt='' />
              :
              <img src='https://images2.mega.cl/ott/portrait-y-thumb-live/meganoticias-live-thumb.jpg' alt='' />
            }
          </figure>
        </div>
        <header>
          <h3>{title}</h3>
        </header>
      </article>
    </a>
  )
}