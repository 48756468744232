import React from 'react'
import { Button } from '@mui/material';

import { Icons } from '../../components/Icons';
import imgLogo from '../../assets/icons/megaGo.svg';

export default function RegisterContainer({navigate, keycloak}) {
  return (
    <div className='registerScreenWrap'>
      <div>
        <Icons path={imgLogo} className='logo'/>
        <p>¡Hola! ya tienes una cuenta creada <br />con el correo <b>{keycloak.userInfo.email}</b> para ingresar a MegaGo.</p>
        <br />
        <br />
        <p>Te dejamos algunos links que te resultarán útiles</p>
        <div className="registerScreenButtonWrap">
          <Button variant='contained' onClick={() => window.open('https://sso.mega.cl/auth/realms/megamedia/login-actions/reset-credentials?client_id=megago-web', '_self')}>Recuperar contraseña</Button>
          <Button variant='contained' onClick={() => navigate('/planes')}>Contratar un plan</Button>
          <Button variant='contained' onClick={() => navigate('/inicio')}>Ir a MegaGo.cl</Button>
          <Button variant='contained' onClick={() => keycloak.register()}>Crear una nueva cuenta</Button>
        </div>
      </div>
    </div>
  )
}