import axios from 'axios'
import { isEmpty } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_FINALLY,
  SUBSCRIBE_NOTIFICATIONS,
  SUBSCRIBE_NOTIFICATIONS_START,
  SUBSCRIBE_NOTIFICATIONS_SUCCESS,
  SUBSCRIBE_NOTIFICATIONS_ERROR,
  SUBSCRIBE_NOTIFICATIONS_FINALLY,
  SET_NOTIFY_TOKEN,
} from '../../redux/actions'

function* GetNotificationsAction({ payload: { keycloak } }) {
  yield put(GET_NOTIFICATIONS_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_NOTIFY_API_URL}messages?max=10`,
      headers
    )

    yield put(GET_NOTIFICATIONS_SUCCESS(data.reverse()))
  } catch (error) {
    yield put(GET_NOTIFICATIONS_ERROR(error.message))
  } finally {
    yield put(GET_NOTIFICATIONS_FINALLY())
  }
}

function* SubscribeNotificationsAction({ payload: { keycloak, currentNotifyToken } }) {
  yield put(SUBSCRIBE_NOTIFICATIONS_START())
  let token = keycloak.token
  const sub = keycloak ? keycloak.subject : null

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data: user } = yield call(axios.get,
      `${process.env.REACT_APP_NOTIFY_API_URL}users/${sub}`,
      headers
    )

    if (isEmpty(user)) {
      const { data } = yield call(axios.post,
        `${process.env.REACT_APP_NOTIFY_API_URL}users`,
        {
          uid: sub,
          topics: ['MEGAGO'],
          max_notify: 999,
          active: 1
        },
        headers
      )

      if (!isEmpty(data)) {
        const { data: device } = yield call(axios.post,
          `${process.env.REACT_APP_NOTIFY_API_URL}users/${sub}/devices`,
          { token: currentNotifyToken },
          headers
        )

        yield put(SUBSCRIBE_NOTIFICATIONS_SUCCESS(device))
      }
    } else {
      const { data: device } = yield call(axios.post,
        `${process.env.REACT_APP_NOTIFY_API_URL}users/${sub}/devices`,
        { token: currentNotifyToken },
        headers
      )

      yield put(SUBSCRIBE_NOTIFICATIONS_SUCCESS(device))
    }
  } catch (error) {
    yield put(SUBSCRIBE_NOTIFICATIONS_ERROR(error.message))
  } finally {
    yield put(SET_NOTIFY_TOKEN(currentNotifyToken))
    yield put(SUBSCRIBE_NOTIFICATIONS_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_NOTIFICATIONS, GetNotificationsAction)
  yield takeLatest(SUBSCRIBE_NOTIFICATIONS, SubscribeNotificationsAction)
}