import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { keyBy, omit } from 'lodash';

import { SET_PROFILE, UPDATE_PROFILE, DELETE_PROFILE } from './profiles/profiles.actions';
import ProfileEditContainer from '../../containers/profile/ProfileEditContainer';
import Loading from '../Loading';

export default memo(function ProfileEditComponent() {
  const { isAuthenticated, isLoading, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth);
  const { data: profiles, avatars, currentProfile } = useSelector(state => state.profiles);
  const [oldProfile, setOldProfile] = useState({});
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profilesLentgh, setProfilesLentgh] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const profilesKeyedById = keyBy(profiles, 'id');
    setProfile(profilesKeyedById[id]);
    setOldProfile(profilesKeyedById[id] !== undefined && profilesKeyedById[id]);
  }, [id, profiles]);

  useEffect(() => {
    setProfilesLentgh(profiles.length);
  }, [profiles]);

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOnChangeProfile = (value, attr) => {
    setProfile({
      ...profile,
      [attr]: value.target.value
    });
  };

  const handleOnChangeAvatarProfile = avatar => {
    setProfile({
      ...profile,
      avatar,
      idAvatar: avatar._id
    });
  };

  const handleOnCreateProfile = newProfile => {
    dispatch(SET_PROFILE({ keycloak, newProfile: omit(newProfile, 'avatar') }))
  }

  const handleOnDeleteProfile = idProfile => {
    dispatch(DELETE_PROFILE({ keycloak, idProfile }))
  }

  const handleOnUpdateProfile = newProfile => {
    dispatch(UPDATE_PROFILE({ keycloak, newProfile: omit(newProfile, 'avatar') }))
  }

  const validateChanges = (
    !profile
    || !profile.name
    || !profile.idAvatar
    || (profile.name === oldProfile.name && profile.idAvatar === oldProfile.idAvatar)
    || profile.name.length > 10
  )

  return (
    (profile === null || isLoading)
      ? <Loading />
      : <ProfileEditContainer
        avatars={avatars}
        currentProfile={currentProfile}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleOnChangeAvatarProfile={handleOnChangeAvatarProfile}
        handleOnChangeProfile={handleOnChangeProfile}
        handleOnCreateProfile={handleOnCreateProfile}
        handleOnDeleteProfile={handleOnDeleteProfile}
        handleOnUpdateProfile={handleOnUpdateProfile}
        navigate={navigate}
        id={id}
        oldProfile={oldProfile}
        open={open}
        profile={profile}
        profilesLentgh={profilesLentgh}
        validateChanges={validateChanges}
        useImageCDN={useImageCDN}
      />
  );
});