export function DestroyAds() {
  // Destroys specific banner
  // window.googletag.destroySlots([window.googletag.slots.anchor])
  if (window.googletag && window.googletag.apiReady) {
    window.googletag.destroySlots()
  }
}

// Crea posición para anchor
export function CreateAnchor(dataPath, kv) {
  if (window.googletag && window.googletag.apiReady) {
    window.googletag = window.googletag || { cmd: [] }

    let anchorSlot
    let keyValue = kv

    window.googletag.cmd.push(() => {
      anchorSlot = window.googletag.defineOutOfPageSlot(
        `/143911651${dataPath}anchor`,
        window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
      )
      for (const key in keyValue) {
        if (keyValue.hasOwnProperty(key)) {
          anchorSlot.setTargeting(key, keyValue[key])
        }
      }
      anchorSlot.addService(window.googletag.pubads())
      window.googletag.pubads().disableInitialLoad()
      window.googletag.enableServices()
      window.googletag.display(anchorSlot)
      window.googletag.pubads().refresh([anchorSlot])
    })
  }
}

// Crea posición para patrocinado y define sus constantes

export function CreateSponsored(dataPath, setSponsored) {
  if (window.googletag && window.googletag.apiReady) {
    window.googletag = window.googletag || { cmd: [] }

    let sponsoredSlot
    if (document.getElementById('slot_skin') === null) {
      let sponsoredDiv = document.createElement('div')
      sponsoredDiv.id = 'slot_skin'
      document.body.appendChild(sponsoredDiv)
    }

    window.googletag.cmd.push(() => {
      sponsoredSlot = window.googletag
        .defineOutOfPageSlot(
          `/143911651${dataPath}skin`,
          'slot_skin'
        )
      // .setTargeting('demo', 'patrocinado_megago')
      sponsoredSlot.addService(window.googletag.pubads())
      window.googletag.pubads().disableInitialLoad()
      window.googletag.enableServices()
      window.googletag.display(sponsoredSlot)
      window.googletag.pubads().refresh([sponsoredSlot])
    });

    // Detecto cuando termina de cargar el aviso y obtengo sus variables
    window.googletag.pubads().addEventListener('slotRenderEnded',
      function (event) {
        let isEmptySlot = event.isEmpty;
        if (!isEmptySlot) {
          let iframeDocumentComp = document.querySelector('iframe[id^="google_ads_iframe"]').contentDocument
          let iframeCompDiv = iframeDocumentComp.getElementById('compContainer')
          let imgComp = iframeCompDiv.dataset.compimg
          let colorComp = iframeCompDiv.dataset.compcolor
          let urlComp = iframeCompDiv.dataset.compurl
          let idComp = iframeCompDiv.dataset.compid
          setSponsored({
            _id: idComp,
            imageUrl: imgComp,
            url: urlComp,
            bgColor: colorComp,
          })
        }
      });
  }
}
