import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { isEmpty, keyBy, sortBy } from 'lodash'

import {
  GET_EVENT,
  GET_EVENT_START,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  GET_EVENT_FINALLY,
  GET_EVENTS,
  GET_EVENTS_START,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  GET_EVENTS_FINALLY,
  MERGE_CONTINUE_WATCHING_EVENT,
  MERGE_CONTINUE_WATCHING_EVENT_START,
  MERGE_CONTINUE_WATCHING_EVENT_FINALLY,
  GET_WATCH_NOW_SUCCESS,
} from '../../../redux/actions'

function* GetEventAction({ payload: { keycloak, id } }) {
  yield put(GET_EVENT_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/events/${id}`,
      headers
    )

    yield put(GET_EVENT_SUCCESS(data))
  } catch (error) {
    yield put(GET_EVENT_ERROR(error.message))
  } finally {
    yield put(GET_EVENT_FINALLY())
  }
}

function* GetEventsAction({ payload: { keycloak, limit, page } }) {
  yield put(GET_EVENTS_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/events/${limit}/${page}`,
      headers
    )

    const nonEmptyEvents = data.records.filter(event => (event.sections?.length || 0) > 0)

    yield put(GET_EVENTS_SUCCESS(nonEmptyEvents))
  } catch (error) {
    yield put(GET_EVENTS_ERROR(error.message))
  } finally {
    yield put(GET_EVENTS_FINALLY())
  }
}

function* MergeContinueWatchingEventAction({ payload: { continueWatching, event } }) {
  yield put(MERGE_CONTINUE_WATCHING_EVENT_START())

  let newEvent = event
  let watchNow = {}
  const continueWatchingById = keyBy(continueWatching, '_id')

  var allVods = []
  const newSections = event.sections.map(section => {
    var content = []

    section.content.forEach(contents => {
      const cwSelected = continueWatchingById[contents._id]

      if (!isEmpty(cwSelected)) {
        content.push({
          ...contents,
          ...cwSelected
        })

        allVods.push({
          ...contents,
          ...cwSelected
        })
      } else {
        content.push({
          ...contents,
          duration: null,
          position: null
        })

        allVods.push({
          ...contents,
          duration: null,
          position: null
        })
      }
    })

    return {
      ...section,
      content
    }

  })

  const isNotViewedFiltered = allVods.filter(x => x.viewedLastAt)
  const latestViewed = sortBy(isNotViewedFiltered, 'viewedLastAt').reverse()

  if (isEmpty(latestViewed)) {
    watchNow = !isEmpty(allVods) ? allVods[0] : {}
  } else {
    const lastViewed = latestViewed[0]

    if (lastViewed.isViewed === true) {
      const lastVodIndex = allVods.findIndex(vod => vod._id === lastViewed._id)

      if (lastVodIndex < allVods.length - 1) {
        let aux = 1

        do {
          const nextInAllVods = allVods[lastVodIndex + aux]
          const nextVod = allVods[allVods.length - 1]._id !== nextInAllVods._id
            ? nextInAllVods
            : {}

          if (isEmpty(nextVod)) {
            watchNow = nextInAllVods
          } else if (nextVod.isViewed === true) {
            aux += 1
          } else {
            watchNow = nextVod
          }
        } while (isEmpty(watchNow))
      } else {
        watchNow = allVods[lastVodIndex]
      }
    } else {
      watchNow = lastViewed
    }
  }

  newEvent = {
    ...newEvent,
    sections: newSections
  }

  yield put(GET_WATCH_NOW_SUCCESS(watchNow))
  yield put(GET_EVENT_SUCCESS(newEvent))
  yield put(MERGE_CONTINUE_WATCHING_EVENT_FINALLY())
}


export default function* actionsWatcher() {
  yield takeLatest(GET_EVENT, GetEventAction)
  yield takeLatest(GET_EVENTS, GetEventsAction)
  yield takeLatest(MERGE_CONTINUE_WATCHING_EVENT, MergeContinueWatchingEventAction)
}