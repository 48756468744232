import React from 'react'
import { Button, TextField, FormControl, Modal, Fade, Backdrop } from '@mui/material'

import { Icons } from '../../components/Icons'
import iconArrowBack from '../../assets/icons/arrowBack.svg'
import iconPencil from '../../assets/icons/pencil.svg'
import iconClose from '../../assets/icons/close.svg'
import addAvatar from '../../assets/img/add-profile-photo.svg'
import { isEmpty } from 'lodash'

export default function ProfileEditContainer(props) {
  const {
    avatars,
    currentProfile,
    handleClose,
    handleOpen,
    handleOnChangeAvatarProfile,
    handleOnChangeProfile,
    handleOnCreateProfile,
    handleOnDeleteProfile,
    handleOnUpdateProfile,
    navigate,
    id,
    oldProfile,
    open,
    profile,
    profilesLentgh,
    validateChanges,
    useImageCDN
  } = props

  return (
    <main className='appWrap'>
      <Button variant='contained' className='medium goBack' onClick={() => navigate('/cuenta/seleccionar')} startIcon={<Icons path={iconArrowBack} />}>Volver</Button>
      {
        (profile && profile.id && (profilesLentgh > 1 && profile.id !== currentProfile.id)) &&
        <Button
          className='deleteProfile medium'
          variant='outlined'
          onClick={() => {
            handleOnDeleteProfile(profile.id)
            navigate('/cuenta/seleccionar')
          }}
        >
          Eliminar perfil
        </Button>
      }
      <div className='stagedProfilesWrap'>
        <div className='stagedProfileEdit'>
          <header className="mediumHeader centered">
            <h3>Editar perfil</h3>
          </header>
          <div className='userAvatar rowM' onClick={handleOpen}>
            <figure className='ratioImgBox ratioSquare'>
              <img
                src={profile && profile.avatar
                  ? !isEmpty(profile.avatar.imageUrl)
                    ? useImageCDN
                      ? profile.avatar.imageUrl.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : profile.avatar.imageUrl
                    : 'https://api-megago.megamedia.cl/files/avatar1.png'
                  : addAvatar
                }
                alt='avatar'
              />
            </figure>
            <button className='circleEdit'><Icons path={iconPencil} /></button>
          </div>
          <FormControl className='inputBase'>
            <label htmlFor='name' shrink='true'>Nombre</label>
            <TextField
              id='name'
              variant='filled'
              value={profile ? profile.name : ''}
              onChange={value => handleOnChangeProfile(value, 'name')}
            />
            <Button
              disabled={validateChanges}
              variant='contained'
              shrink='true'
              style={{ opacity: validateChanges ? 0.5 : 1 }}
              onClick={() => {
                if (profile && profile.id) {
                  handleOnUpdateProfile(profile)
                  navigate('/cuenta/seleccionar')
                } else {
                  handleOnCreateProfile({
                    id,
                    ...profile,
                  })
                  navigate('/cuenta/seleccionar')
                }
              }}
            >
              {(profile && profile.id) ? 'Guardar cambios' : 'Crear perfil'}
            </Button>
          </FormControl>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          oldProfile && handleOnChangeAvatarProfile(oldProfile.avatar)
          handleClose()
        }}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        closeAfterTransition
        className='avatarModal app'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className='modalWrap'>
            <button
              className='closeModal i'
              onClick={() => {
                oldProfile && handleOnChangeAvatarProfile(oldProfile.avatar)
                handleClose()
              }}
            >
              <Icons path={iconClose} />
            </button>
            <div className='modalBox'>
              <section>
                <header className='mediumHeader'>
                  <h3>Selecciona una imagen de perfil</h3>
                </header>
                <ul className='grid grid-5-cols avatarList'>
                  {avatars.map(avatar => (
                    <li key={avatar._id}>
                      <button
                        onClick={() => {
                          handleOnChangeAvatarProfile(avatar)
                          !oldProfile && handleClose()
                        }}
                        style={{ width: '100%', padding: 0 }}
                      >
                        <div className={profile && profile.idAvatar === avatar._id ? 'userAvatar current' : 'userAvatar'}>
                          <figure className='ratioImgBox ratioSquare'>
                            <img
                              src={useImageCDN
                                ? avatar.imageUrl.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                                : avatar.imageUrl
                              }
                              alt={avatar.name}
                            />
                          </figure>
                          {
                            profile && profile.idAvatar === avatar._id &&
                            <span className='circleEdit'>
                              <Icons path={iconPencil} />
                            </span>
                          }
                        </div>
                      </button>
                    </li>
                  ))
                  }
                </ul>
                {oldProfile &&
                  <div className='chooseButtons'>
                    <Button
                      className='reject'
                      variant='contained'
                      shrink='true'
                      onClick={() => {
                        handleOnChangeAvatarProfile(oldProfile.avatar)
                        handleClose()
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className='accept'
                      variant='contained'
                      shrink='true'
                      onClick={() => {
                        handleOnChangeAvatarProfile(profile.avatar)
                        handleClose()
                      }}
                    >
                      Cambiar imagen
                    </Button>
                  </div>
                }
              </section>
            </div>
          </div>
        </Fade>
      </Modal>
    </main>
  )
}