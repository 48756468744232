import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { intersection, isEmpty, keyBy } from 'lodash'

import {
  GET_SERIE,
  GET_CONTINUE_WATCHING,
  MERGE_CONTINUE_WATCHING_SERIE,
  GET_PROFILE_PREFERENCES,
  SET_PROFILE_PREFERENCES,
  GET_NOTIFICATIONS,
  GET_WATCH_NOW_SUCCESS,
} from '../../redux/actions'

import SerieContainer from '../../containers/serie/SerieContainer'
import Loading from '../Loading'
import { withError } from '../error/ErrorComponent'
import { CreateAnchor, DestroyAds, CreateSponsored } from '../Ads'

export default withError(memo(function SerieComponent() {
  const { _id } = useParams()
  const { isAuthenticated, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const {
    continueWatching,
    watchNow,
    isLoading: isLoadingContinueWatching,
    isMerging: isMergingContinueWatching
  } = useSelector(state => state.continueWatching)
  const { currentProfile, preferences, isLoadingPreferences } = useSelector(state => state.profiles)
  const { serie, isLoading } = useSelector(state => state.series)
  const { isLoading: isLoadingSubscription, subscriptionsPlanIds, isFreePlan } = useSelector(state => state.subscription)
  const { data: notifications } = useSelector(state => state.notifications)

  const [seriePreferences, setSeriePreferences] = useState({})
  const [currentSeason, setCurrentSeason] = useState(0)
  const [isUnlocked, setIsUnlocked] = useState(false)
  const [serieInverted, setSerieInverted] = useState(null)
  const [sponsored, setSponsored] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    (_id !== serie._id || isEmpty(serie)) && dispatch(GET_SERIE({
      keycloak,
      id: _id
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id])

  useEffect(() => {
    if (continueWatching.length === 0) {
      dispatch(GET_CONTINUE_WATCHING({
        keycloak, idCurrentProfile: currentProfile.id, idSerie: _id
      }))
    } else if (continueWatching[0].idSerie !== _id) {
      dispatch(GET_CONTINUE_WATCHING({
        keycloak, idCurrentProfile: currentProfile.id, idSerie: _id
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueWatching.length])

  useEffect(() => {
    if (!isEmpty(serie) && serie._id === _id) {
      if (!isEmpty(continueWatching)) {
        const continueWatchingFilteredBySerie = continueWatching.filter(vod => vod.idSerie === serie._id)
        if (!isEmpty(continueWatchingFilteredBySerie)) {
          dispatch(MERGE_CONTINUE_WATCHING_SERIE(
            {
              continueWatching: continueWatchingFilteredBySerie,
              serie
            }
          ))
        } else {
          dispatch(GET_WATCH_NOW_SUCCESS(serie.seasons?.[0]?.vods?.[0] || {}))
        }
      } else {
        dispatch(GET_WATCH_NOW_SUCCESS(serie.seasons?.[0]?.vods?.[0] || {}))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serie._id, continueWatching.length])

  useEffect(() => {
    preferences && serie._id !== preferences.id && dispatch(GET_PROFILE_PREFERENCES({
      keycloak, idSerie: serie._id, idCurrentProfile: currentProfile.id
    }))

    if (!isEmpty(serie)) {
      document.title = 'Mega GO | ' + serie.title

      const seasons = serie.seasons?.map(season => {
        const vods = [...season.vods]
        vods.reverse()
        return {
          ...season,
          vods
        }
      }) || []

      setSerieInverted({
        ...serie,
        seasons
      })
    }

    return () => {
      document.title = 'Mega GO'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serie])

  useEffect(() => {
    if (!isEmpty(preferences)) {
      const keyedByIdSeriePreferences = keyBy(preferences, 'id')
      setSeriePreferences(keyedByIdSeriePreferences[_id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences, _id])

  useEffect(() => {
    notifications.length === 0 && dispatch(GET_NOTIFICATIONS({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !isEmpty(watchNow) && setCurrentSeason(watchNow.season?.order - 1 || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchNow])

  useEffect(() => {
    if (!isEmpty(subscriptionsPlanIds) && !isEmpty(serie.planIds)) {
      const plansIntersection = intersection(subscriptionsPlanIds, serie.planIds)
      if (!isEmpty(plansIntersection)) {
        setIsUnlocked(true)
      } else {
        setIsUnlocked(false)
      }
    } else {
      setIsUnlocked(false)
    }
  }, [subscriptionsPlanIds, serie])

  // Crea y destruye publicidades
  useEffect(() => {
    if (isFreePlan && !isEmpty(serie)) {
      CreateAnchor('/ott/series/home/', {
        'seccion': 'programa',
        'nivel': 'home',
        'nota': '',
        'id_nota': '',
        'tipo': 'serie',
        'keywords': serie.title
      })
      CreateSponsored('/ott/home/home/', setSponsored)
      return () => {
        DestroyAds()
      }
    }
  }, [isFreePlan, serie])

  const handleOnChangePreferences = (newPreference) => {
    const newPreferences = isEmpty(seriePreferences)
      ? {
        id: serie._id,
        favorite: false,
        thumb: '',
        isOrderInverted: false,
        ...newPreference
      } : {
        ...seriePreferences,
        ...newPreference
      }

    dispatch(SET_PROFILE_PREFERENCES({
      keycloak, idSerie: serie._id, idCurrentProfile: currentProfile.id, newPreferences
    }))
  }

  return (
    (isLoading
      || isLoadingContinueWatching
      || isLoadingPreferences
      || isMergingContinueWatching
      || isLoadingSubscription
      || isEmpty(serieInverted))
      ?
      <Loading />
      :
      <SerieContainer
        serie={(seriePreferences?.isOrderInverted || false) ? serieInverted : serie}
        watchNow={watchNow}
        seriePreferences={seriePreferences}
        currentSeason={currentSeason}
        setCurrentSeason={setCurrentSeason}
        handleOnChangePreferences={handleOnChangePreferences}
        isUnlocked={isUnlocked}
        useImageCDN={useImageCDN}
        sponsored={sponsored}
      />
  )
}))