import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icons } from '../../components/Icons'
import imgLogo from '../../assets/icons/megaGo.svg'
import badgeIos from '../../assets/img/app-store-badge.png'
import badgeAndroid from '../../assets/img/google-play-badge.png'

export default function DownloadAppContainer({ isIOS, isFreePlan }) {
  const navigate = useNavigate()

  useEffect(() => {
    isFreePlan && navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreePlan])

  return (
    <div className='onboardingContainer'>
      <section className='obSection screenOne box'>
        <Icons path={imgLogo} className='megaGoLogo' />
        <h1 style={{ fontSize: 20, fontWeight: 800 }}>¡Todo listo!</h1>
        <p style={{ fontSize: 20, lineHeight: '1.5rem' }}>Descarga la app y disfruta de nuestros contenidos</p>
        <span className='subText'>Descarga la app para {isIOS ? 'iOS' : 'Android'}</span>
        {
          isIOS
            ? (
              <a href='https://apps.apple.com/cl/app/mega-go/id1571743848'>
                <img
                  alt='Descárgalo en el App Store'
                  src={badgeIos}
                  className='badge'
                />
              </a>
            ) : (
              <a href='https://play.google.com/store/apps/details?id=cl.megamedia.megago&ah=1BBIBAysiNcshGHuokWDJq1-bXk'>
                <img
                  alt='Disponible en Google Play'
                  src={badgeAndroid}
                  className='badge'
                />
              </a>
            )
        }
      </section>
    </div>
  )
}
