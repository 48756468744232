import { createAction } from 'redux-actions'

export const GET_NOTIFICATIONS = createAction('GET_NOTIFICATIONS')
export const GET_NOTIFICATIONS_START = createAction('GET_NOTIFICATIONS_START')
export const GET_NOTIFICATIONS_SUCCESS = createAction('GET_NOTIFICATIONS_SUCCESS')
export const GET_NOTIFICATIONS_ERROR = createAction('GET_NOTIFICATIONS_ERROR')
export const GET_NOTIFICATIONS_FINALLY = createAction('GET_NOTIFICATIONS_FINALLY')
export const SET_NOTIFICATION_RECEIVED = createAction('SET_NOTIFICATION_RECEIVED')
export const CHECK_ALL_NOTIFICATIONS = createAction('CHECK_ALL_NOTIFICATIONS')
export const SUBSCRIBE_NOTIFICATIONS = createAction('SUBSCRIBE_NOTIFICATIONS')
export const SUBSCRIBE_NOTIFICATIONS_START = createAction('SUBSCRIBE_NOTIFICATIONS_START')
export const SUBSCRIBE_NOTIFICATIONS_SUCCESS = createAction('SUBSCRIBE_NOTIFICATIONS_SUCCESS')
export const SUBSCRIBE_NOTIFICATIONS_ERROR = createAction('SUBSCRIBE_NOTIFICATIONS_ERROR')
export const SUBSCRIBE_NOTIFICATIONS_FINALLY = createAction('SUBSCRIBE_NOTIFICATIONS_FINALLY')
export const SET_NOTIFY_TOKEN = createAction('SET_NOTIFY_TOKEN')