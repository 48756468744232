import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { isArray, isEmpty } from 'lodash'

import {
  GET_LISTS,
  GET_LISTS_START,
  GET_LISTS_SUCCESS,
  GET_LISTS_ERROR,
  GET_LISTS_FINALLY,
  MERGE_CONTINUE_WATCHING_LISTS,
} from '../../../redux/actions'

function* GetListAction({ payload: { keycloak } }) {
  yield put(GET_LISTS_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/lists?populate=false`,
      headers
    )

    yield put(GET_LISTS_SUCCESS(data))
  } catch (error) {
    yield put(GET_LISTS_ERROR(error.message))
  } finally {
    yield put(GET_LISTS_FINALLY())
  }
}

function* MergeContinueWatchingListsAction({ payload: { continueWatching, lists } }) {
  if (isArray(lists) && !isEmpty(lists)) {
    if (continueWatching.length > 0) {
      let newLists = []
      lists.map(list => {
        if (list.title === 'Continuar viendo' && list.type === 'Vod') {
          let mergedList = mergeVodsData(list, continueWatching, continueWatching)

          return newLists.push(mergedList)
        } else if (list.type === 'Vod') {
          let mergedList = mergeVodsData(list, list.content, continueWatching)

          return newLists.push(mergedList)
        } else {
          return newLists.push(list)
        }
      })
      yield put(GET_LISTS_SUCCESS(newLists))
    } else {
      let newLists = []
      lists.map(list => {
        if (list.title === 'Continuar viendo' && list.type === 'Vod') {
          return newLists.push({ ...list, content: [] })
        } else {
          return newLists.push(list)
        }
      })
      yield put(GET_LISTS_SUCCESS(newLists))
    }
  } else {
    yield put(GET_LISTS_SUCCESS([]))
  }
}

function mergeVodsData(list, contents, vods) {
  var li = list
  let newContent = contents.map(content => {
    const vodFounded = vods.find(x => x._id === content._id)
    const vod = !isEmpty(vodFounded)
      ? vodFounded
      : {
        ...content,
        duration: null,
        position: null
      }

    var aux = {
      ...content,
      ...vod
    }

    return aux
  })

  li.content = newContent

  return li
}

export default function* actionsWatcher() {
  yield takeLatest(GET_LISTS, GetListAction)
  yield takeLatest(MERGE_CONTINUE_WATCHING_LISTS, MergeContinueWatchingListsAction)
}