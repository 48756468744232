import React, { useRef, useEffect, useState } from 'react'
import { Button } from '@mui/material'

import { Icons } from '../Icons'
import iconNextVideo from '../../assets/icons/playerNext.svg'

export default function PlayerNextEpisode(props) {
  const {
    handleContinueWatching,
    navigate,
    nextVod,
    setNextEpisodeVisibility
  } = props

  const [time, setTime] = useState(props.initialValue || 10)

  const timerRef = useRef(time)

  useEffect(() => {
    const timerId = setInterval(() => {
      timerRef.current -= 1
      if (timerRef.current < 0) {
        clearInterval(timerId)
        handleContinueWatching()
        setNextEpisodeVisibility(false)
        navigate(`/player/vod/${nextVod._id}`, { replace: true })
      } else {
        setTime(timerRef.current)
      }
    }, 1000)
    return () => {
      clearInterval(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="nextEpisodeFullScreen">
      <div className="interaction">
        <p>Comienza en {time} segundos</p>
        <div className="buttons">
          <Button
            variant='outlined'
            className='large'
            onClick={() => setNextEpisodeVisibility(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='outlined'
            className='large'
            endIcon={<Icons path={iconNextVideo} />}
            onClick={() => {
              handleContinueWatching()
              setNextEpisodeVisibility(false)
              navigate(`/player/vod/${nextVod._id}`, { replace: true })
            }}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  )
}