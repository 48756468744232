import { handleActions } from 'redux-actions'

import {
    GET_EVENT_START,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,
    GET_EVENT_FINALLY,
    GET_EVENTS_START,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_ERROR,
    GET_EVENTS_FINALLY,
} from '../../../redux/actions'

const initialState = {
    data: [],
    event: {},
    error: null,
    isLoading: false,
}

export default handleActions({
    [GET_EVENT_START]: state => ({
        ...state,
        isLoading: true,
        error: null
    }),
    [GET_EVENT_SUCCESS]: (state, action) => ({
        ...state,
        event: action.payload,
    }),
    [GET_EVENT_ERROR]: (state, action) => ({
        ...state,
        error: action.payload,
    }),
    [GET_EVENT_FINALLY]: state => ({
        ...state,
        isLoading: false
    }),
    [GET_EVENTS_START]: state => ({
        ...state,
        isLoading: true,
        error: null
    }),
    [GET_EVENTS_SUCCESS]: (state, action) => ({
        ...state,
        data: action.payload,
    }),
    [GET_EVENTS_ERROR]: (state, action) => ({
        ...state,
        error: action.payload,
    }),
    [GET_EVENTS_FINALLY]: state => ({
        ...state,
        isLoading: false
    }),
}, initialState)