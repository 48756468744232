import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { intersection, isEmpty, keyBy } from 'lodash'

import {
  GET_EVENT,
  GET_CONTINUE_WATCHING,
  MERGE_CONTINUE_WATCHING_EVENT,
  GET_PROFILE_PREFERENCES,
  SET_PROFILE_PREFERENCES,
  GET_WATCH_NOW_SUCCESS,
} from '../../redux/actions'

import EventContainer from '../../containers/event/EventContainer'
import Loading from '../Loading'
import { withError } from '../error/ErrorComponent'

export default withError(memo(function EventComponent({ offset }) {
  const { _id } = useParams()
  const { isAuthenticated, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const {
    continueWatching,
    isLoading: isLoadingContinueWatching,
    isMerging: isMergingContinueWatching
  } = useSelector(state => state.continueWatching)
  const { currentProfile, preferences, isLoadingPreferences } = useSelector(state => state.profiles)
  const { event, isLoading } = useSelector(state => state.events)
  const { isLoading: isLoadingSubscription, subscriptionsPlanIds } = useSelector(state => state.subscription)

  const [eventPreferences, setEventPreferences] = useState({})
  const [isUnlocked, setIsUnlocked] = useState(false)
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    (_id !== event._id || isEmpty(event)) && dispatch(GET_EVENT({
      keycloak,
      id: _id
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id])

  useEffect(() => {
    if (continueWatching.length === 0) {
      dispatch(GET_CONTINUE_WATCHING({
        keycloak, idCurrentProfile: currentProfile.id, idSerie: _id
      }))
    } else if (continueWatching[0].idSerie !== _id) {
      dispatch(GET_CONTINUE_WATCHING({
        keycloak, idCurrentProfile: currentProfile.id, idSerie: _id
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueWatching.length])

  useEffect(() => {
    if (!isEmpty(event) && event._id === _id) {
      const continueWatchingFilteredBySerie = continueWatching.filter(vod => vod.idEvent === event._id)

      if (!isEmpty(continueWatchingFilteredBySerie)) {
        dispatch(MERGE_CONTINUE_WATCHING_EVENT(
          {
            continueWatching: continueWatchingFilteredBySerie,
            event
          }
        ))
      } else {
        dispatch(GET_WATCH_NOW_SUCCESS(event.sections[0]?.content[0] || {}))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event._id, continueWatching.length])

  useEffect(() => {
    preferences && event._id !== preferences.id && dispatch(GET_PROFILE_PREFERENCES({
      keycloak, idSerie: event._id, idCurrentProfile: currentProfile.id
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  useEffect(() => {
    if (!isEmpty(preferences)) {
      const keyedByIdSeriePreferences = keyBy(preferences, 'id')
      setEventPreferences(keyedByIdSeriePreferences[_id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences, _id])

  useEffect(() => {
    if (!isEmpty(subscriptionsPlanIds) && !isEmpty(event.planIds)) {
      const plansIntersection = intersection(subscriptionsPlanIds, event.planIds)
      if (!isEmpty(plansIntersection)) {
        setIsUnlocked(true)
      } else {
        setIsUnlocked(false)
      }
    } else {
      setIsUnlocked(false)
    }
  }, [subscriptionsPlanIds, event])

  const handleOnChangePreferences = (newPreference) => {
    const newPreferences = isEmpty(eventPreferences)
      ? {
        id: event._id,
        favorite: false,
        thumb: "",
        ...newPreference
      } : {
        ...eventPreferences,
        ...newPreference
      }

    dispatch(SET_PROFILE_PREFERENCES({
      keycloak, idSerie: event._id, idCurrentProfile: currentProfile.id, newPreferences
    }))
  }

  // Función para cambiar tabs
  const handleChange = (e, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    (isLoading
      || isLoadingContinueWatching
      || isLoadingPreferences
      || isMergingContinueWatching
      || isLoadingSubscription
      || isEmpty(event))
      ?
      <Loading />
      :
      <EventContainer
        event={event}
        eventPreferences={eventPreferences}
        handleOnChangePreferences={handleOnChangePreferences}
        isUnlocked={isUnlocked}
        currentTab={currentTab}
        handleChange={handleChange}
        useImageCDN={useImageCDN}
      />
  )
}))