import React from 'react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import { useScrollPosition } from '../../hooks/useScrollPosition'

export default function SerieHeroContainer({ vodTrailer, imagesSite, useImageCDN, sponsored, serieId }) {
  const scrollPosition = useScrollPosition()

  // Función para dejar fixed header y cambiar opacidad del hero
  function normalizeBetweenTwoRanges(val, minVal, maxVal, newMin, newMax) {
    return newMin + (val - minVal) * (newMax - newMin) / (maxVal - minVal)
  }

  const newOpacity = normalizeBetweenTwoRanges(scrollPosition, 0, 200, 1, 0)

  return (
    <div
      className={classNames('heroContentWrap', { 'sponsoredItem sponsoredShowItem': serieId === sponsored._id })}
      style={serieId === sponsored._id ? { backgroundColor: sponsored.bgColor, opacity: newOpacity } : { opacity: newOpacity }}
    >
      <div className='heroImgBox'>
        <span className='shadowLeft'></span>
        <span className='shadowBottom'></span>
        <figure className='ratioImgBox ratioHero'>
          <img
            src={!isEmpty(imagesSite?.hero)
              ? useImageCDN
                ? imagesSite.hero.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                : imagesSite.hero
              : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-hero.jpg'
            }
            alt=''
          />
          {
            !isEmpty(vodTrailer) &&
            <video id={`video_serieHero_${vodTrailer.idMedia}`} controls={false} autoPlay preload='auto' loop='loop' muted>
              <source src={`https://mdstrm.com/video/${vodTrailer.idMedia}.mp4`} type='video/mp4' />
            </video>
          }
        </figure>
      </div>
      {serieId === sponsored._id && <span class="shadowBottom"></span>}
    </div>
  )
}