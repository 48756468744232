import { isEmpty, keyBy, sortBy } from 'lodash'

export const watchNow = (navigate, continueWatching, serie) => {
  const continueWatchingById = keyBy(continueWatching, '_id')

  var allVods = []
  serie.seasons.forEach(season => {
    var vods = []

    season.vods.forEach(vod => {
      const cwSelected = continueWatchingById[vod._id]

      if (!isEmpty(cwSelected)) {
        vods.push({
          ...vod,
          ...cwSelected
        })

        allVods.push({
          ...vod,
          ...cwSelected
        })
      } else {
        vods.push({
          ...vod,
          duration: null,
          position: null
        })

        allVods.push({
          ...vod,
          duration: null,
          position: null
        })
      }
    })
  })

  const isNotViewedFiltered = allVods.filter(x => x.viewedLastAt)
  const latestViewed = sortBy(isNotViewedFiltered, 'viewedLastAt').reverse()

  if (isEmpty(latestViewed)) {
    !isEmpty(allVods)
      ? navigate(`/player/vod/${allVods[0]._id}`)
      : navigate(`/serie/${serie._id}`)
  } else {
    const lastViewed = latestViewed[0]

    if (lastViewed.isViewed === true) {
      const lastVodIndex = allVods.findIndex(vod => vod._id === lastViewed._id)

      if (lastVodIndex < allVods.length - 1) {
        let aux = 1
        let searching = true

        do {
          const nextInAllVods = allVods[lastVodIndex + aux]
          const nextVod = allVods[allVods.length - 1]._id !== nextInAllVods._id
            ? nextInAllVods
            : {}

          if (isEmpty(nextVod)) {
            searching = false
            navigate(`/serie/${serie._id}`)
          } else if (nextVod.isViewed === true) {
            aux += 1
          } else {
            searching = false
            navigate(`/player/vod/${nextVod._id}`)
          }
        } while (searching)
      } else {
        navigate(`/serie/${serie._id}`)
      }
    } else {
      navigate(`/player/vod/${lastViewed._id}`)
    }
  }
}