import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function ScrollToTop({ navigate, setCurrentLocation, setOffset }) {
  // Guarda la url actual
  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [location, setCurrentLocation]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (null)
}

export default withRouter(ScrollToTop)