import React, { useState } from 'react'
import { Button, IconButton, Fade } from '@mui/material'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Icons } from './Icons'
import iconPlayOutline from '../assets/icons/playOutline.svg'
import iconStar from '../assets/icons/star.svg'
import iconStarOutline from '../assets/icons/starOutline.svg'
import iconThumbUp from '../assets/icons/thumbUp.svg'
import iconThumbUpOutline from '../assets/icons/thumbUpOutline.svg'
import iconThumbDown from '../assets/icons/thumbDown.svg'
import iconThumbDownOutline from '../assets/icons/thumbDownOutline.svg'

export const CardPortrait = (props) => {
  const {
    serie,
    handleOnChangePreferences,
    handleOnClickWatchNow,
    link,
    preference,
    useImageCDN,
  } = props

  const [isVideoShown, setIsVideoShown] = useState(false)

  const { _id, vodPreview, imagesSite, title } = serie

  let timeoutId = null

  const handleMouseHover = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => { setIsVideoShown(true) }, 1000)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutId)
    setIsVideoShown(false)
  }

  return (
    <article
      className='articleItemBox withContextSerie'
      onMouseEnter={() => handleMouseHover()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <Link to={link}>
        <figure className='ratioImgBox ratioPortrait'>
          <img
            src={!isEmpty(imagesSite.poster)
              ? useImageCDN
                ? imagesSite.poster.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                : imagesSite.poster
              : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-portrait.jpg'
            }
            alt=''
          />
          {!isEmpty(vodPreview) && isVideoShown && (
            <Fade in={isVideoShown} timeout={600} style={{ transitionDelay: '500ms' }} >
              <video id={`video_cardPortrait_${vodPreview.idMedia}`} autoPlay preload='auto' loop='loop' muted>
                <source src={`https://mdstrm.com/video/${vodPreview.idMedia}.mp4`} type='video/mp4' />
              </video>
            </Fade>
          )}
        </figure>
      </Link>
      <header>
        <Link to={link}>
          <h3>{title}</h3>
        </Link>
        <div className='buttonGroup'>
          <Button
            onClick={() => handleOnClickWatchNow(serie)}
            variant='outlined'
            startIcon={<Icons path={iconPlayOutline} />}
          >
            Ver
          </Button>
          <IconButton
            aria-label='favorito'
            color='primary'
            onClick={() => handleOnChangePreferences({ id: _id, favorite: preference && preference.favorite ? !preference.favorite : true })}
          >
            <Icons path={preference && preference.favorite ? iconStar : iconStarOutline} />
          </IconButton>
          <IconButton
            aria-label='me gusta'
            color='primary'
            onClick={() => handleOnChangePreferences({ id: _id, thumb: preference && preference.thumb === 'UP' ? '' : 'UP' })}
          >
            <Icons path={preference && preference.thumb === 'UP' ? iconThumbUp : iconThumbUpOutline} />
          </IconButton>
          <IconButton
            aria-label='no me gusta'
            color='primary'
            onClick={() => handleOnChangePreferences({ id: _id, thumb: preference && preference.thumb === 'DOWN' ? '' : 'DOWN' })}
          >
            <Icons path={preference && preference.thumb === 'DOWN' ? iconThumbDown : iconThumbDownOutline} />
          </IconButton>
        </div>
      </header>
    </article>
  )
}