import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import backgroundImage from '../assets/img/bg-web.jpg'; 
import CircularProgress from '@mui/joy/CircularProgress';
import { Icons } from '../components/Icons'
import megaGo from '../assets/icons/megaGo.svg'

const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1112,
};

function Error429() {
  const [count, setCount] = useState(null);

  useEffect(() => {
    const retryAfterValue = parseInt(window.localStorage.getItem('retryAfter') || 10, 10);

    if (!isNaN(retryAfterValue)) {
      setCount(retryAfterValue);
    }
  }, []);

  useEffect(() => {
    let timer;

    if (count !== null) {
      timer = setInterval(() => {
        setCount(prevCount => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <div style={backgroundStyle}>
      <Box
        className="error429-box"
      >
        <Icons path={megaGo} />
        <div>
          <CircularProgress />
          <div>
            <h1>Estamos procesando tu solicitud</h1>
            {count !== null && (
              <p>Por favor no refresques tu navegador, espera {count} segundos</p>
            )}
          </div>
        </div>
        
      </Box>
    </div>
  );
}

export default Error429;
