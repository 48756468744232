import { handleActions } from 'redux-actions';

import {
    GET_NEWS_START,
    GET_NEWS_SUCCESS,
    GET_NEWS_ERROR,
    GET_NEWS_FINALLY,
} from '../../../redux/actions';

const initialState = {
    data: [],
    error: null,
    isLoading: true,
};

export default handleActions({
    [GET_NEWS_START]: state => ({
        ...state,
        isLoading: true,
        error: false
    }),
    [GET_NEWS_SUCCESS]: (state, action) => ({
        ...state,
        data: action.payload,
    }),
    [GET_NEWS_ERROR]: (state, action) => ({
        ...state,
        error: action.payload,
    }),
    [GET_NEWS_FINALLY]: state => ({
        ...state,
        isLoading: false
    }),
}, initialState);