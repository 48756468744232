import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import { isEmpty } from 'lodash'

import {
  GET_CONTINUE_WATCHING_SUCCESS,
  GET_PROFILE_PREFERENCES_SUCCESS,
  GET_SERIE_SUCCESS,
  SET_CURRENT_PROFILE
} from '../../redux/actions'
import { GET_PLAN } from '../plans/plans/plans.actions'
import { CANCEL_SUBSCRIPTION } from '../subscription/subscription.actions'

import ProfileContainer from '../../containers/profile/ProfileContainer'
import Loading from '../Loading'

export default memo(function ProfileComponent({ setOpenModal }) {
  const { isAuthenticated, keycloak, firebaseConfigs: { useImageCDN } } = useSelector(state => state.auth)
  const { planById: plan, isLoadingById } = useSelector(state => state.plans)
  const { data: subscription, isLoading, isLoadingCancel, subscriber } = useSelector(state => state.subscription)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [params] = useSearchParams()
  const cp = params.get('cp')

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    !isEmpty(keycloak) && !isEmpty(subscription) && isEmpty(plan)
      && dispatch(GET_PLAN({ keycloak, id: subscription.plan_id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, subscription, plan])

  const handleOnCancelSubscription = () => {
    let caducado = subscription.subscription_end &&
      subscription.subscription_end >= new Date()
    if (subscription.paid && !caducado) {
      dispatch(CANCEL_SUBSCRIPTION({
        keycloak,
        subscription_id: subscription.id,
        subscriptor_id: subscription.subscriber_id,
        url_callback: window.location.origin
      }))
    } else {
      navigate('/planes')
    }
  }

  const logout = () => {
    const auth = getAuth()

    localStorage.clear()
    navigate('/')
    keycloak.logout()
    signOut(auth)
  }

  const handleOnSelectProfile = profile => {
    const localProfile = { currentProfileId: profile.id, timestamp: new Date().getTime() }
    localStorage.setItem('currentProfile', JSON.stringify(localProfile))
    dispatch(SET_CURRENT_PROFILE(profile))
    dispatch(GET_PROFILE_PREFERENCES_SUCCESS([]))
    dispatch(GET_CONTINUE_WATCHING_SUCCESS([]))
    dispatch(GET_SERIE_SUCCESS({}))
  }

  useEffect(() => {
    if (cp) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = 'Mega GO | Cuenta'
    return () => {
      document.title = 'Mega GO'
    }
  }, [])

  return (
    isLoading || (isLoadingById && !isEmpty(subscription)) || isLoadingCancel
      ? <Loading />
      :
      <ProfileContainer
        handleOnCancelSubscription={handleOnCancelSubscription}
        handleOnSelectProfile={handleOnSelectProfile}
        navigate={navigate}
        logout={logout}
        plan={plan}
        subscriber={subscriber}
        status={subscription?.status || ''}
        userData={keycloak.userInfo}
        useImageCDN={useImageCDN}
      />
  )
})