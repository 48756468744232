import { legacy_createStore as createStore, combineReducers, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import AuthReducer from '../components/auth/auth.reducer'
import ListsReducer from '../components/home/lists/lists.reducer'
import NewsReducer from '../components/home/news/news.reducer'
import ContinueWatchingReducer from '../components/home/continueWatching/continueWatching.reducer'
import SerieReducer from '../components/serie/serie/serie.reducer'
import EventReducer from '../components/event/event/event.reducer'
import MediaReducer from '../components/player/media/media.reducer'
import ProfilesReducer from '../components/profiles/profiles/profiles.reducer'
import CategoriesReducer from '../components/categories/categories/categories.reducer'
import SubscriptionReducer from '../components/subscription/subscription.reducer'
import NotificationsReducer from '../components/notifications/notifications.reducer'
import plansReducer from '../components/plans/plans/plans.reducer'

import rootSaga from './sagas'

const rootReducer = combineReducers({
  auth: AuthReducer,
  lists: ListsReducer,
  news: NewsReducer,
  continueWatching: ContinueWatchingReducer,
  series: SerieReducer,
  events: EventReducer,
  media: MediaReducer,
  profiles: ProfilesReducer,
  categories: CategoriesReducer,
  subscription: SubscriptionReducer,
  notifications: NotificationsReducer,
  plans: plansReducer,
})

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function generateStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(sagaMiddleware)
    )
  )

  sagaMiddleware.run(rootSaga)

  return store
}