import React from 'react'

import Loading from '../../components/Loading'

export default function ProfileContainer({ message }) {

  return (
    <main className='appWrap registerScreenWrap'>
      <Loading message={message} />
    </main>
  )
}