import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import Button from '@mui/material/Button'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ChevronLeft from '@mui/icons-material/ChevronLeft'

import { Icons } from '../../components/Icons'
import iconPlay from '../../assets/icons/play.svg'
import iconInfo from '../../assets/icons/info.svg'
import { useScrollPosition } from '../../hooks/useScrollPosition'

export default function HomeHero(props) {
  const {
    row,
    handleOnClickWatchNow,
    navigate,
    useImageCDN,
    sponsored
  } = props

  const scrollPosition = useScrollPosition()

  // Función para dejar fixed header y cambiar opacidad del hero
  const normalizeBetweenTwoRanges = (val, minVal, maxVal, newMin, newMax) => {
    return newMin + (val - minVal) * (newMax - newMin) / (maxVal - minVal)
  }

  const newOpacity = normalizeBetweenTwoRanges(scrollPosition, 0, 200, 1, 0)

  const SliderArrow = (props) => {
    const { className, onClick, IconPath } = props
    return (
      <div className={className + ' home-hero-arrow'} onClick={onClick}>
        <div className='icon-border'><IconPath /></div>
      </div>
    )
  }

  const settings = {
    initialSlide: 0,
    fade: true,
    dots: row?.content?.length > 1,
    infinite: row?.content?.length > 1,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    waitForAnimate: true,
    lazyLoad: 'ondemand',
    appendDots: (dots) => (
      <ul className='dotsList'>
        {
          dots.map(dot => {
            const { className: dotClassName, children: buttonDot } = dot.props
            const { onClick: fnDot } = buttonDot.props
            return (
              <li key={dot.key} className={`hero-dot ${dotClassName === 'slick-active' ? 'hero-dot-active' : ''}`}>
                <button onClick={fnDot}><div></div></button>
              </li>
            )
          })
        }
      </ul>
    ),
    nextArrow: <SliderArrow IconPath={ChevronRight} />,
    prevArrow: <SliderArrow IconPath={ChevronLeft} />,
    afterChange: (newIndex) => {
      const video = document.getElementById(`video_homeHero_${newIndex}`)
      if (video) {
        video.play()
      }
    },
    beforeChange: (oldIndex) => {
      const video = document.getElementById(`video_homeHero_${oldIndex}`)
      if (video) {
        video.pause()
        video.currentTime = 0
      }
    },
  }

  const HeroSlides = () => (
    row.content.map((item, index) => (
      <div key={item._id}>
        <article
          style={item._id === sponsored._id ? { backgroundColor: sponsored.bgColor } : {}}
          className={classNames({
            'sponsoredItem': item._id === sponsored._id
          })}
        >
          <div className='itemContainer'>
            <div className='heroHeader'>
              <div className='mainWidth'>
                {item.type === 'Live' &&
                  <span className='itemTag'>En vivo</span>
                }
                <figure className='heroFig'>
                  <img
                    src={!isEmpty(item.imagesSite.logo)
                      ? useImageCDN
                        ? item.imagesSite.logo.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                        : item.imagesSite.logo
                      : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-logo.png'
                    }
                    alt={item.title}
                  />
                </figure>
                <h2>{item.title}</h2>
                <Button
                  variant='contained'
                  startIcon={<Icons path={iconPlay} />}
                  onClick={item.type === 'Serie'
                    ? () => handleOnClickWatchNow(item || {})
                    : () => navigate(`/player/live/${item._id}`)
                  }
                >
                  Ver ahora
                </Button>
                {item.type === 'Serie' && <Link to={`/serie/${item._id}`}>
                  <Button variant='contained' startIcon={<Icons path={iconInfo} />}>Episodios</Button>
                </Link>}
                {item._id === sponsored._id &&
                  <a href={sponsored.url} target="_blank" className='sponsoredLink' rel="noreferrer">
                    <span>Patrocinado por:</span>
                    <img
                      src={sponsored.imageUrl}
                      alt='placeholder'
                    />
                  </a>
                }
              </div>
            </div>
            <div className='heroImgBox'>
              <span className='shadowRight'></span>
              <span className='shadowLeft'></span>
              <span className='shadowBottom'></span>
              <figure className='ratioImgBox ratioHero'>
                <img
                  src={!isEmpty(item.imagesSite.hero)
                    ? useImageCDN
                      ? item.imagesSite.hero.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : item.imagesSite.hero
                    : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-hero.jpg'
                  }
                  alt={item?.title || ''}
                />
                {item?.vodTrailer?.idMedia &&
                  <video id={`video_homeHero_${index}`} controls={false} autoPlay preload='auto' loop='loop' muted>
                    {document.body.style.cursor = 'auto'}
                    <source src={`https://mdstrm.com/video/${item.vodTrailer.idMedia}.mp4`} type='video/mp4' />
                  </video>
                }
              </figure>
            </div>
          </div>
          <span className='shadowBottom'></span>
        </article>
      </div>
    ))
  )

  return (
    <div className='heroContentWrap' style={{ opacity: newOpacity }}>
      {
        row.content.length > 1
          ? (
            <Slider
              className='newsSliderHighlighted'
              {...settings}
            >
              {HeroSlides()}
            </Slider>
          )
          : HeroSlides()
      }
    </div>
  )
}