import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import PreHomeContainer from "../../containers/home/PrehomeContainer";
import Loading from "../Loading";

export default function PreHomeComponent(props) {
  const {
    preHomeUrl,
    preHomeTextButton,
    preHomeTitle,
    preHomeTimeOut
  } = props;

  const { isAuthenticated, keycloak, isLoading } = useSelector(
    (state) => state.auth
  );
  const [counter, setCounter] = useState(preHomeTimeOut);
  const navigate = useNavigate();

  useEffect(() => {
    !isAuthenticated && keycloak.login();
  }, [isAuthenticated, keycloak]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      navigate(preHomeUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, preHomeTimeOut]);

  return isLoading || !isAuthenticated ? (
    <Loading />
  ) : (
    <PreHomeContainer
      navigate={navigate}
      counter={counter}
      urlEvent={preHomeUrl}
      titleEvent={preHomeTitle}
      preHomeTextButton={preHomeTextButton}
    />
  );
}
