import { call, put, takeLatest, delay } from 'redux-saga/effects'
import axios from 'axios'
import { isEmpty, keyBy } from 'lodash'
import {
  doc,
  collection,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  query,
  Timestamp
} from 'firebase/firestore'
import { getFirestore } from 'firebase/firestore'

import {
  GET_PROFILES,
  GET_PROFILES_START,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_ERROR,
  GET_PROFILES_FINALLY,
  SET_PROFILE,
  SET_PROFILE_START,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_ERROR,
  SET_PROFILE_FINALLY,
  UPDATE_PROFILE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_FINALLY,
  DELETE_PROFILE,
  DELETE_PROFILE_START,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_ERROR,
  DELETE_PROFILE_FINALLY,
  SET_PROFILE_PREFERENCES,
  SET_PROFILE_PREFERENCES_START,
  SET_PROFILE_PREFERENCES_ERROR,
  SET_PROFILE_PREFERENCES_FINALLY,
  SET_CURRENT_PROFILE,
} from '../../../redux/actions'

const MAX_ATTEMPTS = 3
const DELAY_BETWEEN_RETRIES = 1000
const TIMEOUT = 10000

function* GetProfilesAction({ payload: { keycloak } }) {
  try {
    yield put(GET_PROFILES_START())
    let token = keycloak.token
    const sub = keycloak ? keycloak.subject : null
    const db = getFirestore()

    let attempt = 0
    let avatars = []

    while (attempt < MAX_ATTEMPTS) {
      attempt++

      try {
        const isTokenUpdated = yield call(keycloak.updateToken, 5)
        token = isTokenUpdated ? keycloak.token : token

        const headers = {
          headers: { 'authorization': `Bearer ${token}` },
          timeout: TIMEOUT
        }

        const { data } = yield call(
          axios.get,
          `${process.env.REACT_APP_API_URL}screen/avatars`,
          headers
        )
        avatars = data

        let profiles = []
        profiles = yield fetchProfiles(db, sub, attempt)

        if (isEmpty(profiles)) {
          profiles = yield createNewProfile(db, sub, avatars, keycloak, attempt)
        }

        const profilesWithAvatar = mergeProfilesWithAvatars(profiles, avatars)
        yield setCurrentProfile(profilesWithAvatar)

        yield put(GET_PROFILES_SUCCESS({ profilesWithAvatar, avatars }))

        attempt = MAX_ATTEMPTS
      } catch (error) {
        if (attempt === MAX_ATTEMPTS) {
          yield handleProfilesError(error, attempt)
        }
      }
    }
  } catch (error) {
    yield put(GET_PROFILES_ERROR({
      title: 'Ha ocurrido un error. Por favor intentar nuevamente.',
      code: 2010
    }))
  } finally {
    yield put(GET_PROFILES_FINALLY())
  }
}

function* fetchProfiles(db, sub, attempt) {
  try {
    const q = query(collection(db, 'users', sub, 'profiles'), orderBy('createdAt'))
    const querySnapshot = yield call(getDocs, q)
    const profiles = []
    querySnapshot.forEach(doc => profiles.push(doc.data()))
    return profiles
  } catch (error) {
    if (attempt === MAX_ATTEMPTS) {
      yield put(GET_PROFILES_ERROR({
        title: 'Ha ocurrido un error. Por favor intentar nuevamente.',
        code: 2006
      }))
    }
    return []
  }
}

function* createNewProfile(db, sub, avatars, keycloak, attempt) {
  try {
    const loadUserInfo = yield call(keycloak.loadUserInfo)
    const { email, first_name: name } = loadUserInfo
    const newProfile = {
      id: sub,
      idAvatar: avatars[0]._id,
      name,
      createdAt: Timestamp.fromDate(new Date())
    }

    const userCollection = doc(db, 'users', sub)
    const user = yield call(getDoc, userCollection)
    if (!user.exists()) {
      yield call(setDoc, userCollection, { id: sub, email, name })
    }
    yield call(setDoc, doc(userCollection, 'profiles', sub), newProfile)
    return [newProfile]
  } catch (error) {
    if (attempt === MAX_ATTEMPTS) {
      yield put(GET_PROFILES_ERROR({
        title: 'Ha ocurrido un error. Por favor intentar nuevamente.',
        code: 2007
      }))
    }
  }
}

function mergeProfilesWithAvatars(profiles, avatars) {
  const avatarsKeyedById = keyBy(avatars, '_id')
  return profiles.map(profile => ({
    ...profile,
    avatar: avatarsKeyedById[profile.idAvatar] || {}
  }))
}

function* setCurrentProfile(profilesWithAvatar) {
  const localProfile = JSON.parse(localStorage.getItem('currentProfile'))
  if (!isEmpty(localProfile)) {
    const dateString = localProfile.timestamp
    const now = new Date().getTime()

    if (((now - dateString) / 1000) < 86400) {
      const profilesKeyedById = keyBy(profilesWithAvatar, 'id')
      const currentProfile = profilesKeyedById[localProfile.currentProfileId]
      yield put(SET_CURRENT_PROFILE(currentProfile || profilesWithAvatar[0]))
    } else {
      localStorage.clear()
      yield put(SET_CURRENT_PROFILE(profilesWithAvatar[0]))
    }
  } else {
    yield put(SET_CURRENT_PROFILE(profilesWithAvatar[0]))
  }
}

function* handleProfilesError(error, attempt) {
  const errObj = {
    title: 'Ha ocurrido un error. Por favor intentar nuevamente.',
    code: 2011
  }
  if (error.code === 'permission-denied') {
    if (attempt < MAX_ATTEMPTS) {
      yield delay(DELAY_BETWEEN_RETRIES)
    } else {
      yield put(GET_PROFILES_ERROR(errObj))
    }
  } else {
    yield put(GET_PROFILES_ERROR(errObj))
  }
}

function* SetProfileAction({ payload: { keycloak, newProfile } }) {
  yield put(SET_PROFILE_START())
  const sub = keycloak ? keycloak.subject : null

  const db = getFirestore()
  const profile = doc(db, 'users', sub, 'profiles', newProfile.id)

  try {
    yield call(setDoc, profile, {
      ...newProfile,
      createdAt: Timestamp.fromDate(new Date()),
    })

    yield put(SET_PROFILE_SUCCESS(newProfile))
  } catch (error) {
    yield put(SET_PROFILE_ERROR(error.message))
  } finally {
    yield put(SET_PROFILE_FINALLY())
  }
}

function* UpdateProfileAction({ payload: { keycloak, newProfile } }) {
  yield put(UPDATE_PROFILE_START())
  const sub = keycloak ? keycloak.subject : null

  const db = getFirestore()
  const profile = doc(db, 'users', sub, 'profiles', newProfile.id)

  try {
    yield call(updateDoc, profile, newProfile)

    yield put(UPDATE_PROFILE_SUCCESS(newProfile))
  } catch (error) {
    yield put(UPDATE_PROFILE_ERROR(error.message))
  } finally {
    yield put(UPDATE_PROFILE_FINALLY())
  }
}

function* DeleteProfileAction({ payload: { keycloak, idProfile } }) {
  yield put(DELETE_PROFILE_START())
  const sub = keycloak ? keycloak.subject : null

  const db = getFirestore()
  const profile = doc(db, 'users', sub, 'profiles', idProfile)

  try {
    const cwSnaps = yield call(getDocs, collection(profile, 'continue_watching'))
    cwSnaps.forEach(document => deleteDoc(doc(profile, 'continue_watching', document.id)))

    const seriesSnaps = yield call(getDocs, collection(profile, 'series'))
    seriesSnaps.forEach(document => deleteDoc(doc(profile, 'series', document.id)))

    yield call(deleteDoc, profile)

    yield put(DELETE_PROFILE_SUCCESS(idProfile))
  } catch (error) {
    yield put(DELETE_PROFILE_ERROR(error.message))
  } finally {
    yield put(DELETE_PROFILE_FINALLY())
  }
}

function* SetProfilePreferenceAction({ payload: { keycloak, idSerie, idCurrentProfile, newPreferences } }) {
  yield put(SET_PROFILE_PREFERENCES_START())
  const sub = keycloak.subject

  const db = getFirestore()
  const profilePreferences = doc(db, 'users', sub, 'profiles', idCurrentProfile, 'series', idSerie)

  try {
    yield call(setDoc, profilePreferences, newPreferences)
  } catch (error) {
    yield put(SET_PROFILE_PREFERENCES_ERROR(error.message))
  } finally {
    yield put(SET_PROFILE_PREFERENCES_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_PROFILES, GetProfilesAction)
  yield takeLatest(SET_PROFILE, SetProfileAction)
  yield takeLatest(UPDATE_PROFILE, UpdateProfileAction)
  yield takeLatest(DELETE_PROFILE, DeleteProfileAction)
  yield takeLatest(SET_PROFILE_PREFERENCES, SetProfilePreferenceAction)
}