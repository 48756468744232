import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContactContainer from '../../containers/profile/ContactContainer'

export default memo(function ContactComponent() {
  const { isFreePlan } = useSelector(state => state.subscription)

  const navigate = useNavigate();

  return <ContactContainer navigate={navigate} isFreePlan={isFreePlan} />
})