import { createAction } from 'redux-actions';

export const GET_CONTINUE_WATCHING = createAction('GET_CONTINUE_WATCHING');
export const GET_CONTINUE_WATCHING_START = createAction('GET_CONTINUE_WATCHING_START');
export const GET_CONTINUE_WATCHING_SUCCESS = createAction('GET_CONTINUE_WATCHING_SUCCESS');
export const GET_CONTINUE_WATCHING_ERROR = createAction('GET_CONTINUE_WATCHING_ERROR');
export const GET_CONTINUE_WATCHING_FINALLY = createAction('GET_CONTINUE_WATCHING_FINALLY');

export const GET_NOT_VIEWED_CONTINUE_WATCHING = createAction('GET_NOT_VIEWED_CONTINUE_WATCHING');
export const GET_NOT_VIEWED_CONTINUE_WATCHING_START = createAction('GET_NOT_VIEWED_CONTINUE_WATCHING_START');
export const GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS = createAction('GET_NOT_VIEWED_CONTINUE_WATCHING_SUCCESS');
export const GET_NOT_VIEWED_CONTINUE_WATCHING_ERROR = createAction('GET_NOT_VIEWED_CONTINUE_WATCHING_ERROR');
export const GET_NOT_VIEWED_CONTINUE_WATCHING_FINALLY = createAction('GET_NOT_VIEWED_CONTINUE_WATCHING_FINALLY');

export const MERGE_CONTINUE_WATCHING_SERIE = createAction('MERGE_CONTINUE_WATCHING_SERIE')
export const MERGE_CONTINUE_WATCHING_SERIE_START = createAction('MERGE_CONTINUE_WATCHING_SERIE_START')
export const MERGE_CONTINUE_WATCHING_SERIE_FINALLY = createAction('MERGE_CONTINUE_WATCHING_SERIE_FINALLY')

export const MERGE_CONTINUE_WATCHING_EVENT = createAction('MERGE_CONTINUE_WATCHING_EVENT')
export const MERGE_CONTINUE_WATCHING_EVENT_START = createAction('MERGE_CONTINUE_WATCHING_EVENT_START')
export const MERGE_CONTINUE_WATCHING_EVENT_FINALLY = createAction('MERGE_CONTINUE_WATCHING_EVENT_FINALLY')

export const UPDATE_CONTINUE_WATCHING = createAction('UPDATE_CONTINUE_WATCHING');

export const GET_WATCH_NOW_SUCCESS = createAction('GET_WATCH_NOW_SUCCESS')