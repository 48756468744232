import { createAction } from 'redux-actions'

export const GET_EVENT = createAction('GET_EVENT')
export const GET_EVENT_START = createAction('GET_EVENT_START')
export const GET_EVENT_SUCCESS = createAction('GET_EVENT_SUCCESS')
export const GET_EVENT_ERROR = createAction('GET_EVENT_ERROR')
export const GET_EVENT_FINALLY = createAction('GET_EVENT_FINALLY')
export const GET_EVENTS = createAction('GET_EVENTS')
export const GET_EVENTS_START = createAction('GET_EVENTS_START')
export const GET_EVENTS_SUCCESS = createAction('GET_EVENTS_SUCCESS')
export const GET_EVENTS_ERROR = createAction('GET_EVENTS_ERROR')
export const GET_EVENTS_FINALLY = createAction('GET_EVENTS_FINALLY')