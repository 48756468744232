import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isIOS, isMobile } from 'react-device-detect'
import { isEmpty } from 'lodash'

import { GET_ONBOARDING_PLANS } from '../plans/plans/plans.actions'
import Loading from '../Loading'
import OnboardingContainer from '../../containers/login/OnboardingContainer'

export default memo(function OnboardingComponent() {
  const { isAuthenticated, keycloak } = useSelector((state) => state.auth)
  const { isFreePlan } = useSelector((state) => state.subscription)
  const { onboardingPlans, isLoadingOnboardingPlans } = useSelector((state) => state.plans)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
   if (isAuthenticated && !isMobile){
    navigate('/directing')
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    isEmpty(onboardingPlans)
      && dispatch(GET_ONBOARDING_PLANS())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingPlans])

  return (
    isLoadingOnboardingPlans
      ? <Loading />
      : (
        <OnboardingContainer
          isAuthenticated={isAuthenticated}
          isFreePlan={isFreePlan}
          isIOS={isIOS}
          isMobile={isMobile}
          keycloak={keycloak}
          onboardingPlans={onboardingPlans}
        />
      )
  )
})
