import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { VALIDATE_SUBSCRIPTION } from '../../redux/actions'

import PaymentValidationContainer from '../../containers/payment/PaymentValidationContainer'
import { isMobile } from 'react-device-detect'

export default memo(function PaymentValidationComponent() {
  const { isAuthenticated, keycloak } = useSelector(state => state.auth)
  const { transaction } = useSelector(state => state.subscription)

  const [message, setMessage] = useState('Validando Pago...')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const query = new URLSearchParams(useLocation().search)

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    const transactionId = query.get('transaction_id')
    !isEmpty(transactionId) && dispatch(VALIDATE_SUBSCRIPTION({ keycloak, transactionId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(transaction) && keycloak !== null) {
      if (
        transaction.subscriber_id === keycloak.subject
        && transaction.Subscription?.paid === true
        && transaction.Subscription?.active === true
      ) {
        setMessage('¡Pago validado!. Redireccionando al inicio...')
      } else {
        setMessage('Ha ocurrido un error. Por favor, vuelva a intentarlo en unos minutos...')
      }
      setTimeout(() => {
        isMobile
          ? navigate('/descargar-app')
          : navigate('/inicio')
      }, 5000)
    }
    setTimeout(() => {
      navigate('/inicio')
    }, 10000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction])

  return (
    <PaymentValidationContainer message={message} />
  )
})