import { handleActions } from 'redux-actions'
import moment from 'moment'

import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_FINALLY,
  SET_NOTIFICATION_RECEIVED,
  CHECK_ALL_NOTIFICATIONS,
  SUBSCRIBE_NOTIFICATIONS_START,
  SUBSCRIBE_NOTIFICATIONS_SUCCESS,
  SUBSCRIBE_NOTIFICATIONS_ERROR,
  SUBSCRIBE_NOTIFICATIONS_FINALLY,
  SET_NOTIFY_TOKEN
} from '../../redux/actions'

const initialState = {
  data: [],
  isLoading: true,
  notifyToken: '',
  error: null,
}

export default handleActions({
  [GET_NOTIFICATIONS_START]: state => ({
    ...state,
    isLoading: true
  }),
  [GET_NOTIFICATIONS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.map(notification => {
      return {
        ...notification,
        isChecked: moment(new Date()).diff(moment(notification.date), 'minutes') > 30 ? true : false
      }
    })
  }),
  [GET_NOTIFICATIONS_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
  [GET_NOTIFICATIONS_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [SET_NOTIFICATION_RECEIVED]: (state, action) => ({
    ...state,
    data: [
      {
        title: action.payload.title,
        body: action.payload.body,
        image_url: action.payload.image,
        date: new Date(),
        isChecked: false
      },
      ...state.data
    ]
  }),
  [CHECK_ALL_NOTIFICATIONS]: state => ({
    ...state,
    data: state.data.map(notification => ({ ...notification, isChecked: true }))
  }),
  [SUBSCRIBE_NOTIFICATIONS_START]: state => ({
    ...state,
    isLoading: true
  }),
  [SUBSCRIBE_NOTIFICATIONS_SUCCESS]: (state, action) => ({
    ...state,
  }),
  [SUBSCRIBE_NOTIFICATIONS_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
  [SUBSCRIBE_NOTIFICATIONS_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [SET_NOTIFY_TOKEN]: (state, action) => ({
    ...state,
    notifyToken: action.payload
  })
}, initialState)