import React from 'react'
import { Button, Modal, Fade, Backdrop } from '@mui/material'
import { isEmpty } from 'lodash'

import { Icons } from '../../components/Icons'
import iconArrowBack from '../../assets/icons/arrowBack.svg'
import iconClose from '../../assets/icons/close.svg'
import devicePhone from '../../assets/icons/devicePhone.svg'
import playOutline from '../../assets/icons/playOutline.svg'
import deviceTv from '../../assets/icons/deviceTv.svg'
import signal from '../../assets/icons/signal.svg'
import calendar from '../../assets/icons/calendar.svg'
import check from '../../assets/icons/check.svg'

export default function PlansContainer(props) {
  const {
    email,
    handleOnPayPlan,
    navigate,
    handleOnChangePlan,
    isSameUser,
    keycloak,
    plans,
    setIsSameUser,
    subscription: {
      can_change: canChange = false,
      paid = false
    },
    subscriptionsPlanIds,
    userId
  } = props

  const onlySuscriptions = plans.filter(plan => plan.unique_pay !== true)
  const onlyUniquePays = plans.filter(plan => plan.unique_pay === true)

  const getIcon = (icon) => {
    switch (icon) {
      case 'devicePhone':
        return devicePhone
      case 'signal':
        return signal
      case 'playOutline':
        return playOutline
      case 'deviceTv':
        return deviceTv
      case 'calendar':
        return calendar
      default:
        return deviceTv
    }
  }

  return (
    <>
      <main className='appWrap plansWrap'>
        <Button
          variant='contained'
          className='medium goBack'
          onClick={() => navigate("/inicio")}
          startIcon={<Icons path={iconArrowBack} />}
        >
          Volver
        </Button>
        {/* Planes principales */}
        <form>
          <header className='mediumHeader centered'>
            <h3>Conoce nuestros planes</h3>
          </header>
          <div className='plansColsDisplay'>
            {onlySuscriptions.map(plan => (
              <section
                style={{ display: !plan.show_on_plans ? 'none' : 'inherit' }}
                className={
                  (subscriptionsPlanIds.includes(plan.id) && plan.amount !== 0)
                    || (!subscriptionsPlanIds.includes(plan.id) && !canChange && paid)
                    ? 'active planCard'
                    : 'planCard'
                }
                key={plan.id}
              >
                <header>
                  <h3>{plan.shortName.toUpperCase()}</h3>
                  <div className='planPrice'>
                    {plan.amount === 0 ? (
                      'Gratis'
                    ) : subscriptionsPlanIds.includes(plan.id) ? (
                      <>
                        <Icons path={check} />
                        <p>Adquirido</p>
                      </>
                    ) : !subscriptionsPlanIds.includes(plan.id) && !canChange && paid ? (
                      <>
                        <p style={{ color: '#ffff00', fontSize: '0.9rem', textAlign: 'end' }}>No disponible<br />para cambio</p>
                      </>
                    ) : (
                      <>
                        {`$${plan.amount.toLocaleString('de')}`}
                        <span> {plan.Periodicity?.name?.toLowerCase() || ''}</span>
                      </>
                    )}
                  </div>
                </header>
                <ul>
                  {plan.attributes.map((attribute, i) => (
                    <li key={i} className={attribute.active ? '' : 'disabled'}>
                      <Icons path={getIcon(attribute.icon)} />
                      <div>
                        <h5>{attribute.name}</h5>
                        <p>{attribute.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className='planAction'>
                  {!subscriptionsPlanIds.includes(plan.id) && plan.trial_period_days > 0
                    && !(subscriptionsPlanIds.includes(process.env.REACT_APP_MS_PLAN_FULL)
                      || subscriptionsPlanIds.includes(process.env.REACT_APP_MS_PLAN_FULL_ANUAL))
                    && <p>{`¡Prueba gratis durante ${plan.trial_period_days} ${plan.trial_period_days === 1 ? 'día' : 'días'}!`}</p>
                  }
                  {subscriptionsPlanIds.includes(plan.id)
                    ? (
                      <Button
                        onClick={() => navigate('/inicio')}
                        type='button'
                        variant='contained'
                      >
                        Ver contenido
                      </Button>
                    ) : !canChange && paid
                      ? (
                        <></>
                      ) : (
                        <Button
                          onClick={() => {
                            (plan.id === process.env.REACT_APP_MS_PLAN_FULL && subscriptionsPlanIds.includes(process.env.REACT_APP_MS_PLAN_FULL_ANUAL))
                              || (plan.id === process.env.REACT_APP_MS_PLAN_FULL_ANUAL && subscriptionsPlanIds.includes(process.env.REACT_APP_MS_PLAN_FULL))
                              ? handleOnChangePlan({ planId: plan.id })
                              : handleOnPayPlan({ planId: plan.id, isSubscription: true })
                          }}
                          type='submit'
                          variant='contained'
                        >
                          {
                            (plan.id === process.env.REACT_APP_MS_PLAN_FULL && subscriptionsPlanIds.includes(process.env.REACT_APP_MS_PLAN_FULL_ANUAL))
                              ? `Cambiar a ${plan.name}`
                              : (plan.id === process.env.REACT_APP_MS_PLAN_FULL_ANUAL && subscriptionsPlanIds.includes(process.env.REACT_APP_MS_PLAN_FULL))
                                ? '¡Ahorrar 4 meses!'
                                : plan.trial_period_days > 0 ? 'Prueba ahora' : 'Comprar'
                          }
                        </Button>
                      )
                  }
                </div>
              </section>
            ))}
          </div>
        </form>

        {/* Otros planes */}
        {!isEmpty(onlyUniquePays) &&
          <form>
            <header className='mediumHeader centered'>
              <hr />
              <h3>Te puede interesar</h3>
            </header>
            <div className='plansColsDisplay'>
              {onlyUniquePays.map(plan => (
                <section className={subscriptionsPlanIds.includes(plan.id) & plan.amount !== 0 ? 'active planCard' : 'planCard'} key={plan.id}>
                  <header>
                    {
                      plan.logo
                        ? <>
                          <img src={plan.logo} alt={plan.name} />
                        </>
                        : <>
                          <h3>{plan.name}</h3>
                        </>
                    }
                    <div className='planPrice'>
                      {plan.amount === 0 ? (
                        'Gratis'
                      ) : subscriptionsPlanIds.includes(plan.id) ? (
                        <>
                          <Icons path={check} />
                          <p>Adquirido</p>
                        </>
                      ) : (
                        `$${plan.amount.toLocaleString('de')}`
                      )}
                    </div>
                  </header>
                  <ul>
                    {plan.attributes.map((attribute, i) => (
                      <li key={i} className={attribute.active ? '' : 'disabled'}>
                        <Icons path={getIcon(attribute.icon)} />
                        <div>
                          <h5>{attribute.name}</h5>
                          <p>{attribute.description}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className='planAction'>
                    {!subscriptionsPlanIds.includes(plan.id) && plan.trial_period_days > 0
                      && <p>{`¡Prueba gratis durante ${plan.trial_period_days} ${plan.trial_period_days === 1 ? 'día' : 'días'}!`}</p>
                    }
                    {subscriptionsPlanIds.includes(plan.id)
                      ? (
                        <Button
                          onClick={() => navigate('/inicio')}
                          type='button'
                          variant='contained'
                        >
                          Ver contenido
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleOnPayPlan({ planId: plan.id, isSubscription: false })}
                          type='submit'
                          variant='contained'
                        >
                          {plan.trial_period_days > 0 ? 'Prueba ahora' : 'Comprar'}
                        </Button>
                      )
                    }
                  </div>
                </section>
              ))}
            </div>
          </form>
        }
      </main>

      <Modal
        open={!isSameUser}
        onClose={() => setIsSameUser(true)}
        className='modalContainer app'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!isSameUser}>
          <div className='modalWrapPlans'>
            <button
              className='closeModal i'
              onClick={() => setIsSameUser(true)}
            >
              <Icons path={iconClose} />
            </button>
            <p>Tu cuenta de origen es distinta a la actual.<br />¿Quieres continuar como <b>{email}</b> de todas maneras?</p>
            <Button
              variant='contained'
              className='gray'
              onClick={() => {
                navigate(`/planes/${userId}`)
                keycloak.logout()
              }}
            >
              Cambiar cuenta
            </Button>
            <Button variant='contained' className='blue' onClick={() => setIsSameUser(true)}>Continuar</Button>
          </div>
        </Fade>
      </Modal>
    </>
  )
}