import React, { memo, useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import screenfull from 'screenfull'
import { Timestamp } from 'firebase/firestore'
import { intersection, isEmpty } from 'lodash'
import axios from 'axios'

import {
  GET_MEDIA,
  GET_MDSTRM_ACCESS_TOKEN,
  SET_CONTINUE_WATCHING,
  GET_MEDIA_SUCCESS
} from '../../redux/actions'

import PlayerContainer from '../../containers/player/PlayerContainer'
import PlayerControls from './PlayerControls'
import Loading from '../Loading'
import { timerFormat } from '../../utils/timerFormat'
import { withError } from '../error/ErrorComponent'
import PlayerAccessAlertContainer from '../../containers/player/PlayerAccessAlertContainer'

const baseUrlThumbsImage = `${process.env.REACT_APP_API_MDSTRM_THUMBNAIL_URL}/${process.env.REACT_APP_API_MDSTRM_ACOUNT_ID_MEGA}`

export default withError(memo(function PlayerComponent() {
  const { isAuthenticated, keycloak, isLoading, idUser } = useSelector(state => state.auth)
  const { data: media, accessToken, isLoading: isLoadingMedia, isLoadingToken, error: mediaError } = useSelector(state => state.media)
  const { serie } = useSelector(state => state.series)
  const { currentProfile, error: profileError, isLoading: isLoadingProfiles } = useSelector(state => state.profiles)
  const { isLoading: isLoadingSuscription, subscriptionsPlanIds, subscriber, error: subscriptionError } = useSelector(state => state.subscription)
  const { error: notificationsError } = useSelector(state => state.notifications)

  const [isLoadingError, setIsLoadingError] = useState(false)
  const [hasAccess, setHasAccess] = useState(true)
  const [player, setPlayer] = useState(null)
  const [count, setCount] = useState(0)
  const [duration, setDuration] = useState(0)
  const [position, setPosition] = useState(0)
  const [elapsedTime, setElapsedTime] = useState('00:00')
  const [totalDuration, setTotalDuration] = useState('00:00')
  const [controlsVisibility, setControlsVisibility] = useState(true)
  const [nextEpisodeVisibility, setNextEpisodeVisibility] = useState(false)
  const [isBuffering, setIsBuffering] = useState(true)
  const [timerToSave, setTimerToSave] = useState(0)
  const [nextVod, setNextVod] = useState({})
  const [allVods, setAllVods] = useState([])
  const [isLoadingNextVod, setIsLoadingNextVod] = useState(true)
  const [isShowingAds, setIsShowingAds] = useState(false)
  const [playerState, setPlayerState] = useState({
    playing: true,
    muted: false,
    volume: JSON.parse(sessionStorage.getItem("megaGoCurrentVolume")) || 50,
    lastVolume: JSON.parse(sessionStorage.getItem("megaGoCurrentVolume")) || 50,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    position: position,
  })
  const [vttContent, setVttContent] = useState(null)
  const playerContainerRef = useRef(null)
  const { type, _id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    isAuthenticated
      && dispatch(GET_MEDIA({
        keycloak,
        idCurrentProfile: currentProfile.id,
        type,
        id: _id
      }))

    return () => {
      dispatch(GET_MEDIA_SUCCESS({}))
      setIsLoadingError(false)
      setCount(0)
      setDuration(0)
      setPosition(0)
      setElapsedTime('00:00')
      setTotalDuration('00:00')
      setControlsVisibility(true)
      setIsBuffering(true)
      setTimerToSave(0)
      setNextVod({})
      setAllVods([])
      setIsLoadingNextVod(true)
      setPlayerState({
        playing: true,
        volume: JSON.parse(sessionStorage.getItem("megaGoCurrentVolume")) || 50,
        lastVolume: JSON.parse(sessionStorage.getItem("megaGoCurrentVolume")) || 50,
        played: 0,
        seeking: false
      })
      setPlayer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, currentProfile.id])

  const hasAccessToCallback = useCallback(async (customerId) => {
    try {
      const headers = { timeout: 10000 }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_TLBX_URL}hasAccessTo?customerId=${customerId}`,
        headers
      )

      return data
    } catch {
      return []
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(media)) {
      const contentPlanIds = type === 'live' ? (media.planIds || []) : (media.serie.planIds || [])

      if (isEmpty(intersection(subscriptionsPlanIds, contentPlanIds)) && media.pay === true) {
        navigate('/planes')
      } else if (!isEmpty(subscriber.idp) && media.pay === true) {
        setIsLoadingError(true)
        hasAccessToCallback(subscriber.external_customer_id)
          .then(urns => {
            const urnsFiltered = urns.filter(urn => urn === 'urn:tve:mgofullfijo')
            if (!isEmpty(urnsFiltered)) {
              media.position !== undefined
                && media.position !== null
                && !media.isViewed
                && setPosition(media.position)
              setHasAccess(true)
            } else {
              setHasAccess(false)
            }
          })
      } else {
        media.position !== undefined
          && media.position !== null
          && !media.isViewed
          && setPosition(media.position)

        setHasAccess(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media])

  useEffect(() => {
    !isEmpty(media) && media._id === _id
      && dispatch(GET_MDSTRM_ACCESS_TOKEN({
        keycloak,
        id: media.idMedia,
        type: type === 'vod' ? 'media' : type,
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media])

  useEffect(() => {
    if (type !== 'live' && hasAccess) {
      if (!isEmpty(media)) {
        const allVodsAux = allVods
        if (isEmpty(allVodsAux) && media.serie !== null) {
          const serie = media.serie
          serie.seasons.forEach(season => season.vods?.forEach(vod => allVodsAux.push(vod)))
          setAllVods(allVodsAux)
        } else if (isEmpty(allVodsAux) && media.event !== null) {
          const event = media.event
          event.sections.forEach(section => section.content?.forEach(contents => allVodsAux.push(contents)))
          setAllVods(allVodsAux)
        }
        if (!isEmpty(allVodsAux)) {
          const mediaIndex = allVodsAux.findIndex(vod => vod._id === media._id)
          setNextVod(allVodsAux[mediaIndex]._id === allVodsAux[allVodsAux.length - 1]._id
            ? {}
            : allVodsAux[mediaIndex + 1]
          )
        }
        setIsLoadingNextVod(false)
      }
    } else {
      setIsLoadingNextVod(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media])

  useEffect(() => {
    if (type !== 'live' && hasAccess) {
      if (!isEmpty(player) && player.isReady()) {
        setPlayerState({ ...playerState, played: (position / duration) })
        setElapsedTime(timerFormat(position))
        setTotalDuration(timerFormat(duration))
        player.seekTo(position)
        player.videoPlay()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, duration])

  useEffect(() => {
    if (
      isEmpty(player)
      && !isEmpty(accessToken)
      && !isEmpty(media)
      && !isLoading
      && !isLoadingMedia
      && !isLoadingSuscription
      && !isLoadingToken
      && !isLoadingNextVod
      && !isLoadingProfiles
      && hasAccess
    ) {
      const playerOptions = {
        width: 1080,
        height: 720,
        type: type === 'live' ? 'live' : "media",
        id: media.idMedia,
        access_token: accessToken,
        autoplay: true,
        player: process.env.REACT_APP_MDSTRM_ID_PLAYER,
        controls: false,
        show_controls_on_ad: true,
        pause_on_screen_click: false,
        pause_ad_on_click: false,
        customer: idUser,
        starttime: position,
        volume: JSON.parse(sessionStorage.getItem("megaGoCurrentVolume")) || 50,
        events: {
          onPlayerMounted: function (data) {
            if (type !== 'live') {
              axios.get(
                `${baseUrlThumbsImage}/${media.idMedia}/preview_${media.idMedia}.vtt`,
              ).then(({ data }) => {
                setVttContent(data)
              })
            }
          },
          onPlayerReady: function () {
            this.getDuration(playerDuration => {
              setDuration(playerDuration)
            })
          },
          onPlay: function () {
            setIsBuffering(false)
            setIsLoadingError(false)
          },
          onVideoEnd: function () {
            if (!isEmpty(nextVod)) {
              setNextEpisodeVisibility(true)
              setControlsVisibility(false)
            } else {
              setControlsVisibility(true)
            }
          },
          onVideoError: function (e, data) {
            handleError(e, data)
          },
          onTimeUpdate: function (time) {
            setPosition(time)
          },
          onFullscreenChange: function (fullscreen) {
            console.log("Is fullscreen " + fullscreen)
          },
          onBuffering: function () {
            setIsBuffering(true)
          },
          onAdsStarted: function () {
            setIsShowingAds(true)
          },
          onAdsAllAdsCompleted: function () {
            setIsShowingAds(false)
          }
        }
      }

      const mediaSerieId = media.serie ? media.serie._id : media._id
      const kvshow = `seccion%3Dserie%2Cnivel%3Dinterior%2Cid%3D${mediaSerieId}%2Ctipo%3Dteleseries%2Ckeywords%3D${encodeURI(media.serie ? media.serie.title : media.title)}}`
      const vastUrlShow = `https://pubads.g.doubleclick.net/gampad/ads?iu=/143911651/ott/series/vod/preroll&description_url=https%3A%2F%2Fmegago.cl%2Fserie%2F${mediaSerieId}&tfcd=0&npa=0&sz=320x180%7C640x360%7C640x480&gdfp_req=1&output=vast&unviewed_position_start=1&ad_type=audio_video&env=instream&cust_params=${kvshow}`

      const newPlayerOptions = subscriptionsPlanIds.length <= 1
        ? { ...playerOptions, ads: { map: isEmpty(media.vastURL) ? vastUrlShow : media.vastURL, volume: 100 } }
        : playerOptions

      var mdstrmPlayer = new window.MediastreamPlayer("mdstrm", newPlayerOptions)
      setPlayer(mdstrmPlayer)

      return () => {
        setPlayer(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    media,
    isLoading,
    isLoadingMedia,
    isLoadingSuscription,
    isLoadingToken,
    isLoadingNextVod,
    isLoadingProfiles
  ])

  const {
    playing,
    muted,
    volume,
    lastVolume,
    played,
    seeking
  } = playerState

  // Handle function to play or pause video
  const handlePlayPause = () => {
    try {
      if (player.isPlaying()) {
        setPlayerState({ ...playerState, playing: false })
        player.videoStop()
        type !== 'live' && playing && position >= 10 && (media.serie?.seasons[0]?.vods?.length ?? 0) > 0
          && dispatch(SET_CONTINUE_WATCHING({
            keycloak,
            idCurrentProfile: currentProfile.id,
            continueWatching: {
              id: _id,
              duration,
              position,
              serieId: media.serie._id,
              viewedLastAt: Timestamp.fromDate(new Date()),
              isViewed: duration - position <= 10
            }
          }))
      } else {
        player.videoPlay()
        setPlayerState({ ...playerState, playing: true })
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle continue watching
  const handleContinueWatching = () => {
    try {
      type !== 'live' && position >= 10
        && dispatch(SET_CONTINUE_WATCHING({
          keycloak,
          idCurrentProfile: currentProfile.id,
          continueWatching: {
            id: _id,
            duration,
            position,
            serieId: media.serie !== null ? media.serie._id : media.event._id,
            viewedLastAt: Timestamp.fromDate(new Date()),
            isViewed: duration - position <= 10
          }
        }))
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle rewind <<< player
  const handleRewind = () => {
    try {
      player.getCurrentTime(currentTime => player.seekTo(currentTime - 10))
      setCount(0)
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle forward >>> player
  const handleForward = () => {
    try {
      player.getCurrentTime(currentTime => player.seekTo(currentTime + 10))
      setCount(0);
    }
    catch (err) {
      console.error(err)
    }
  }

  // Save current volume on sesion storage
  const saveVolume = (setedVolume) => {
    sessionStorage.setItem("megaGoCurrentVolume", setedVolume)
  }

  // Handle when volume change
  const handleVolumeChange = (e, newValue) => {
    try {
      setPlayerState({ ...playerState, volume: newValue, lastVolume: newValue, muted: newValue === 0 ? true : false })
      saveVolume(parseFloat(newValue))
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle volume comited change
  const handleVolumeSeekUp = (e, newValue) => {
    try {
      setPlayerState({ ...playerState, volume: newValue, lastVolume: newValue, muted: newValue === 0 ? true : false })
      saveVolume(newValue)
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle mute function
  const handleMute = () => {
    try {
      setPlayerState({ ...playerState, volume: muted ? true && lastVolume : 0, muted: !playerState.muted })
    }
    catch (err) {
      console.error(err)
    }
  }

  // Change volume on state change
  useEffect(() => {
    try {
      !isEmpty(player) && player.isReady() && player.setVolume(volume)
    }
    catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume, muted])

  // Handle fullscreen
  const toggleFullScreen = () => {
    try {
      screenfull.toggle(playerContainerRef.current)
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle progress time of player
  const handleProgress = (time) => {
    try {
      if (count > 4) {
        setControlsVisibility(false)
        document.body.style.cursor = 'none'
      }
      if (controlsVisibility) {
        setCount(count + 1)
      }
      if (!seeking && type !== 'live') {
        setPlayerState({
          ...playerState,
          played: (time / duration) * 100,
        })
        setElapsedTime(timerFormat(time))
      }
      if (!isBuffering && playing && type !== 'live') {
        if (timerToSave >= 30) {
          dispatch(SET_CONTINUE_WATCHING({
            keycloak,
            idCurrentProfile: currentProfile.id,
            continueWatching: {
              id: _id,
              duration,
              position,
              serieId: media.serie !== null ? media.serie._id : media.event._id,
              viewedLastAt: Timestamp.fromDate(new Date()),
              isViewed: duration - position <= 10
            }
          }))
          setTimerToSave(0)
        } else {
          setTimerToSave(timerToSave + 1)
        }
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  // Set position in time bar
  useEffect(() => {
    handleProgress(position)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, duration])

  // Handle when mouse moves seeking
  const handleSeekChange = (e, newValue) => {
    try {
      setPlayerState({ ...playerState, played: newValue })
      player.seekTo((newValue / 100) * duration)
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle when mouse down on seeking the player time slider
  const handleSeekMouseDown = (e) => {
    try {
      setPlayerState({ ...playerState, playing: false, seeking: true })
      player.videoStop()
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle when mouse up on seeking the player time slider
  const handleSeekMouseUp = (e, newValue) => {
    try {
      setPlayerState({ ...playerState, playing: true, seeking: false, played: newValue })
      player.seekTo((newValue / 100) * duration)
      player.videoPlay()
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle when mouse move on player
  const handleMouseMove = () => {
    try {
      setControlsVisibility(true)
      document.body.style.cursor = 'auto'
      setCount(0)
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle when mouse leave screen or player area
  const handleMouseLeave = () => {
    try {
      setControlsVisibility(false)
    }
    catch (err) {
      console.error(err)
    }
  }

  // Handle player errors
  const handleError = (e, data) => {
    try {
      if (e.srcElement && e.srcElement.error) {
        setIsLoadingError(true)
        console.table('playerRef', player)
        console.table('event', e)

        switch (e.srcElement.error.code) {
          case 2: {
            console.log('fatal network error encountered, try to recover: ' + e.srcElement.error.message)
            let hls = player.getInternalPlayer('hls')
            hls.startLoad()
            break
          }
          case 3: {
            try {
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log("wait 50ms")
                  resolve(true)
                }, 50)
              }).then((result) => {
                let hls = player.getInternalPlayer('hls')
                console.log('fatal media error encountered, try to recover: ' + e.srcElement.error.message)
                hls.swapAudioCodec()
                hls.recoverMediaError()
                console.log('fatal media error recovered: ')
              })
              break
            }
            catch (ex2) {
              console.error('fatal media error not recovered: ', ex2)
              break
            }
          }
          default: {
            break
          }
        }
      }
      else {
        if (data) {
          console.table('data', data)
        }
      }
    }
    catch (error) {
      console.log("error on recover")
      console.table(error)
    }
  }

  // Functions when go back button is pressed
  const handleOnClose = () => {
    navigate(-1)
    type !== 'live' && position >= 10 && (media.serie?.seasons[0]?.vods?.length ?? 0) > 0
      && dispatch(SET_CONTINUE_WATCHING({
        keycloak,
        idCurrentProfile: currentProfile.id,
        continueWatching: {
          id: _id,
          duration,
          position,
          serieId: media.serie !== null ? media.serie._id : media.event._id,
          viewedLastAt: Timestamp.fromDate(new Date()),
          isViewed: duration - position <= 10
        }
      }))
  }

  const handleProfileErrorOnClose = () => {
    if (type === "vod" && serie?._id) {
      const serieId = serie._id
      window.location.href = `/serie/${serieId}`
    } else {
      window.location.href = '/inicio'
    }
  }

  return (
    (profileError || mediaError || subscriptionError || notificationsError)
      ? <PlayerAccessAlertContainer handleOnClose={handleProfileErrorOnClose} error={profileError || mediaError || subscriptionError || notificationsError} showRetryButton />
      :
      (
        isLoading
        || isLoadingMedia
        || isLoadingSuscription
        || isLoadingToken
        || (type !== ('live') && (media._id !== _id))
        || isLoadingNextVod
        || isLoadingProfiles
      )
        ?
        <Loading />
        : !hasAccess
          ? <PlayerAccessAlertContainer
            handleOnClose={handleOnClose}
            error={{
              title: 'Actualmente no cuentas con acceso a este contenido',
              body: 'Contacta a tu proveedor para obtener mayor información.'
            }}
          />
          :
          <>
            {
              (isBuffering || isLoadingError || !player?.isReady()) && !isShowingAds &&
              <Loading styles={{
                alignItems: 'center',
                background: 'rgba(0,0,0,0.8)',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                marginLeft: 0,
                marginRight: 0,
                paddingBottom: 25,
                top: 0,
                width: '100%',
                zIndex: 3,
              }} />
            }
            {
              !isShowingAds
                ? (
                  <div onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} ref={playerContainerRef}>
                    <PlayerContainer
                      handleContinueWatching={handleContinueWatching}
                      navigate={navigate}
                      nextEpisodeVisibility={nextEpisodeVisibility}
                      setNextEpisodeVisibility={setNextEpisodeVisibility}
                      nextVod={nextVod}
                    />
                    <PlayerControls
                      controlsVisibility={controlsVisibility}
                      elapsedTime={elapsedTime}
                      handleOnClose={handleOnClose}
                      navigate={navigate}
                      muted={muted}
                      onForward={handleForward}
                      onMute={handleMute}
                      onPlayPause={handlePlayPause}
                      onRewind={handleRewind}
                      onSeek={handleSeekChange}
                      onSeekMouseDown={handleSeekMouseDown}
                      onSeekMouseUp={handleSeekMouseUp}
                      onToggleFullScreen={toggleFullScreen}
                      onVolumeChange={handleVolumeChange}
                      onVolumeSeekUp={handleVolumeSeekUp}
                      played={played}
                      playing={playing}
                      duration={duration}
                      media={media}
                      type={type}
                      volume={volume}
                      nextVod={nextVod}
                      vttContent={vttContent}
                      baseUrlImage={`${baseUrlThumbsImage}/${media.idMedia}`}
                      totalDuration={totalDuration}
                    />
                    <div onClick={handlePlayPause} className='screenPlay'></div>
                  </div>
                )
                : (
                  <div ref={playerContainerRef}>
                    <PlayerContainer
                      handleContinueWatching={handleContinueWatching}
                      navigate={navigate}
                      nextEpisodeVisibility={nextEpisodeVisibility}
                      setNextEpisodeVisibility={setNextEpisodeVisibility}
                      nextVod={nextVod}
                    />
                  </div>
                )
            }
          </>
  )
}))