import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { isEmpty } from 'lodash'

import { Icons } from './Icons'
import iconPlus from '../assets/icons/plus.svg'

const ProfilesList = props => {
  const {
    handleOnSelectProfile,
    navigate,
    useImageCDN
  } = props

  const { currentProfile, data: profiles } = useSelector(state => state.profiles)
  return (
    <ul className='userList grid grid-6-cols rowL'>
      {
        Array(6).fill().map((_, index) => (
          profiles[index] !== undefined
            ? (
              <li key={index}>
                {
                  handleOnSelectProfile ? (
                    <button onClick={() => {
                      handleOnSelectProfile(profiles[index])
                      navigate('/inicio')
                    }}>
                      <div className={profiles[index].id === currentProfile.id ? 'userAvatar current' : 'userAvatar'}>
                        <figure className='ratioImgBox ratioSquare'>
                          <img
                            src={!isEmpty(profiles[index].avatar.imageUrl)
                              ? useImageCDN
                                ? profiles[index].avatar.imageUrl.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                                : profiles[index].avatar.imageUrl
                              : 'https://api-megago.megamedia.cl/files/avatar1.png'
                            }
                            alt='avatar'
                          />
                        </figure>
                        <p>{profiles[index].name}</p>
                      </div>
                    </button>
                  ) : (
                    <Link to={`/cuenta/editar/${profiles[index].id}`}>
                      <div className={profiles[index].id === currentProfile.id ? 'userAvatar current' : 'userAvatar'}>
                        <figure className='ratioImgBox ratioSquare'>
                          <img
                            src={!isEmpty(profiles[index].avatar.imageUrl)
                              ? useImageCDN
                                ? profiles[index].avatar.imageUrl.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                                : profiles[index].avatar.imageUrl
                              : 'https://api-megago.megamedia.cl/files/avatar1.png'
                            }
                            alt='avatar'
                          />
                        </figure>
                        <p>{profiles[index].name}</p>
                      </div>
                    </Link>
                  )
                }
              </li>
            )
            : (
              <li key={index}>
                <Link to={`/cuenta/editar/${uuid()}`}>
                  <div className='userAvatar userCreateAvatar'>
                    <figure className='ratioImgBox ratioSquare'>
                      <Icons path={iconPlus} />
                    </figure>
                    <p>Nuevo perfil</p>
                  </div>
                </Link>
              </li>
            )
        ))
      }
    </ul>
  )
}

export default ProfilesList