import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isArray, isEmpty, keyBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import axios from 'axios'

import HomeContainer from '../../containers/home/HomeContainer'
import Loading from '../Loading'
import { watchNow } from '../../utils/watchNow'
import { Icons } from '../../components/Icons'
import ArrowNext from '../../assets/icons/next.svg'
import ArrowPrev from '../../assets/icons/prev.svg'
import { CreateAnchor, CreateSponsored, DestroyAds } from '../Ads'

import {
  GET_LISTS,
  GET_NEWS,
  GET_CONTINUE_WATCHING,
  GET_NOT_VIEWED_CONTINUE_WATCHING,
  MERGE_CONTINUE_WATCHING_LISTS,
  SET_PROFILE_PREFERENCES,
  GET_NOTIFICATIONS,
  GET_NEWS_FINALLY,
  GET_SERIE_SUCCESS
} from '../../redux/actions'

export default memo(function HomeComponent() {
  const { isAuthenticated, keycloak, isLoading, firebaseConfigs: { plansShowFirstVisit, useImageCDN } } = useSelector(state => state.auth)
  const { data: lists, isLoading: isLoadingLists } = useSelector(state => state.lists)
  const { data: news, isLoading: isLoadingNews } = useSelector(state => state.news)
  const {
    continueWatching,
    notViewedContinueWatching,
    isLoading: isLoadingcontinueWatching
  } = useSelector(state => state.continueWatching)
  const { currentProfile, preferences, isLoading: isLoadingProfiles } = useSelector(state => state.profiles)
  const { data: notifications, isLoading: isLoadingNotifications } = useSelector(state => state.notifications)
  const { isLoading: isLoadingSubscription, subscriptionsPlanIds, isFreePlan } = useSelector(state => state.subscription)
  const { serie: serieFromRedis } = useSelector(state => state.series)
  const [isLoadingShowPlans, setIsLoadingShowPlans] = useState(true)
  const [numberOfSlide, setNumberOfSlides] = useState(6)
  const [sponsored, setSponsored] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  const getUserCallback = useCallback(async () => {
    const db = getFirestore()
    const user = await getDoc(doc(db, 'users', keycloak.subject))
    return user.exists() ? user.data() : {}
  }, [keycloak])

  const setUserCallback = useCallback(async () => {
    const db = getFirestore()
    await updateDoc(doc(db, 'users', keycloak.subject), { firstTime: false })
  }, [keycloak])

  const getSerieCallback = useCallback(async (idSerie) => {
    if (!isEmpty(keycloak)) {
      try {
        const headers = {
          headers: { 'authorization': `Bearer ${keycloak.token}` },
          timeout: 10000
        }

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}screen/series/${idSerie}`, headers)

        dispatch(GET_SERIE_SUCCESS(data))

        return data
      } catch (error) {
        return {}
      }
    } else {
      return {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak])

  useEffect(() => {
    if (isAuthenticated && subscriptionsPlanIds.length <= 1) {
      getUserCallback()
        .then(user => {
          if (!isEmpty(user)) {
            if (user.firstTime !== false) {
              if (plansShowFirstVisit) {
                setUserCallback()
                  .then(() => {
                    navigate('/planes')
                  })
                  .catch(() => setIsLoadingShowPlans(false))
              } else {
                setIsLoadingShowPlans(false)
              }
            } else {
              setIsLoadingShowPlans(false)
            }
          } else {
            setIsLoadingShowPlans(false)
          }
        })
        .catch(() => setIsLoadingShowPlans(false))
    } else {
      setIsLoadingShowPlans(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    !isLoadingShowPlans && isEmpty(lists) && dispatch(GET_LISTS({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingShowPlans])

  useEffect(() => {
    if (isArray(lists) && !isEmpty(lists)) {
      const hasNewsList = lists.some(list => list.type === 'News');
      const isLoadingNews = isLoadingLists && !hasNewsList;
      if (hasNewsList) {
        if (isEmpty(news)) {
          dispatch(GET_NEWS());
        }
      } else if (!isLoadingNews) {
        dispatch(GET_NEWS_FINALLY());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists])

  useEffect(() => {
    dispatch(GET_NOT_VIEWED_CONTINUE_WATCHING({
      keycloak, idCurrentProfile: currentProfile.id
    }))
    dispatch(GET_CONTINUE_WATCHING({
      keycloak, idCurrentProfile: currentProfile.id
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile.id])

  useEffect(() => {
    !isEmpty(lists) && dispatch(MERGE_CONTINUE_WATCHING_LISTS({ continueWatching: notViewedContinueWatching, lists }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists.length, notViewedContinueWatching.length])

  useEffect(() => {
    isEmpty(notifications) && dispatch(GET_NOTIFICATIONS({ keycloak }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Cambia el tamnaño de los slides según el ancho de la pantalla
  useEffect(() => {
    function setSliderNumbers() {
      if (window.matchMedia("(min-width: 1600px)").matches) {
        setNumberOfSlides(7)
      } else if (window.matchMedia("(min-width: 1300px)").matches) {
        setNumberOfSlides(6)
      } else if (window.matchMedia("(min-width: 1000px)").matches) {
        setNumberOfSlides(5)
      } else if (window.matchMedia("(min-width: 650px)").matches) {
        setNumberOfSlides(4)
      } else {
        setNumberOfSlides(3)
      }
    }
    window.addEventListener("resize", setSliderNumbers)
    setSliderNumbers()
    return () => window.removeEventListener("resize", setSliderNumbers)
  }, [])

  //Función de sliders
  const SliderArrow = (props) => {
    const { className, onClick, iconPath } = props
    return (
      <div className={className} onClick={onClick}>
        <Icons path={iconPath} />
      </div>
    )
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: numberOfSlide + 1,
    slidesToScroll: numberOfSlide,
    lazyLoad: 'ondemand',
    nextArrow: <SliderArrow iconPath={ArrowNext} />,
    prevArrow: <SliderArrow iconPath={ArrowPrev} />
  }

  const sliderNewsSettings = {
    dots: false,
    // Se necesita detectar si >=5 slides infinite sea true
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    lazyLoad: 'ondemand',
    nextArrow: <SliderArrow iconPath={ArrowNext} />,
    prevArrow: <SliderArrow iconPath={ArrowPrev} />
  }

  const preferencesKeyedById = keyBy(preferences, 'id')

  const handleOnChangePreferences = (newPreference) => {
    const newPreferences = isEmpty(preferencesKeyedById[newPreference.id])
      ? {
        id: newPreference.id,
        favorite: false,
        thumb: '',
        ...newPreference
      } : {
        ...preferencesKeyedById[newPreference.id],
        ...newPreference
      }

    dispatch(SET_PROFILE_PREFERENCES({
      keycloak, idSerie: newPreference.id, idCurrentProfile: currentProfile.id, newPreferences
    }))
  }

  const handleOnClickWatchNow = async (serie) => {
    let watchNowSerie = serieFromRedis

    if (isEmpty(serieFromRedis) || serieFromRedis._id !== serie._id) {
      await getSerieCallback(serie._id)
        .then(data => {
          watchNowSerie = data
        })
        .catch(() => {
          watchNowSerie = {}
        })
    }

    if (!isEmpty(continueWatching)) {
      const continueWatchingFilteredBySerie = continueWatching.filter(vod => vod.idSerie === watchNowSerie._id)

      if (!isEmpty(continueWatchingFilteredBySerie)) {
        watchNow(navigate, continueWatchingFilteredBySerie, watchNowSerie)
      } else {
        (watchNowSerie?.seasons[0]?.vods[0])
          ? navigate(`/player/vod/${watchNowSerie.seasons[0]?.vods[0]?._id}`)
          : navigate(`/serie/${watchNowSerie._id}`)
      }
    } else {
      (watchNowSerie?.seasons[0]?.vods[0])
        ? navigate(`/player/vod/${watchNowSerie.seasons[0]?.vods[0]?._id}`)
        : navigate(`/serie/${watchNowSerie._id}`)
    }
  }

  // Crea y destruye publicidades
  useEffect(() => {
    if (isFreePlan) {
      CreateAnchor('/ott/home/home/', {
        'seccion': 'portada',
        'nivel': 'home',
        'nota': '',
        'id_nota': '',
        'tipo': 'otro',
        'keywords': ''
      })
      CreateSponsored('/ott/home/home/', setSponsored)
      return () => {
        DestroyAds()
      }
    }
  }, [isFreePlan]);

  return (
    (isLoading || isLoadingLists || isLoadingNews || isLoadingcontinueWatching || isLoadingProfiles || isLoadingNotifications || isLoadingSubscription)
      ?
      <Loading />
      :
      <HomeContainer
        handleOnChangePreferences={handleOnChangePreferences}
        handleOnClickWatchNow={handleOnClickWatchNow}
        lists={lists}
        news={news}
        preferencesKeyedById={preferencesKeyedById}
        sliderNewsSettings={sliderNewsSettings}
        sliderSettings={sliderSettings}
        subscriptionsPlanIds={subscriptionsPlanIds}
        navigate={navigate}
        useImageCDN={useImageCDN}
        sponsored={sponsored}
      />
  )
})