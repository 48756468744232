import React from 'react'

import Footer from '../../components/Footer'
import { CardPortrait } from '../../components/CardPortrait'

export default function FavoritesContainer(props) {
  const {
    handleOnChangePreferences,
    handleOnClickWatchNow,
    preferencesKeyedById,
    series,
    useImageCDN
  } = props

  return (
    <main className='appWrap'>
      <div className='mainWidth noHeroWrap'>
        <section className='rowXxl'>
          <header className='rowS'>
            <h2>Favoritos</h2>
          </header>
          <ul className='grid grid-5-cols'>
            {
              series.map(serie => (
                <li key={serie._id} className='articleListItem'>
                  <CardPortrait
                    serie={serie}
                    handleOnChangePreferences={handleOnChangePreferences}
                    handleOnClickWatchNow={handleOnClickWatchNow}
                    link={`/serie/${serie._id}`}
                    preference={preferencesKeyedById[serie._id]}
                    useImageCDN={useImageCDN}
                  />
                </li>
              ))
            }
          </ul>
        </section>
      </div>
      <Footer />
    </main>
  )
}
