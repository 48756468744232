import { handleActions } from 'redux-actions'

import {
  GET_PLAN_START,
  GET_PLAN_SUCCESS,
  GET_PLAN_ERROR,
  GET_PLAN_FINALLY,
  GET_PLANS_START,
  GET_PLANS_SUCCESS,
  GET_PLANS_ERROR,
  GET_PLANS_FINALLY,
  GET_ONBOARDING_PLANS_START,
  GET_ONBOARDING_PLANS_SUCCESS,
  GET_ONBOARDING_PLANS_ERROR,
  GET_ONBOARDING_PLANS_FINALLY,
} from '../../../redux/actions'

const initialState = {
  plans: [],
  onboardingPlans: [],
  planById: [],
  error: null,
  isLoading: true,
  isLoadingById: true,
  isLoadingOnboardingPlans: true,
  urlToPaySubscription: '',
  transaction: {}
}

export default handleActions({
  [GET_PLAN_START]: state => ({
    ...state,
    isLoadingById: true,
    error: false
  }),
  [GET_PLAN_SUCCESS]: (state, action) => ({
    ...state,
    planById: action.payload
  }),
  [GET_PLAN_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [GET_PLAN_FINALLY]: state => ({
    ...state,
    isLoadingById: false
  }),
  [GET_PLANS_START]: state => ({
    ...state,
    isLoading: true,
    error: false
  }),
  [GET_PLANS_SUCCESS]: (state, action) => ({
    ...state,
    plans: action.payload
  }),
  [GET_PLANS_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [GET_PLANS_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [GET_ONBOARDING_PLANS_START]: state => ({
    ...state,
    isLoadingOnboardingPlans: true,
    error: false
  }),
  [GET_ONBOARDING_PLANS_SUCCESS]: (state, action) => ({
    ...state,
    onboardingPlans: action.payload
  }),
  [GET_ONBOARDING_PLANS_ERROR]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [GET_ONBOARDING_PLANS_FINALLY]: state => ({
    ...state,
    isLoadingOnboardingPlans: false
  }),
}, initialState)