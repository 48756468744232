import React from "react";
import Button from "@mui/material/Button";

import { Icons } from "../../components/Icons";
import iconArrowBack from "../../assets/icons/arrowBack.svg";
import megaGo from "../../assets/icons/megaGo.svg";
import iconMail from "../../assets/icons/mail.svg"
import iconWsp from "../../assets/icons/whatsapp.svg"
import iconLink from "../../assets/icons/outLink.svg"
import iconInfo from "../../assets/icons/info.svg"


export default function ContactContainer({ navigate, isFreePlan }) {
  return (
    <main className="appWrap bgDark">
      <Button
        variant="contained"
        className="medium goBack"
        onClick={() => navigate("/inicio")}
        startIcon={<Icons path={iconArrowBack} />}
      >
        Volver
      </Button>
      <div className="contactMessage">
        <Icons path={megaGo} className="megagologo" />
        <h2>¡Gracias por ayudarnos a mejorar Mega GO!</h2>
        <p>¿Tienes alguna duda? Ingresa a nuestro centro de ayuda</p>
        <div className="rowXl">
          <Button
            variant="contained"
            startIcon={<Icons path={iconInfo} />}
            endIcon={<Icons path={iconLink} />}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://megago.cl/ayuda", "_blank");
            }}
          >
            Centro de Ayuda
          </Button>

        </div>
        {
          isFreePlan ? (
            <p>
              Contáctanos a nuestro email:
              <b> contacto@megago.cl</b>
            </p>
          ) : (
            <p>
              También puedes contactarnos a nuestro WhatsApp: <b>+56 9 7125 3208</b> <br /> O al
              email:
              <b> contacto@megago.cl</b>
            </p>
          )}
        {!isFreePlan && (
          <Button
            variant="contained"
            startIcon={<Icons path={iconWsp} />}
            endIcon={<Icons path={iconLink} />}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/56971253208", "_blank");
            }}
            value="WhatsApp"
          >
            WhatsApp
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<Icons path={iconMail} />}
          endIcon={<Icons path={iconLink} />}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "mailto: contacto@megago.cl";
          }}
          value="Mail"
        >
          Email
        </Button>
      </div>
    </main>
  );
}