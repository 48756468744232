import React from 'react'
import { Icons } from '../components/Icons'
import megaGo from '../assets/icons/megaGo.svg'

const Footer = () => {
  return (
    <footer className='footerWrap'>
      <Icons path={megaGo} />
      <ul className='footerLinks'>
        <li>
          <a href='https://megago.cl/ayuda' target='_blank' rel='noreferrer'>Centro de Ayuda</a>
        </li>
        <li>|</li>
        <li>
          <a href='https://pay.megamedia.cl/terms' target='_blank' rel='noreferrer'>Términos de uso</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;