import { handleActions } from 'redux-actions';

import {
  AUTH_KEYCLOAK_START,
  AUTH_KEYCLOAK_SUCCESS,
  AUTH_KEYCLOAK_ERROR,
  AUTH_FINALLY,
  GET_FIREBASE_TOKEN_START,
  GET_FIREBASE_TOKEN_SUCCESS,
  GET_FIREBASE_TOKEN_ERROR,
  GET_FIREBASE_TOKEN_FINALLY,
  GET_FIREBASE_CONFIGS
} from '../../redux/actions';

const initialState = {
  keycloak: null,
  idUser: '',
  email: '',
  isAuthenticated: false,
  isLoading: true,
  firebase: {},
  firebaseConfigs: {},
  isLoadingFirebaseToken: false,
};

export default handleActions({
  [AUTH_KEYCLOAK_START]: state => ({
    ...state,
    isLoading: true
  }),
  [AUTH_KEYCLOAK_SUCCESS]: (state, action) => ({
    ...state,
    keycloak: action.payload.keycloak,
    email: action.payload.email,
    idUser: action.payload.idUser,
    isAuthenticated: action.payload.isAuthenticated,
  }),
  [AUTH_KEYCLOAK_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
  [AUTH_FINALLY]: state => ({
    ...state,
    isLoading: false
  }),
  [GET_FIREBASE_TOKEN_START]: state => ({
    ...state,
    isLoadingFirebaseToken: true
  }),
  [GET_FIREBASE_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    firebase: action.payload,
  }),
  [GET_FIREBASE_TOKEN_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
  [GET_FIREBASE_TOKEN_FINALLY]: state => ({
    ...state,
    isLoadingFirebaseToken: false
  }),
  [GET_FIREBASE_CONFIGS]: (state, action) => ({
    ...state,
    firebaseConfigs: action.payload
  })
}, initialState);