import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'

import {
  GET_CATEGORIES,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_FINALLY,
} from '../../../redux/actions'

function* GetCategoriesAction({ payload: { keycloak } }) {
  yield put(GET_CATEGORIES_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_API_URL}screen/categories`,
      headers
    )

    yield put(GET_CATEGORIES_SUCCESS(data))
  } catch (error) {
    yield put(GET_CATEGORIES_ERROR(error.message))
  } finally {
    yield put(GET_CATEGORIES_FINALLY())
  }
}


export default function* actionsWatcher() {
  yield takeLatest(GET_CATEGORIES, GetCategoriesAction)
}