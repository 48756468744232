import { createAction } from 'redux-actions'

export const AUTH = createAction('AUTH')
export const AUTH_KEYCLOAK_START = createAction('AUTH_KEYCLOAK_START')
export const AUTH_KEYCLOAK_SUCCESS = createAction('AUTH_KEYCLOAK_SUCCESS')
export const AUTH_KEYCLOAK_ERROR = createAction('AUTH_KEYCLOAK_ERROR')
export const AUTH_FINALLY = createAction('AUTH_FINALLY')

export const GET_FIREBASE_TOKEN = createAction('GET_FIREBASE_TOKEN')
export const GET_FIREBASE_TOKEN_START = createAction('GET_FIREBASE_TOKEN_START')
export const GET_FIREBASE_TOKEN_SUCCESS = createAction('GET_FIREBASE_TOKEN_SUCCESS')
export const GET_FIREBASE_TOKEN_ERROR = createAction('GET_FIREBASE_TOKEN_ERROR')
export const GET_FIREBASE_TOKEN_FINALLY = createAction('GET_FIREBASE_TOKEN_FINALLY')

export const GET_FIREBASE_CONFIGS = createAction('GET_FIREBASE_CONFIGS')