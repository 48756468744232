import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'

import {
  GET_PLAN,
  GET_PLAN_START,
  GET_PLAN_SUCCESS,
  GET_PLAN_ERROR,
  GET_PLAN_FINALLY,
  GET_PLANS,
  GET_PLANS_START,
  GET_PLANS_SUCCESS,
  GET_PLANS_ERROR,
  GET_PLANS_FINALLY,
  GET_ONBOARDING_PLANS,
  GET_ONBOARDING_PLANS_START,
  GET_ONBOARDING_PLANS_SUCCESS,
  GET_ONBOARDING_PLANS_ERROR,
  GET_ONBOARDING_PLANS_FINALLY,
} from '../../../redux/actions'

function* GetPlanAction({ payload: { keycloak, id } }) {
  yield put(GET_PLAN_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}plans/${id}`,
      headers
    )

    yield put(GET_PLAN_SUCCESS(data[0]))
  } catch (error) {
    yield put(GET_PLAN_ERROR(error.message))
  } finally {
    yield put(GET_PLAN_FINALLY())
  }
}

function* GetPlansAction({ payload: { keycloak } }) {
  yield put(GET_PLANS_START())
  let token = keycloak.token

  try {
    const isTokenUpdated = yield call(keycloak.updateToken, 5)
    token = isTokenUpdated ? keycloak.token : token

    const headers = {
      headers: { 'authorization': `Bearer ${token}` },
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      // `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}plans?platform_id=${process.env.REACT_APP_MS_SYSTEM_ID}&active=true&show_on_plans=true`,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}plans?platform_id=${process.env.REACT_APP_MS_SYSTEM_ID}&active=true`,
      headers
    )

    const plansSortByAmount = data.filter(p => p.show_on_plans).sort((a, b) => a.amount - b.amount)
    const dataWithAttributes = plansSortByAmount.map(plan => {
      if (plan.unique_pay !== true) {
        if (plan.id === process.env.REACT_APP_MS_PLAN_BASICO) {
          return {
            ...plan,
            attributes: [
              { icon: "devicePhone", description: "Hasta 5 dispositivos conectados simultáneamente", active: true },
              { icon: "signal", name: "Señales en vivo", description: "Mega, Meganoticias, Radios", active: true },
              { icon: "playOutline", name: "VOD", description: "Documentales", active: true },
              // { icon: "deviceTv", description: "Preestrenos antes que la tv", active: false },
              // { icon: "calendar", description: "Todos los eventos incluidos", active: false },
            ]
          }
        } else if (plan.id === process.env.REACT_APP_MS_PLAN_FULL) {
          return {
            ...plan,
            attributes: [
              { icon: "devicePhone", description: "Hasta 5 dispositivos conectados simultáneamente", active: true },
              { icon: "signal", name: "Señales en vivo", description: "Mega, Meganoticias, Radios, Mega 2 y ETC", active: true },
              { icon: "playOutline", name: "VOD", description: "Teleseries y películas nacionales, series, documentales y programas", active: true },
              { icon: "playOutline", description: "Capítulos sin publicidad", active: true },
              { icon: "deviceTv", description: "Preestrenos antes que la tv", active: true },
            ]
          }
        } else if (plan.id === process.env.REACT_APP_MS_PLAN_FULL_ANUAL) {
          return {
            ...plan,
            attributes: [
              { icon: "devicePhone", description: "Hasta 5 dispositivos conectados simultáneamente", active: true },
              { icon: "signal", name: "Señales en vivo", description: "Mega, Meganoticias, Radios, Mega 2 y ETC", active: true },
              { icon: "playOutline", name: "VOD", description: "Teleseries y películas nacionales, series, documentales y programas", active: true },
              { icon: "playOutline", description: "Capítulos sin publicidad", active: true },
              { icon: "deviceTv", description: "Preestrenos antes que la tv", active: true },
            ]
          }
        } else {
          return {
            ...plan,
            attributes: [
              { icon: "devicePhone", description: "Hasta 5 dispositivos conectados simultáneamente", active: true },
              { icon: "signal", name: "Señales en vivo", description: "Mega, Meganoticias, Radios", active: true },
              { icon: "playOutline", name: "VOD", description: "Documentales", active: true },
              // { icon: "deviceTv", description: "Preestrenos antes que la tv", active: false },
            ]
          }
        }
      } else {
        if (plan.name === 'Lollapalooza 2023') {
          return {
            ...plan,
            attributes: [
              { icon: "devicePhone", description: "Accede el evento en cualquiera de tus dispositivos compatibles con Mega GO", active: true },
              { icon: "signal", name: "Escenarios", description: "Disfruta en vivo de tus artistas favoritos", active: true },
              { icon: "playOutline", name: "VOD", description: "Revive el evento hasta un mes después de su estreno", active: true },
              { icon: "calendar", name: "Fecha", description: "Del 7 al 14 de marzo 2023", active: true },
            ]
          }
        } else if (plan.name === 'Juego de Ilusiones') {
          return {
            ...plan,
            attributes: [
              { icon: "playOutline", name: "VOD", description: "Disfruta de esta Teleserie exclusiva y descubre la historia de Mariana", active: true },
            ]
          }
        } else {
          return {
            ...plan,
            // logo: "https://api-megago.dev-megamedia.cl/ott/api/v2/screen/images/63d934497ce94f5214a8187e",
            attributes: [
              { icon: "devicePhone", description: "Accede el evento en cualquiera de tus dispositivos compatibles con Mega GO", active: true },
              { icon: "signal", name: "Escenarios", description: "Disfruta en vivo de tus artistas favoritos", active: true },
              { icon: "playOutline", name: "VOD", description: "Revive el evento hasta un mes después de su estreno", active: true },
              { icon: "calendar", name: "Fecha", description: "Del 7 al 14 de marzo 2023", active: true },
            ]
          }
        }
      }
    })
    yield put(GET_PLANS_SUCCESS(dataWithAttributes))
  } catch (error) {
    yield put(GET_PLANS_ERROR(error.message))
  } finally {
    yield put(GET_PLANS_FINALLY())
  }
}

function* GetOnboardingPlansAction() {
  yield put(GET_ONBOARDING_PLANS_START())
  try {
    const headers = {
      timeout: 10000
    }

    const { data } = yield call(axios.get,
      // `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}open/plans?platform_id=${process.env.REACT_APP_MS_SYSTEM_ID}&show_on_plans=true`,
      `${process.env.REACT_APP_SUBSCRIPTIONS_API_URL}open/plans?platform_id=${process.env.REACT_APP_MS_SYSTEM_ID}`,
      headers
    )
    const plansSortByAmount = data.filter(plan => plan.type === 'SUBSCRIPTION' && plan.show_on_plans ).sort((a, b) => a.amount - b.amount)
    yield put(GET_ONBOARDING_PLANS_SUCCESS(plansSortByAmount))
  } catch (error) {
    yield put(GET_ONBOARDING_PLANS_ERROR(error.message))
  } finally {
    yield put(GET_ONBOARDING_PLANS_FINALLY())
  }
}

export default function* actionsWatcher() {
  yield takeLatest(GET_PLAN, GetPlanAction)
  yield takeLatest(GET_PLANS, GetPlansAction)
  yield takeLatest(GET_ONBOARDING_PLANS, GetOnboardingPlansAction)
}