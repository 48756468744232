import { createAction } from 'redux-actions';

export const GET_MEDIA = createAction('GET_MEDIA');
export const GET_MEDIA_START = createAction('GET_MEDIA_START');
export const GET_MEDIA_SUCCESS = createAction('GET_MEDIA_SUCCESS');
export const GET_MEDIA_ERROR = createAction('GET_MEDIA_ERROR');
export const GET_MEDIA_FINALLY = createAction('GET_MEDIA_FINALLY');

export const GET_MDSTRM_ACCESS_TOKEN = createAction('GET_MDSTRM_ACCESS_TOKEN')
export const GET_MDSTRM_ACCESS_TOKEN_START = createAction('GET_MDSTRM_ACCESS_TOKEN_START')
export const GET_MDSTRM_ACCESS_TOKEN_SUCCESS = createAction('GET_MDSTRM_ACCESS_TOKEN_SUCCESS')
export const GET_MDSTRM_ACCESS_TOKEN_ERROR = createAction('GET_MDSTRM_ACCESS_TOKEN_ERROR')
export const GET_MDSTRM_ACCESS_TOKEN_FINALLY = createAction('GET_MDSTRM_ACCESS_TOKEN_FINALLY')

export const SET_CONTINUE_WATCHING = createAction('SET_CONTINUE_WATCHING')
export const SET_CONTINUE_WATCHING_START = createAction('SET_CONTINUE_WATCHING_START')
export const SET_CONTINUE_WATCHING_SUCCESS = createAction('SET_CONTINUE_WATCHING_SUCCESS')
export const SET_CONTINUE_WATCHING_ERROR = createAction('SET_CONTINUE_WATCHING_ERROR')
export const SET_CONTINUE_WATCHING_FINALLY = createAction('SET_CONTINUE_WATCHING_FINALLY')