import React from 'react'
import Drawer from '@mui/material/Drawer'
import moment from 'moment'
import 'moment/locale/es-mx'

import { Icons } from '../components/Icons'
import IconButton from '@mui/material/IconButton'
import iconClose from '../assets/icons/close.svg'

moment.locale('es-mx')

export default function Notifications(props) {
  const {
    anchorEl,
    handleClose,
    notifications,
  } = props

  return (
    <Drawer anchor='right' open={anchorEl} onClose={handleClose} className='paperWrap app'>
      <div className='notificationsWrap'>
        <IconButton onClick={handleClose} className='closeDrawer'><Icons path={iconClose} /></IconButton>
        <header className="rowS"><h2>Notificaciones</h2></header>
        <ul>
          {notifications.map((notification, index) => (
            <li key={index} className='notifyItem'>
              <a href={notification.click_action}>
                <div className='leftContent'>
                  {!notification.isChecked && <span className='alertBallon'></span>}
                  <figure className='ratioImgBox ratioPortrait'>
                    {notification.image_url ? (
                      <img src={notification.image_url} alt='' className='' />
                    ) : (
                      <img src='https://images2-mega.cdn.mdstrm.com/ott/portrait-y-thumb-live/mega-live-thumb.jpg' alt='logo-mega' className='' />
                    )}
                  </figure>
                </div>
                <div className='centerContent'>
                  <p>{notification.title}</p>
                  <h5>{notification.body}</h5>
                  <span>{moment(notification.date).fromNow()}</span>
                </div>
                {/* <div className='rightContent'>
                  <figure className='ratioImgBox ratioThumb'>
                    <img src='https://images2.mega.cl/ott/teleserie/E10-thumb-edif.jpg' alt='' />
                  </figure>
                </div> */}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Drawer>
  )
}