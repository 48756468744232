import React from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { Icons } from '../../components/Icons'
import iconArrowBack from '../../assets/icons/arrowBack.svg'
import iconNext from '../../assets/icons/next.svg'
import iconExternalLink from '../../assets/icons/outLink.svg'
import iconCoupon from '../../assets/icons/coupon.svg'
import cardVisa from '../../assets/icons/visa.svg'
import cardMasterCard from '../../assets/icons/mastercard.svg'
import cardPlaceholder from '../../assets/icons/cardPlaceholder.svg'

export default function ProfilePlanContainer(props) {
  const {
    subscription: {
      Coupon: coupon,
      subscriber_id,
      subscription_start,
      next_pay_date,
      last4_card_digits,
      credit_card_type,
      cancel_at_period_end,
      period_end,
      external_id,
      can_change: canChange = false,
    },
    navigate,
    plan,
    handleOnCancelSubscription,
  } = props

  let showDate = false

  if (cancel_at_period_end || !external_id) {
    showDate = period_end ? true : false
  } else {
    showDate = next_pay_date ? true : false
  }

  return (
    <main className='appWrap'>
      <Button
        variant='contained'
        className='medium goBack'
        onClick={() => navigate('/cuenta')}
        startIcon={<Icons path={iconArrowBack} />}
      >
        Volver
      </Button>
      <div className='mainWidthProfile noHeroWrap'>
        <section className='planSection'>
          <header className='mediumHeader' style={{ marginBottom: '-10px' }}>
            <h3>{plan.name}</h3>
          </header>
        </section>
        <section className='planSection'>
          <header className='mediumHeader'>
            <h4>Descripción</h4>
          </header>
          <div className='profileTextRow'>
            <p>{plan.descripcion}</p>
          </div>
        </section>
        {
          !cancel_at_period_end && plan.id !== process.env.REACT_APP_MS_PLAN_CORPORATIVO && (
            <section className='planSection'>
              <header className='mediumHeader'>
                <h4>Valor del plan</h4>
              </header>
              <div className='profileTextRow'>
                {plan.amount > 0
                  ? (
                    <div className=''>
                      <p>${Number(plan.amount).toLocaleString('es-CL')} CLP {plan.Periodicity?.name || ''}</p>
                      {
                        !isEmpty(coupon) && moment(new Date(subscription_start)).utc().add(coupon?.max_duration || 0, 'months').isSameOrAfter(moment().now) && (
                          <div className='profileCouponActive'>
                            <p className='withIcon'><Icons path={iconCoupon} /><strong>Tienes un cupón activo</strong></p>
                            <p><span>Vigencia:</span> Activo hasta el {moment(new Date(subscription_start)).utc().add(coupon?.max_duration || 0, 'months').format('DD/MM/YYYY')}</p>
                            <p><span>Valor final del plan:</span> ${Number(plan.amount - ((plan.amount * (coupon?.percentage || 0)) / 100)).toLocaleString('es-CL')} CLP {plan.Periodicity?.name || ''}</p>
                          </div>
                        )
                      }
                    </div>
                  )
                  : <p>Sin costo</p>
                }
              </div>
            </section>
          )
        }
        {plan.amount > 0 &&
          <>
            {
              showDate && (
                <section className='planSection'>
                  <header className='mediumHeader'>
                    {
                      cancel_at_period_end || !external_id
                        ? <h4>Suscripción activa hasta</h4>
                        : <h4>Próxima fecha de facturación</h4>
                    }
                  </header>
                  <div className='profileTextRow'>
                    {
                      cancel_at_period_end || !external_id
                        ? <p>{moment(new Date(period_end)).utc().format('D [de] MMMM [del] YYYY')}</p>
                        : <p>{moment(new Date(next_pay_date)).utc().format('D [de] MMMM [del] YYYY')}</p>
                    }
                  </div>
                </section>
              )
            }
            {
              !isEmpty(last4_card_digits) && !isEmpty(credit_card_type) && !cancel_at_period_end && (
                <section className='planSection'>
                  <header className='mediumHeader'>
                    <h4>Medio de pago</h4>
                  </header>
                  <div className='profileTextRow cardDisplay'>
                    <Icons path={getCardType(credit_card_type).img} />
                    <div>
                      <p>{getCardType(credit_card_type).type}</p>
                      <p>•••• •••• •••• {last4_card_digits}</p>
                    </div>
                  </div>
                </section>
              )
            }
          </>
        }
        <section className='planSection'>
          <div className='profileTextRow' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            {canChange && plan.id !== process.env.REACT_APP_MS_PLAN_CORPORATIVO &&
              <Link to='/planes'>Cambiar plan <Icons path={iconNext} /></Link>
            }
            {!cancel_at_period_end && plan.id !== process.env.REACT_APP_MS_PLAN_CORPORATIVO && (
              plan.amount > 0
                ?
                <>
                  {external_id && <Button onClick={() => window.location.replace(`${process.env.REACT_APP_PAY_WEB_URL}/edit/${subscriber_id}`)}>Cambiar medio de pago <Icons path={iconExternalLink} /></Button>}
                  <Button onClick={handleOnCancelSubscription}>Cancelar mi plan <Icons path={iconExternalLink} /></Button>
                </>
                : <Link to='/planes'>Contratar plan <Icons path={iconNext} /></Link>
            )}
            <a href={process.env.REACT_APP_TERMS_URL} alt='' target='_blank' rel='noreferrer'>Ver términos y condiciones <Icons path={iconExternalLink} /></a>
          </div>
        </section>
      </div>
      <Footer />
    </main>
  )
}

function getCardType(type) {
  switch (type) {
    case 'Visa':
      return { type: 'Tarjeta de crédito', img: cardVisa }
    case 'MasterCard':
      return { type: 'Tarjeta de crédito', img: cardMasterCard }
    case 'RedCompra':
      return { type: 'Tarjeta de débito', img: cardPlaceholder }
    case 'Prepago':
      return { type: 'Tarjeta de prepago', img: cardPlaceholder }
    default:
      return { type: '', img: cardPlaceholder }
  }
}