import React from 'react'
import { isEmpty } from 'lodash'

import PlayerNextEpisode from '../../components/player/PlayerNextEpisode'

export default function PlayerContainer(props) {
  const {
    handleContinueWatching,
    navigate,
    nextEpisodeVisibility,
    setNextEpisodeVisibility,
    nextVod,
  } = props

  return (
    <>
      <main className='appWrap'>
        <div className='playerWrapper'>
          <div id="mdstrm"></div>
        </div>
        {!isEmpty(nextVod) && nextEpisodeVisibility === true && (
          <PlayerNextEpisode
            navigate={navigate}
            nextVod={nextVod}
            setNextEpisodeVisibility={setNextEpisodeVisibility}
            handleContinueWatching={handleContinueWatching}
          />
        )}
      </main>
    </>
  )
}