import React, { memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { GET_PLAN } from '../plans/plans/plans.actions'
import { CANCEL_SUBSCRIPTION } from '../subscription/subscription.actions'

import ProfilePlanContainer from '../../containers/profile/ProfilePlanContainer'
import Loading from '../Loading'

export default memo(function ProfileComponent() {
  const { isAuthenticated, keycloak } = useSelector((state) => state.auth)
  const { planById: plan, isLoadingById } = useSelector(state => state.plans)
  const { data: subscription,
    isLoading: isLoadingSubscription,
    isLoadingCancel,
    subscriber
  } = useSelector(state => state.subscription)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    !isAuthenticated && keycloak.login()
  }, [isAuthenticated, keycloak])

  useEffect(() => {
    if (!isLoadingSubscription) {
      if (!isEmpty(subscription)) {
        !isEmpty(keycloak) && isEmpty(plan)
          && dispatch(GET_PLAN({ keycloak, id: subscription.plan_id }))
      } else {
        navigate('/cuenta')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, keycloak, plan])

  useEffect(() => {
    !isEmpty(subscriber) && !isEmpty(subscriber.idp_id)
      && navigate('/cuenta')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriber])

  const handleOnCancelSubscription = () => {
    let caducado = subscription.subscription_end &&
      subscription.subscription_end >= new Date()
    if (subscription.paid && !caducado) {
      dispatch(CANCEL_SUBSCRIPTION({
        keycloak,
        subscription_id: subscription.id,
        subscriptor_id: subscription.subscriber_id,
        url_callback: window.location.origin
      }))
    } else {
      navigate('/planes')
    }
  }

  return (
    isLoadingById || isLoadingSubscription || isLoadingCancel
      ? <Loading />
      : (
        <ProfilePlanContainer
          navigate={navigate}
          subscription={subscription}
          plan={plan}
          handleOnCancelSubscription={handleOnCancelSubscription}
        />
      )
  )
})