import React from 'react'
import { CircularProgress } from '@mui/material'

export default function Loading(props) {
  const { message, styles } = props;

  return (
    <div className='loadingWrap' style={{...styles }}>
      <CircularProgress />
      {message && <h2 style={{  fontSize: '1.3rem' }}>{message}</h2>}
    </div>
  )
}