import { createAction } from 'redux-actions';

export const GET_PROFILES = createAction('GET_PROFILES');
export const GET_PROFILES_START = createAction('GET_PROFILES_START');
export const GET_PROFILES_SUCCESS = createAction('GET_PROFILES_SUCCESS');
export const GET_PROFILES_ERROR = createAction('GET_PROFILES_ERROR');
export const GET_PROFILES_FINALLY = createAction('GET_PROFILES_FINALLY');
export const SET_PROFILE = createAction('SET_PROFILE');
export const SET_PROFILE_START = createAction('SET_PROFILE_START');
export const SET_PROFILE_SUCCESS = createAction('SET_PROFILE_SUCCESS');
export const SET_PROFILE_ERROR = createAction('SET_PROFILE_ERROR');
export const SET_PROFILE_FINALLY = createAction('SET_PROFILE_FINALLY');
export const UPDATE_PROFILE = createAction('UPDATE_PROFILE');
export const UPDATE_PROFILE_START = createAction('UPDATE_PROFILE_START');
export const UPDATE_PROFILE_SUCCESS = createAction('UPDATE_PROFILE_SUCCESS');
export const UPDATE_PROFILE_ERROR = createAction('UPDATE_PROFILE_ERROR');
export const UPDATE_PROFILE_FINALLY = createAction('UPDATE_PROFILE_FINALLY');
export const DELETE_PROFILE = createAction('DELETE_PROFILE');
export const DELETE_PROFILE_START = createAction('DELETE_PROFILE_START');
export const DELETE_PROFILE_SUCCESS = createAction('DELETE_PROFILE_SUCCESS');
export const DELETE_PROFILE_ERROR = createAction('DELETE_PROFILE_ERROR');
export const DELETE_PROFILE_FINALLY = createAction('DELETE_PROFILE_FINALLY');
export const SET_CURRENT_PROFILE = createAction('SET_CURRENT_PROFILE');
export const GET_PROFILE_PREFERENCES = createAction('GET_PROFILE_PREFERENCES');
export const GET_PROFILE_PREFERENCES_START = createAction('GET_PROFILE_PREFERENCES_START');
export const GET_PROFILE_PREFERENCES_SUCCESS = createAction('GET_PROFILE_PREFERENCES_SUCCESS');
export const GET_PROFILE_PREFERENCES_ERROR = createAction('GET_PROFILE_PREFERENCES_ERROR');
export const GET_PROFILE_PREFERENCES_FINALLY = createAction('GET_PROFILE_PREFERENCES_FINALLY');
export const SET_PROFILE_PREFERENCES = createAction('SET_PROFILE_PREFERENCES');
export const SET_PROFILE_PREFERENCES_START = createAction('SET_PROFILE_PREFERENCES_START');
export const SET_PROFILE_PREFERENCES_SUCCESS = createAction('SET_PROFILE_PREFERENCES_SUCCESS');
export const SET_PROFILE_PREFERENCES_ERROR = createAction('SET_PROFILE_PREFERENCES_ERROR');
export const SET_PROFILE_PREFERENCES_FINALLY = createAction('SET_PROFILE_PREFERENCES_FINALLY');