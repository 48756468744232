export let failedRequest = null;
let setShowError = null;

export const setAxiosErrorHandler = (setError) => {
  setShowError = setError;
};

const axiosInterceptor = (axios) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { response, config } = error;

      const errorStatusCodes = new Set([429, 502, 503, 504, 404]);

      if (response && errorStatusCodes.has(response.status) && setShowError) {
        const retryAfterValue = parseInt(response.headers['Retry-After'] || 10, 10);
        console.log("Error en la ruta:", config.url);
        failedRequest = { config, retryAfterValue };
        setShowError(true);
        await new Promise(resolve => setTimeout(resolve, retryAfterValue * 1000));
        setShowError(false);
        return axios.request(config);
      }

      return Promise.reject(error);
    }
  );
};

export default axiosInterceptor;
